import { ReactNode, createContext, useState } from "react";

const QuickLinksContext = createContext({});

export const QuickLinksProvider = ({ children }: { children: ReactNode }) => {
    const [quickLinks, setQuickLinks] = useState<any[]>([]);

    return (
        <QuickLinksContext.Provider value={{ quickLinks, setQuickLinks }}>
            {children}
        </QuickLinksContext.Provider>
    )
}

export default QuickLinksContext;