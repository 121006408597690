import {
    InputBase,
    Box,
    Stack,
    Button,
} from "@mui/material";
import { GridColDef, DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import baseURL from "../../../configs/api";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import QuotesDetailsPopup from "./QuotesDetailsPopup";
import { format } from "date-fns";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },

    width: "100%",
    paddingBottom: "0px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    paddingBottom: "0px",
}));

type AllQuoteProps = {
    paginationModel: any;
    setPaginationModel: (value: any) => void;
    totalLength: number;
    setSearchTableData: (value: any[]) => void;
    searchTableData: any[];
    setIsDataLoaded: (value: boolean) => void;
    isDataLoaded: boolean;
    searchValue: string;
    setSearchValue: (value: string) => void;
    oldPage: number;
    setOldPage: (value: number) => void;
    setTotalLength: (value: number) => void;
}

const AllQuotes = ({
    paginationModel,
    setPaginationModel,
    totalLength,
    setSearchTableData,
    searchTableData,
    setIsDataLoaded,
    searchValue,
    setSearchValue,
    isDataLoaded,
    oldPage,
    setOldPage,
    setTotalLength
}: AllQuoteProps) => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [particularQuotes, setParticularQuotes] = useState<any>();
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [quoteFilterValue, setQuoteFilterValue] = useState(0);
    const [operatorFilter, setOpertorFilter] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState<string>("Customer Name");

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "#",
            width: 120,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <p style={{ color: "blue", cursor: "pointer" }} onClick={() => {
                        setShowPopup(true);
                        setParticularQuotes(params.row);
                    }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "reservationType",
            headerName: "Reservation Type",
            headerAlign: "center",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "customerName",
            headerName: "Customer Name",
            width: 240,
            editable: false,
            sortable: false,
        },
        {
            field: "pickupDate",
            headerName: "Pickup Date",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "returnDate",
            headerName: "Return Date",
            type: "number",
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "vehicleClassName",
            headerName: "Vehicle Class",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "commissionPartner",
            headerName: "Commission Patner",
            type: "number",
            width: 120,
            editable: false,
            sortable: false,
        },
        {
            field: "branchName",
            headerName: "Branch Name",
            type: "number",
            width: 120,
            editable: false,
            sortable: false,
        },
        {
            field: "clientPay",
            headerName: "Client pays at Commission Patner",
            headerAlign: "right",
            type: "string",
            width: 120,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <p
                        style={{
                            width: "30px",
                            color: "white",
                            background: params.value === "Yes" ? "#66BB6A" : "#ff0000",
                            padding: "1px 3px",
                            textAlign: "center",
                            borderRadius: "2px",
                            fontSize: "10px",
                        }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "rackPrice",
            headerName: "Rack Price",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "totalPrice",
            headerName: "Total Price",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "status",
            headerName: "Status",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                let color = "#bdbdbd";
                switch (params.value) {
                    case "Quote":
                        color = "#bdbdbd";
                        break;
                    case "Open":
                        color = "#f0ad4e";
                        break;
                    case "Rental":
                        color = "#66BB6A";
                        break;
                    case "Completed":
                        color = "#253455";
                        break;
                    case "Cancelled":
                        color = "#ff0000";
                        break;
                    case "Pending":
                        color = "#808080";
                        break;
                    case "No Show":
                        color = "#e0e0e0";
                        break;
                }

                return (
                    <p
                        style={{
                            width: "60px",
                            color: "white",
                            background: color,
                            padding: "1px 3px",
                            textAlign: "center",
                            borderRadius: "2px",
                            fontSize: "10px",
                        }}
                    >
                        {params.value}
                    </p>
                );
            },
        },
        {
            field: "discount",
            headerName: "Discount",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
        {
            field: "tag",
            headerName: "Tags",
            type: "number",
            width: 160,
            editable: false,
            sortable: false,
        },
    ];


    useEffect(() => {

        let timeOutId: NodeJS.Timeout;

        if (searchValue !== "") {
            timeOutId = setTimeout(() => {
                const newPaginationModel = {
                    page: 0,
                    pageSize: paginationModel.pageSize,
                };

                setPaginationModel(newPaginationModel);

            }, 1000);

        }

        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue !== "") {
            apiCall(paginationModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const apiCall = (newPaginationModel: any) => {
        let filterData: any[] = [];
        setIsDataLoaded(true);
        switch (quoteFilterValue) {
            case 0:
                fetch(`${baseURL}quote/GetQuotesReportSearchWithCustomerName/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
                    .then((res) => res.json())
                    .then((data) => {

                        filterData = data.quoteReports.map((item: any) => {
                            return {
                                ...item,
                                pickupDate: format(
                                    new Date(item.pickupDate),
                                    "MM/dd/yyyy KK:mm a"
                                ),
                                returnDate: format(
                                    new Date(item.returenDate),
                                    "MM/dd/yyyy KK:mm a"
                                ),
                                totalPrice: "$" + item.totalPrice?.toFixed(2),
                                rackPrice: "$" + item.rackPrice?.toFixed(2),
                                securityDepositAmount: "$" + item.securityDepositAmount?.toFixed(2),
                                discount: "$" + item.discount?.toFixed(2),
                                clientPay: item.clientPaysatCommissionPartner ? "Yes" : "No",
                            };
                        });

                        let newArray = [];
                        if (paginationModel.page === 0) {
                            newArray = filterData;
                        } else if (paginationModel.page > oldPage) {
                            newArray = searchTableData.concat(filterData);
                        } else {
                            newArray = searchTableData;
                        }

                        setSearchTableData(newArray);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                        if (paginationModel.page === 0) {
                            setOldPage(-1);
                        } else {
                            setOldPage(paginationModel.page - 1);
                        }
                    });
                break;
            case 1:
                fetch(`${baseURL}quote/GetQuotesReportSearchWithVehicleClassName/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
                    .then((res) => res.json())
                    .then((data) => {

                        filterData = data.quoteReports.map((item: any) => {
                            return {
                                ...item,
                                pickupDate: format(
                                    new Date(item.pickupDate),
                                    "MM/dd/yyyy KK:mm a"
                                ),
                                returnDate: format(
                                    new Date(item.returenDate),
                                    "MM/dd/yyyy KK:mm a"
                                ),
                                totalPrice: "$" + item.totalPrice?.toFixed(2),
                                rackPrice: "$" + item.rackPrice?.toFixed(2),
                                securityDepositAmount: "$" + item.securityDepositAmount?.toFixed(2),
                                discount: "$" + item.discount?.toFixed(2),
                                clientPay: item.clientPaysatCommissionPartner ? "Yes" : "No",
                            };
                        });

                        let newArray = [];
                        if (paginationModel.page === 0) {
                            newArray = filterData;
                        } else if (paginationModel.page > oldPage) {
                            newArray = searchTableData.concat(filterData);
                        } else {
                            newArray = searchTableData;
                        }

                        setSearchTableData(newArray);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);
                        if (paginationModel.page === 0) {
                            setOldPage(-1);
                        } else {
                            setOldPage(paginationModel.page - 1);
                        }
                    });
                break;
            case 2:
                fetch(`${baseURL}quote/GetQuotesReportSearchWithBranchName/${searchValue},${newPaginationModel.page},${newPaginationModel.pageSize}`)
                    .then((res) => res.json())
                    .then((data) => {

                        filterData = data.quoteReports.map((item: any) => {
                            return {
                                ...item,
                                pickupDate: format(
                                    new Date(item.pickupDate),
                                    "MM/dd/yyyy KK:mm a"
                                ),
                                returnDate: format(
                                    new Date(item.returenDate),
                                    "MM/dd/yyyy KK:mm a"
                                ),
                                totalPrice: "$" + item.totalPrice?.toFixed(2),
                                rackPrice: "$" + item.rackPrice?.toFixed(2),
                                securityDepositAmount: "$" + item.securityDepositAmount?.toFixed(2),
                                discount: "$" + item.discount?.toFixed(2),
                                clientPay: item.clientPaysatCommissionPartner ? "Yes" : "No",
                            };
                        });

                        let newArray = [];
                        if (paginationModel.page === 0) {
                            newArray = filterData;
                        } else if (paginationModel.page > oldPage) {
                            newArray = searchTableData.concat(filterData);
                        } else {
                            newArray = searchTableData;
                        }

                        setSearchTableData(newArray);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);
                        if (paginationModel.page === 0) {
                            setOldPage(-1);
                        } else {
                            setOldPage(paginationModel.page - 1);
                        }
                    });
                break;

        }
    }


    const applyFilter = () => {
        setSelectedFilter(() =>
            quoteFilterValue === 0 ? "Customer Name" : quoteFilterValue === 1 ? "Vehicle Class" : "Branch Name"
        );
        setSearchValue("");
        setDisplayFilter(false);
    };

    const clearFilter = () => {
        setSearchValue("");
        setDisplayFilter(false);
    };


    return (
        <>
            <Box sx={{ padding: "24px" }}>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ border: "1px solid black", alignItems: "center" }}
                >
                    <div style={{ position: "relative", width: "150px" }}>
                        <Button
                            variant="contained"
                            endIcon={<ArrowDropDownIcon sx={{ color: "#bdbdbd" }} />}
                            sx={{
                                color: "black",
                                backgroundColor: "#e0dee0",
                                textTransform: "unset",
                                fontSize: "12px",
                                border: "none",
                                borderRadius: "0px",

                                "&: hover": {
                                    backgroundColor: "white",
                                },
                            }}
                            onClick={() => setDisplayFilter(() => !displayFilter)}
                        >
                            {selectedFilter}
                        </Button>

                        <div
                            style={{
                                display: displayFilter ? "block" : "none",
                                width: "170px",
                                position: "absolute",
                                zIndex: "100",
                                top: "-100%",
                                left: "100%",
                                background: "white",
                                border: "1px solid black",
                                padding: "8px",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <label
                                        htmlFor="fields"
                                        style={{ fontSize: "10px", fontWeight: "bold" }}
                                    >
                                        Fields
                                        <br />
                                    </label>

                                    <CloseIcon
                                        sx={{
                                            width: "18px",
                                            fontSize: "10px",
                                            cursor: "pointer",
                                            background: "#e0e0e0",
                                        }}
                                        onClick={() => setDisplayFilter(false)}
                                    />
                                </div>

                                <select
                                    name="fields"
                                    style={{
                                        width: "100%",
                                        fontSize: "10px",
                                        paddingBlock: "3px",
                                    }}
                                    value={quoteFilterValue}
                                    onChange={(e) =>
                                        setQuoteFilterValue(parseInt(e.target.value))
                                    }
                                >
                                    <option value="0">Customer Name</option>
                                    <option value="1">Vehicle Class</option>
                                    <option value="2">Branch Name</option>
                                </select>
                            </div>

                            <div>
                                <label
                                    htmlFor="operator"
                                    style={{ fontSize: "10px", fontWeight: "bold" }}
                                >
                                    Operator
                                    <br />
                                    <select
                                        name="operator"
                                        style={{
                                            width: "100%",
                                            fontSize: "10px",
                                            paddingBlock: "3px",
                                        }}
                                        value={operatorFilter}
                                        onChange={(e) => setOpertorFilter(parseInt(e.target.value))}
                                    >
                                        <option value="0">In a list</option>
                                    </select>
                                </label>
                            </div>


                            <div
                                style={{
                                    display: "flex",

                                    marginTop: "7px",
                                    justifyContent: "space-between",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <button
                                    className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                                    onClick={() => applyFilter()}
                                >
                                    Apply Filter
                                </button>

                                <button
                                    className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                                    onClick={() => clearFilter()}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>

                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ "aria-label": "search" }}
                            value={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value);

                            }}
                        />
                    </Search>
                </Stack>


                <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
                    {!isDataLoaded ? searchTableData.length > 0 ? (
                        <DataGrid
                            rows={searchTableData}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                            rowCount={totalLength}
                            paginationModel={paginationModel}
                            onPaginationModelChange={(model: GridPaginationModel) => {
                                if (model.pageSize !== paginationModel.pageSize) {
                                    setPaginationModel({
                                        page: 0,
                                        pageSize: model.pageSize,
                                    });
                                    return;
                                }

                                setPaginationModel({
                                    page: model.page,
                                    pageSize: model.pageSize,
                                });
                            }}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            checkboxSelection
                            showCellVerticalBorder={false}
                            sx={{
                                border: "none",
                                fontSize: "12px",
                                wordWrap: "break-word",
                                overflow: "visible",
                            }}
                        />
                    ) : (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <p>No Data Found</p>

                        </div>
                    ) : (
                        <div className="loading">
                            <p>Please wait</p>
                            <span>
                                <i></i>
                                <i></i>
                            </span>
                        </div>
                    )}
                </Box>
            </Box>

            <QuotesDetailsPopup
                particularQuotes={particularQuotes}
                showPopup={showPopup}
                hidePopup={() => setShowPopup(false)}
                deleteQuote={() => {
                    const newPaginationModel = {
                        page: 0,
                        pageSize: paginationModel.pageSize,
                    };

                    setPaginationModel(newPaginationModel);
                    setShowPopup(false);
                }}
            />
        </>
    );
}

export default AllQuotes