import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import { format } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  reservation: any;
  reservationUpdate: () => void;
  refundList: any[];
  refundUpdate: () => void;
  isCompletedOrCancelled: boolean;
};

export default function ({
  reservation,
  reservationUpdate,
  refundList,
  refundUpdate,
  isCompletedOrCancelled
}: Props) {
  const [isRefunds, setIsRefunds] = useState<boolean>(true);
  const [offlineRefundPopup, setOfflineRefundPopup] = useState<boolean>(false);

  const [isOfflineRefund, setIsOfflineRefund] = useState<boolean>(true);
  const [refundType, setRefundTpe] = useState<string>("Bank Transfer");
  const [refundDate, setRefundDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [refundAmount, setRefundAmount] = useState<number>(0.0);
  const [refundReference, setRefundReference] = useState<string>("");
  const [isRefundSendEmail, setIsRefundSendEmail] = useState<boolean>(false);

  const handleRefundPayment = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!isCompletedOrCancelled) {
      e.preventDefault();

      await axios
        .post(
          `${baseURL}refund`,
          JSON.stringify({
            reservationId: reservation?.id,
            refundType: refundType,
            dateofRefund: refundDate,
            amount: refundAmount,
            reference: refundReference,
            createdAt: new Date(),
            createdBy: "Website",
            syncedWithAccounting: false,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          updateTheTotalPrice();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const updateTheTotalPrice = async () => {
    if (!isCompletedOrCancelled) {
      const outstandingPayment =
        parseFloat(reservation?.outstandingBalance) + refundAmount;

      const totalRefunded = parseFloat(reservation?.totalRefunded) - refundAmount;

      await axios
        .put(
          `${baseURL}reservation`,
          {
            ...reservation,
            outstandingBalance: outstandingPayment,
            totalRefunded: totalRefunded,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          reservationUpdate();
          refundUpdate();
          setOfflineRefundPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    if (reservation) {
      setRefundAmount(() => -reservation?.outstandingBalance);
    }
  }, [reservation]);

  return (
    <>
      <h5
        style={{
          paddingBottom: "0px",
          marginBottom: "0px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          cursor: "pointer",
          WebkitUserSelect: "none" /* Safari */,
          msUserSelect: "none" /* IE 10 and IE 11 */,
          userSelect: "none" /* Standard syntax */,
        }}
        onClick={() => setIsRefunds(!isRefunds)}
      >
        {isRefunds ? (
          <ExpandMoreIcon sx={{ fontSize: "20px" }} />
        ) : (
          <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
        )}{" "}
        Refunds
      </h5>
      <hr />

      {isRefunds && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "8%",
                marginBottom: "0px",
              }}
            >
              #
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "12%",
                marginBottom: "0px",
              }}
            >
              Payment Type
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "12%",
                marginBottom: "0px",
              }}
            >
              Date
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Amount
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "12%",
                marginBottom: "0px",
              }}
            >
              Reference
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "15%",
                marginBottom: "0px",
              }}
            >
              Created at
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "12%",
                marginBottom: "0px",
              }}
            >
              Created by
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "10%",
                marginBottom: "0px",
              }}
            >
              Synced with Accounting?
            </p>
          </div>
          <hr style={{ marginBlock: "0px" }} />

          {refundList.map((item: any) => {
            return (
              <React.Fragment key={item.id}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "8%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.id}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "12%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.refundType}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "12%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.dateofRefund
                      ? format(
                        new Date(
                          item.dateofRefund.replaceAll("-", "/").split("T")[0]
                        ),
                        "MM/dd/yyyy "
                      )
                      : ""}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    ${parseFloat(item.amount).toFixed(2)}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "12%",
                      marginBottom: "0px",
                      whiteSpace: "normal",
                    }}
                  >
                    {item?.reference}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "15%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.createdAt
                      ? format(new Date(item.createdAt), "MM/dd/yyyy kk:mm a")
                      : ""}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "12%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.createdBy}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.syncedWithAccounting ? "Yes" : "No"}
                  </p>
                </div>
                <hr style={{ marginBlock: "0px" }} />
              </React.Fragment>
            );
          })}

          <button
            style={{
              marginBlock: "5px",
              paddingBlock: "3px",
              backgroundColor: colorConfigs.topbar.bg,
              color: "white",
              border: "none",
              padding: "8px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!isCompletedOrCancelled)
                setOfflineRefundPopup(true)
            }}
          >
            Add Offline Refund
          </button>
        </>
      )}

      <Dialog
        open={offlineRefundPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "50%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              Add Offline Refund
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => setOfflineRefundPopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <form onSubmit={(e) => handleRefundPayment(e)}>
            <p
              style={{
                fontSize: "10px",
                backgroundColor: "#f1dedf",
                color: "#bb6a68",
                textAlign: "start",
                borderRadius: "2px",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "24px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            >
              By Creating a refund here, it won't return any money to customer.
              Create refunds here only if you processed this refund manually.
            </p>

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: "16px",
                marginRight: "16px",
                WebkitUserSelect: "none" /* Safari */,
                msUserSelect: "none" /* IE 10 and IE 11 */,
                userSelect: "none" /* Standard syntax */,
              }}
              onClick={() => setIsOfflineRefund(!isOfflineRefund)}
            >
              {isOfflineRefund ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "50%" }}>Refunds Information</div>

                <div
                  style={{
                    width: "50%",
                    fontSize: "10px",
                    fontWeight: "normal",
                    textAlign: "end",
                    color: "gray",
                  }}
                >
                  Some general Information about the Refund
                </div>
              </div>
            </h5>
            <hr style={{ marginLeft: "16px", marginRight: "16px" }} />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {isOfflineRefund && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="refundType"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Refund Type
                        <br />
                        <select
                          name="refundType"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "3px",
                          }}
                          value={refundType}
                          onChange={(e) => setRefundTpe(e.target.value)}
                          required
                        >
                          <option value="Bank Transfer">Bank Transfer</option>
                          <option value="Cash">Cash</option>
                          <option value="Check">Check</option>
                        </select>
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="refundAmount"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Amount
                        <br />
                        <input
                          type="number"
                          name="refundAmount"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "3px",
                          }}
                          value={refundAmount}
                          onChange={(e) =>
                            setRefundAmount(parseFloat(e.target.value))
                          }
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="isRefundSendEmail"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Send Confirmation Email
                        <br />
                        <div>
                          <label style={{ fontSize: "12px" }}>
                            <input
                              type="radio"
                              value="Yes"
                              name="isRefundSendEmail"
                              style={{ fontSize: "12px" }}
                              checked={isRefundSendEmail ? true : false}
                              onChange={(e) =>
                                setIsRefundSendEmail(
                                  e.target.value === "Yes" ? true : false
                                )
                              }
                            />{" "}
                            Yes
                          </label>
                          <label style={{ fontSize: "12px" }}>
                            <input
                              type="radio"
                              value="No"
                              name="isRefundSendEmail"
                              style={{ fontSize: "12px" }}
                              checked={isRefundSendEmail ? false : true}
                              onChange={(e) =>
                                setIsRefundSendEmail(
                                  e.target.value === "Yes" ? true : false
                                )
                              }
                            />{" "}
                            No
                          </label>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="refundDate"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Date
                        <br />
                        <input
                          type="date"
                          name="refundDate"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "1px",
                          }}
                          value={refundDate}
                          onChange={(e) => setRefundDate(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="refundReference"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Reference
                        <br />
                        <input
                          type="text"
                          name="refundReference"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "3px",
                          }}
                          value={refundReference}
                          onChange={(e) => setRefundReference(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                backgroundColor: "#eeeeee",
              }}
            >
              <div style={{ width: "50%" }} />

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: "#e0dee0",
                      color: colorConfigs.sidebar.color,
                    },
                  }}
                  onClick={() => setOfflineRefundPopup(false)}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}