import React, { useState } from "react";
import EditAdjustmentModal from "./EditAdjustmentModal";

type AdjsutmentListProps = {
    adjustmentData: any[],
    setAdjustmentRefresh: (value: boolean) => void,
    setIsExtraValueIsAdded: (value: boolean) => void,
    reservation: any,
    setReservationUpdate: (value: boolean) => void;
}

const AdjustmentList = ({ adjustmentData, setAdjustmentRefresh, setIsExtraValueIsAdded, reservation, setReservationUpdate }: AdjsutmentListProps) => {
    const [editAdjustmentIsOpen, setEditAdjustmentIsOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>({});


    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "5%",
                        marginBottom: "0px",
                    }}
                >
                    #
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "30%",
                        marginBottom: "0px",
                    }}
                >
                    Charge or Adjustment
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "30%",
                        marginBottom: "0px",
                    }}
                >
                    Quantity
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "20%",
                        marginBottom: "0px",
                    }}
                >
                    Amount/Percentage
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "20%",
                        marginBottom: "0px",
                    }}
                >
                    Comments
                </p>
            </div>
            <hr style={{ marginBlock: "0px" }} />

            {adjustmentData?.map((item: any, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "5%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.id}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "30%",
                                    marginBottom: "0px",
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => {
                                    setModalData(item);
                                    setEditAdjustmentIsOpen(true);
                                }}
                            >
                                {item?.name}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "30%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.quantity}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "20%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.basePrice}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "20%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.comment}
                            </p>
                        </div>
                        <hr style={{ marginBlock: "0px" }} />
                    </React.Fragment>
                );
            })}

            <EditAdjustmentModal
                editAdjustmentIsOpen={editAdjustmentIsOpen}
                setEditAdjustmentIsOpen={setEditAdjustmentIsOpen}
                modalData={modalData}
                setAdjustmentRefresh={setAdjustmentRefresh}
                setIsExtraValueIsAdded={setIsExtraValueIsAdded}
                reservation={reservation}
                setReservationUpdate={setReservationUpdate}
            />
        </>
    )
}

export default AdjustmentList;