import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SecuityDeposit from "./SecuityDeposit";
import { CurrentType } from "../../../enum/CurrentType";

type Props = {
  currentPage: string;
  currentType: CurrentType;
  showDateAndTimeBox: () => void;
  outstandingBalance: number;
  totalAmount: number;
  status: string;
  branches: any[];
  branchId: string;
  pickupDate: string;
  pickupTime: string;
  returnDate: string;
  returnTime: string;
  pickupLocation: string;
  returnLocation: string;
  locations: any[];
  selectedVehicleClass: any;
  totalOfSelectedVehicleClass: number;
  totalHours: number;
  hourlyRate: number;
  totalDays: number;
  dailyRate: number;
  totalWeeks: number;
  weeklyRate: number;
  totalMonths: number;
  monthilyRate: number;
  checkExtraValue: any[];
  reservation: any;
  paymentList: any[];
  refundList: any[];
  securityDepositList: any[];
  discountTotalAmount: number;
  discountList: any[];
  securityDepositAmountToPaid: string;
  updateSecurityDepositAmountToPaid: (updateAmount: string) => void;
};

export default function Summary({
  currentPage,
  currentType,
  showDateAndTimeBox,
  outstandingBalance,
  totalAmount,
  status,
  branches,
  branchId,
  pickupDate,
  pickupTime,
  returnDate,
  returnTime,
  pickupLocation,
  returnLocation,
  locations,
  selectedVehicleClass,
  totalOfSelectedVehicleClass,
  totalHours,
  hourlyRate,
  totalDays,
  dailyRate,
  totalWeeks,
  weeklyRate,
  totalMonths,
  monthilyRate,
  checkExtraValue,
  reservation,
  paymentList,
  refundList,
  securityDepositList,
  discountTotalAmount,
  discountList,
  securityDepositAmountToPaid,
  updateSecurityDepositAmountToPaid
}: Props) {
  const [statusColor, setStatusColor] = useState<string>("#bdbdbd");
  const [branchName, setBranchName] = useState<string>("");
  const [pickupLocationName, setPickupLocationName] = useState<string>("");
  const [returnLocationName, setReturnLocationName] = useState<string>("");

  const [totalAfterDiscount, setTotalAfterDiscount] = useState<number>(0.0);

  const [openSecurityDepositDialog, setOpenSecurityDepositDialog] =
    useState<boolean>(false);

  let latestPickup = new Date();
  let latestReturn = new Date();
  if (pickupDate) latestPickup = new Date(pickupTime ? `${pickupDate}T${pickupTime}` : pickupDate);
  if (returnDate) latestReturn = new Date(returnTime ? `${returnDate}T${returnTime}` : returnDate);

  useEffect(() => {
    if (branches) setBranchName(branches.find((item: any) => item.id === parseInt(branchId))?.branchName);
  }, [branchId, branches]);

  useEffect(() => {
    switch (status) {
      case "Quote":
        setStatusColor("#bdbdbd");
        break;
      case "Open":
        setStatusColor("#f0ad4e");
        break;
      case "Rental":
        setStatusColor("#66BB6A");
        break;
      case "Completed":
        setStatusColor("#253455");
        break;
      case "Cancel":
        setStatusColor("#ff0000");
        break;
      case "Pending":
        setStatusColor("#808080");
        break;
      case "No Show":
        setStatusColor("#e0e0e0");
        break;
    }
  }, [status]);

  useEffect(() => {
    if (locations.length > 0) setPickupLocationName(locations?.find((item: any) => item.id === parseInt(pickupLocation))?.locationName);
  }, [locations, pickupLocation])

  useEffect(() => {
    if (locations.length > 0) setReturnLocationName(locations?.find((item: any) => item.id === parseInt(returnLocation))?.locationName);
  }, [locations, returnLocation])

  useEffect(() => {
    setTotalAfterDiscount(0.0);
    setTotalAfterDiscount(totalOfSelectedVehicleClass - discountTotalAmount);
  }, [discountTotalAmount, totalOfSelectedVehicleClass]);

  return (
    <>
      {currentPage !== "Dates" && (
        <div style={{ padding: "24px 24px 24px 0px" }}>
          <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>Summary</h5>
          <hr />

          {(reservation && parseFloat(securityDepositAmountToPaid) !== 0) && (
            <p
              style={{
                width: "100%",
                fontSize: "10px",
                backgroundColor: "#f1dedf",
                color: "#bb6a68",
                textAlign: "start",
                borderRadius: "3%",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "24px",
              }}
            >
              The Security Deposit hasn't been released yet.
            </p>
          )}

          {reservation && Number(reservation?.outstandingBalance) !== 0 && (
            <p
              style={{
                width: "100%",
                fontSize: "10px",
                backgroundColor: "#fdf8e3",
                color: "#8a6d3b",
                textAlign: "start",
                borderRadius: "3%",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "32px",
              }}
            >
              The Reservation has an outstanding balance of $
              {Number(reservation?.outstandingBalance)?.toFixed(2)}
            </p>
          )}

          <p
            style={{
              width:
                status === "No Show"
                  ? "50px"
                  : status === "Completed"
                    ? "60px"
                    : "45px",
              fontSize: "10px",
              backgroundColor: statusColor,
              color: "white",
              textAlign: "center",
              borderRadius: "2px",
            }}
          >
            {status}
          </p>

          <h6
            style={{ fontSize: "13px", marginTop: "22px", marginBottom: "0px" }}
          >
            Branch
          </h6>
          <p style={{ fontSize: "12px", marginTop: "0px" }}>{branchName}</p>

          {reservation?.dateTimeCheckOut && (
            <>
              <h6
                style={{
                  fontSize: "13px",
                  marginTop: "22px",
                  marginBottom: "0px",
                }}
              >
                Pickup
              </h6>
              <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                {format(
                  new Date(reservation?.dateTimeCheckOut),
                  "EEEE, MMMM dd, yyyy @ KK:mm a"
                )}
              </p>
            </>
          )}

          <h6
            style={{
              fontSize: "13px",
              marginTop: reservation?.dateTimeCheckOut ? "0px" : "22px",
              marginBottom: "0px",
            }}
          >
            Initial Pickup{" "}
            {(!reservation || status === 'Open') && (
              <EditIcon
                style={{
                  color: "#4FC3F7",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => showDateAndTimeBox()}
              />
            )}
          </h6>
          <p style={{ fontSize: "12px", marginTop: "0px" }}>
            {format(latestPickup, "EEEE, MMMM dd, yyyy @ KK:mm a")}
            <br />
            Location:{" "}
            {pickupLocationName}
          </p>

          {reservation?.dateTimeCheckIn && (
            <>
              <h6
                style={{
                  fontSize: "13px",
                  marginTop: "22px",
                  marginBottom: "0px",
                }}
              >
                Return
              </h6>
              <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                {format(
                  new Date(reservation?.dateTimeCheckIn),
                  "EEEE, MMMM dd, yyyy @ KK:mm a"
                )}
              </p>
            </>
          )}

          <h6
            style={{
              fontSize: "13px",
              marginTop: reservation?.dateTimeCheckIn ? "0px" : "22px",
              marginBottom: "0px",
            }}
          >
            Initial Return{" "}
            {(!reservation || status === 'Open') && (
              <EditIcon
                style={{
                  color: "#4FC3F7",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => showDateAndTimeBox()}
              />
            )}
          </h6>
          <p style={{ fontSize: "12px", marginTop: "0px" }}>
            {format(latestReturn, "EEEE, MMMM dd, yyyy @ KK:mm a")}
            <br />
            Location:{" "}
            {returnLocationName}
          </p>

          {currentPage !== "Cars" && (
            <div>
              <h6
                style={{
                  fontSize: "13px",
                  marginTop: "22px",
                  marginBlock: "0px",
                }}
              >
                {selectedVehicleClass?.vehicleName}
                <br />
                {currentType === CurrentType.ADD_FROM_QUOTES &&
                  <span style={{ color: "red" }}>{selectedVehicleClass?.availableVehicles <= 0 ? "(Not Available)" : ""}</span>
                }
              </h6>

              {totalMonths !== 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    {totalMonths}x Months:
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    $
                    {monthilyRate?.toFixed(2)}
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    $
                    {(totalMonths * monthilyRate)?.toFixed(2)}
                  </p>
                </div>
              )}

              {totalWeeks !== 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    {totalWeeks}x Weeks:
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    $
                    {weeklyRate?.toFixed(2)}
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    $
                    {(totalWeeks * weeklyRate)?.toFixed(2)}
                  </p>
                </div>
              )}

              {totalDays !== 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    {totalDays}x Days:
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    $
                    {dailyRate?.toFixed(2)}
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    $
                    {(totalDays * dailyRate)?.toFixed(2)}
                  </p>
                </div>
              )}

              {totalHours !== 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    {totalHours}x Hours:
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    ${hourlyRate?.toFixed(2)}
                  </p>
                  <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                    $
                    {(totalHours * hourlyRate)?.toFixed(2)}
                  </p>
                </div>
              )}
              <hr style={{ marginBlock: "0px" }} />

              {discountList.map((item: any) => {
                return (
                  <div
                    key={item.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                      {item.discountDefinition}:
                    </p>
                    <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                      -${parseFloat(item.discountAmount)?.toFixed(2)}
                    </p>
                  </div>
                );
              })}

              <p
                style={{
                  display: "flex",
                  justifyContent: "end",
                  fontSize: "12px",
                  marginBlock: "0px",
                }}
              >
                ${totalAfterDiscount?.toFixed(2)}
              </p>

              {checkExtraValue.map((item: any) => {
                return (
                  <div key={item.id}>
                    <h6
                      style={{
                        fontSize: "13px",
                        marginTop: "3px",
                        marginBottom: "0px",
                      }}
                    >
                      {item?.categoryName}
                    </h6>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          marginBlock: "0px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingRight: "24px",
                        }}
                      >
                        {item?.name}:
                      </p>
                      <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                        ${parseFloat(item?.amountOrPercentage)?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                );
              })}

              <h6
                style={{
                  fontSize: "13px",
                  marginTop: "3px",
                  marginBottom: "0px",
                }}
              >
                Included Distance
              </h6>
              <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                {selectedVehicleClass?.distanceLimitperDay *
                  selectedVehicleClass?.days}{" "}
                miles
              </p>

              {paymentList.length !== 0 && (
                <>
                  <h6
                    style={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      marginTop: "3px",
                      marginBottom: "0px",
                    }}
                  >
                    Payments
                  </h6>

                  {paymentList.map((item: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        key={item.id}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            marginBlock: "0px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            paddingRight: "24px",
                          }}
                        >
                          {item?.reference}:
                        </p>
                        <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                          ${parseFloat(item?.amount)?.toFixed(2)}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}

              {refundList.length !== 0 && (
                <>
                  <h6
                    style={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      marginTop: "3px",
                      marginBottom: "0px",
                    }}
                  >
                    Refunds
                  </h6>

                  {refundList.map((item: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        key={item.id}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            marginBlock: "0px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            paddingRight: "24px",
                          }}
                        >
                          {item?.reference}:
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            marginBlock: "0px",
                            color: item?.amount < 0 ? "red" : "black",
                          }}
                        >
                          ${Math.abs(parseFloat(item?.amount))?.toFixed(2)}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}

              {securityDepositList.length !== 0 && (
                <>
                  <h6
                    style={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      marginTop: "3px",
                      marginBottom: "0px",
                    }}
                  >
                    Security Deposits
                  </h6>

                  {securityDepositList.map((item: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        key={item.id}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            marginBlock: "0px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            paddingRight: "24px",
                          }}
                        >
                          {item?.reference}:
                        </p>
                        <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                          ${parseFloat(item?.amount)?.toFixed(2)}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}

              <h6
                style={{
                  fontSize: "13px",
                  marginTop: "14px",
                  marginBottom: "0px",
                }}
              >
                Total
              </h6>
              <p
                style={{
                  display: "flex",
                  justifyContent: "end",
                  fontSize: "18px",
                  marginBlock: "0px",
                }}
              >
                ${Number(totalAmount)?.toFixed(2)}
              </p>

              {currentPage !== "Dates" &&
                currentPage !== "Cars" &&
                currentPage !== "Extras" && (
                  <>
                    <h6
                      style={{
                        fontSize: "13px",
                        marginTop: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      Outstanding Balance
                    </h6>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "18px",
                        marginBlock: "0px",
                      }}
                    >
                      ${outstandingBalance?.toFixed(2)}
                    </p>
                  </>
                )}

              <>
                <h6
                  style={{
                    fontSize: "13px",
                    marginTop: "22px",
                    marginBlock: "0px",
                  }}
                >
                  Security Deposit
                </h6>

                <p
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    color: "red",
                    fontSize: "14px",
                    marginBlock: "0px",
                  }}
                >
                  ${Number(securityDepositAmountToPaid).toFixed(2)}{" "}
                  <EditIcon
                    style={{
                      color: "#4FC3F7",
                      fontSize: "14px",
                      marginLeft: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenSecurityDepositDialog(true)}
                  />
                </p>
              </>

              {reservation &&
                <>
                  <p
                    style={{
                      fontSize: "18px",
                      marginBottom: "0px",
                    }}
                  >
                    Audits
                  </p>

                  <hr />

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%" }}>
                      <h6
                        style={{
                          fontSize: "13px",
                          marginTop: "22px",
                          marginBlock: "0px",
                        }}
                      >
                        Created by
                      </h6>
                      <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                        {reservation?.createdBy}
                      </p>
                    </div>

                    <div style={{ width: "50%" }}>
                      <h6
                        style={{
                          fontSize: "13px",
                          marginTop: "22px",
                          marginBlock: "0px",
                        }}
                      >
                        Created At
                      </h6>
                      <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                        {format(new Date(reservation?.createdAt ? reservation?.createdAt : ""), "MM/dd/yyyy KK:mm a")}
                        <br />
                      </p>
                    </div>
                  </div>

                  {reservation?.rentalUser &&
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "50%" }}>
                        <h6
                          style={{
                            fontSize: "13px",
                            marginTop: "22px",
                            marginBlock: "0px",
                          }}
                        >
                          Rental by
                        </h6>
                        <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                          {reservation?.rentalUser}
                        </p>
                      </div>

                      <div style={{ width: "50%" }}>
                        <h6
                          style={{
                            fontSize: "13px",
                            marginTop: "22px",
                            marginBlock: "0px",
                          }}
                        >
                          Rental At
                        </h6>
                        <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                          {format(new Date(reservation?.rentalAt ? reservation?.rentalAt : ""), "MM/dd/yyyy KK:mm a")}
                          <br />
                        </p>
                      </div>
                    </div>
                  }

                  {reservation?.completedBy &&
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "50%" }}>
                        <h6
                          style={{
                            fontSize: "13px",
                            marginTop: "22px",
                            marginBlock: "0px",
                          }}
                        >
                          Completed by
                        </h6>
                        <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                          {reservation?.completedBy}
                        </p>
                      </div>

                      <div style={{ width: "50%" }}>
                        <h6
                          style={{
                            fontSize: "13px",
                            marginTop: "22px",
                            marginBlock: "0px",
                          }}
                        >
                          Completed At
                        </h6>
                        <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                          {format(new Date(reservation?.completedAt ? reservation?.completedAt : ""), "MM/dd/yyyy KK:mm a")}
                          <br />
                        </p>
                      </div>
                    </div>
                  }

                  {reservation?.reopenedBy &&
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "50%" }}>
                        <h6
                          style={{
                            fontSize: "13px",
                            marginTop: "22px",
                            marginBlock: "0px",
                          }}
                        >
                          Reopened by
                        </h6>
                        <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                          {reservation?.reopenedBy}
                        </p>
                      </div>

                      <div style={{ width: "50%" }}>
                        <h6
                          style={{
                            fontSize: "13px",
                            marginTop: "22px",
                            marginBlock: "0px",
                          }}
                        >
                          Reopened At
                        </h6>
                        <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                          {format(new Date(reservation?.reopenedAt ? reservation?.reopenedAt : ""), "MM/dd/yyyy KK:mm a")}
                          <br />
                        </p>
                      </div>
                    </div>
                  }

                </>
              }
            </div>
          )}
        </div>
      )}

      <SecuityDeposit
        openSecurityDepositDialog={openSecurityDepositDialog}
        setOpenSecurityDepositDialog={() => setOpenSecurityDepositDialog(false)}
        reservation={reservation}
        securityDepositAmountToPaid={securityDepositAmountToPaid}
        updateSecurityDepositAmountToPaid={(updateAmount: string) => updateSecurityDepositAmountToPaid(updateAmount)}
      />
    </>
  );
}
