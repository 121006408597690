import { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs";
import { Select, MenuItem } from "@mui/material";
import { format } from "date-fns";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import baseURL from "../../configs/api";

const days: string[] = ["7 Days", "14 Days", "30 Days"];
type Props = {
  date: Date;
};

export default function UtilizationGraph({ date }: Props) {
  let dates = new Date(date);
  dates.setDate(dates.getDate() - 1);
  const [dayName, setDayName] = useState<string>(days[0]);
  const [daysValue, setDaysValue] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<string>("");
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get(`${baseURL}utilizationGraph/TotalVehicleCount`)
      .then((response) => {
        setTotalCount(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (date) {
      const updatedDate = new Date(date);
      let formatDateString = `${updatedDate.getFullYear()}-${updatedDate.getMonth() + 1}-${updatedDate.getDate()}T23:59:59Z`;

      axios
        .get(`${baseURL}utilizationGraph?startDate=${formatDateString}`)
        .then((response) => {
          setData(() => response.data);

          switch (dayName) {
            case days[0]:
              setDaysValue(() =>
                response.data.filter((_: any, index: number) => index < 7)
              );
              break;
            case days[1]:
              setDaysValue(() =>
                response.data.filter((_: any, index: number) => index < 14)
              );
              break;
            case days[2]:
              setDaysValue(() =>
                response.data.filter((_: any, index: number) => index < 30)
              );
              break;
          }

        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const onDateChange = (daysName: string) => {
    setDayName(daysName)
    switch (daysName) {
      case days[0]:
        setDaysValue(() => data.filter((_, index: number) => index < 7));
        break;
      case days[1]:
        setDaysValue(() => data.filter((_, index: number) => index < 14));
        break;
      case days[2]:
        setDaysValue(() => data.filter((_, index: number) => index < 30));
        break;
    }
  }

  const getOrdinalSuffix = (day: number): string => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <div
      style={{
        margin: "10px",
        border: "2px solid",
        borderBlockColor: colorConfigs.topbar.bg,
        borderRadius: "5px",
      }}
    >
      <nav
        style={{
          background: colorConfigs.topbar.bg,
          color: "white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 16px",
        }}
      >
        <div style={{ fontSize: "12px" }}>Utilization Graph (All Vehicles)</div>
        <div>
          <Select
            variant="standard"
            disableUnderline
            sx={{
              color: "white",
              fontSize: "12px",
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
            value={dayName}
            onChange={(e) => onDateChange(e.target.value)}
          >
            {days.map((day: string, index: number) => (
              <MenuItem sx={{ fontSize: "12px" }} key={index} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </div>
      </nav>

      {daysValue.map((item: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <div style={{ width: "20%", fontSize: "12px" }}>
              {format(
                dates.setDate(dates.getDate() + 1),
                `MMMM d'${getOrdinalSuffix(dates.getDate())}`
              )}
            </div>

            <div style={{ width: "80%" }}>
              <ProgressBar
                customLabelStyles={{
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                completed={parseInt(item.count)}
                bgColor={colorConfigs.topbar.bg}
                height="22px"
                borderRadius="5px"
                baseBgColor="#e0e0e0"
                labelColor="white"
                maxCompleted={parseInt(totalCount)}
                customLabel={`${(
                  (parseFloat(item.count) * 100) /
                  parseFloat(totalCount)
                ).toFixed(1)}%(${item.count}/${totalCount})`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
