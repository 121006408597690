import axios from "axios"
import baseURL from "../configs/api"

export const post_EarlyReturn = (data: any, userId: number) => {
    return axios({
        url: `${baseURL}earlyReturn/?${userId}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_EarlyReturn = (data: any, userId: number) => {
    return axios({
        url: `${baseURL}earlyReturn?${userId}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}


export const getEarlyReturnByReservationId= (primaryKey: string) => {
    return getFetch(`earlyReturn/GetEarlyReturnsByReservationId/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}