import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import BusinessIcon from "@mui/icons-material/Business";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import colorConfigs from "../../../configs/colorConfigs";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../configs/api";

import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import axois from "../../../configs/axois";
import { ViewRepairOrder } from "./ViewRepairOrder";
import { format } from "date-fns";
import AddComments from "../../AddComments";
import Webcam from "react-webcam";
import { delete_comment, getCommentsOfAttribute, post_comment } from "../../../services/comment";
import useAuth from "../../../components/hooks/useAuth";
import { handleCapture } from "../../../utils/covertTheBase64FormatIntoImage";

const pages = ["View", "Edit"];

type CommentType = {
  id: number;
  attributeName: string;
  attributeId: string;
  notes: string;
  userId: string;
  createdDate: string;
};

const fuelLevelOptions: any = [];
for (let i = 0; i <= 8; i++) {
  fuelLevelOptions.push(<option key={i} value={i}>{i}</option>);
}

export const EditRepariOrder = () => {
  const {auth}: any = useAuth();
  const [vehicleOptions, setVehicleOption] = React.useState([]);

  const [saveCommentsValue, setSaveCommentsValue] = useState([]);
  const [isComment, setIsComment] = useState(0);

  const [isClone, setIsClone] = useState<boolean>(false);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<any[]>([]);
  const [newFilePath, setNewFilePath] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [isImageSaved, setIsImageSaved] = useState<boolean>(false);
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  const idNumber = useLocation();

  useEffect(() => {
    axois
      .get(`${baseURL}vehicles`)
      .then((response) => {
        setVehicleOption(
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [mechanicOption, setMechanicOption] = useState([]);

  useEffect(() => {
    axois
      .get(`${baseURL}users`)
      .then((response) => {
        setMechanicOption(
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleRepairOrder/${idNumber.state}`)
      .then((response) => {
        setId(response.data.id);
        const vehicleValue: any = vehicleOptions.find(
          (item: any) => item.value === response.data.vehicleId
        );
        setVehicle(vehicleValue.value);
        setMaintenanceType(response.data.maintenanceType);
        setTotalInParts(response.data.totalInParts);
        setTotalInLabour(response.data.totalInLabour);
        setDamages(response.data.damages);
        setStatus(response.data.status);
        setNotes(response.data.notes);
        setDateOut(response.data.dateOut.split("T")[0]);
        setTimeOut(response.data.dateOut.split("T")[1]);
        setDateDue(response.data.dateDue.split("T")[0]);
        setTimeDue(response.data.dateDue.split("T")[1]);
        setMechanic(response.data.mechanic);
        setMechanicComments(response.data.comments);

        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(response.data.notes).contentBlocks,
              convertFromHTML(response.data.notes).entityMap
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [vehicleOptions]);

  useEffect(() => {
    axois
      .get(`${baseURL}fileUploadData/vehicleRepairOrder/${idNumber.state}`)
      .then((response) => {
        setFilePath(() =>
          response.data.map((item: any) => {
            return {
              id: item.id,
              fileName: item.fileName.startsWith("data:image/png;base64,")
                ? item.fileName
                : `${baseURL}repairOrderFile/Get?name=` +
                  item.fileName,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const [id, setId] = useState<string>("");
  const [vehicle, setVehicle] = useState<string>("");
  const [maintenanceType, setMaintenanceType] = useState<string>("");
  const [totalInParts, setTotalInParts] = useState(0);
  const [totalInLabour, setTotalInLabour] = useState(0);
  const [damages, setDamages] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [dateOut, setDateOut] = useState<string>("");
  const [timeOut, setTimeOut] = useState<string>("T00:00:00");
  const [dateDue, setDateDue] = useState<string>("");
  const [timeDue, setTimeDue] = useState<string>("T00:00:00");
  const [mechanic, setMechanic] = useState<string>("");
  const [mechanicComments, setMechanicComments] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  const [firstTime, setFirstTime] = React.useState<String>(pages[1]);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPleaseWaitPopup(true);

    if (newFilePath.length !== 0) {
      newFilePath.forEach((item: string, index: number) => {
        axios
          .post(
            `${baseURL}fileUploadData`,
            JSON.stringify({
              attributeName: "VehicleRepairOrder",
              attributeId: idNumber.state,
              fileName: item?.replace(
                `${baseURL}repairOrderFile/Get?name=`,
                ""
              ),
              tabName: "VehicleRepairOrder",
              userId: auth?.id ?? 0,
              createdDate: new Date(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            if (newFilePath.length - 1 === index) {
              setIsImageSaved(true);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    } else {
      setIsImageSaved(true);
    }
  };

  useEffect(() => {
    if (isImageSaved) {
      if (isClone) {
        axios
          .post(
            `${baseURL}vehicleRepairOrder`,
            JSON.stringify({
              vehicleId: 2,
              maintenanceType,
              totalInParts,
              totalInLabour,
              status,
              notes,
              dateOut:
                timeOut === "T00:00:00"
                  ? dateOut + timeOut
                  : dateOut + ("T" + timeOut),
              dateDue:
                timeDue === "T00:00:00"
                  ? dateDue + timeDue
                  : dateDue + ("T" + timeDue),
              mechanic,
              comments: mechanicComments,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then((response) => {
            navigate(-1);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        axios
          .put(
            `${baseURL}vehicleRepairOrder`,
            JSON.stringify({
              id,
              vehicleId: vehicle,
              maintenanceType,
              totalInParts,
              totalInLabour,
              status,
              notes,
              dateOut:
                timeOut === "T00:00:00"
                  ? dateOut + timeOut
                  : dateOut + ("T" + timeOut),
              dateDue:
                timeDue === "T00:00:00"
                  ? dateDue + timeDue
                  : dateDue + ("T" + timeDue),
              mechanic,
              comments: mechanicComments,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then((response) => {
            navigate(-1);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      setIsImageSaved(false);
    }
  }, [isImageSaved]);

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}repairOrderFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          const newFilePath: any = {
            id: 0,
            fileName:
              `${baseURL}repairOrderFile/Get?name=` +
              response.data,
          };

          setNewFilePath((prevPath: string[]) => [
            ...prevPath,
            newFilePath.fileName,
          ]);
          setFilePath((prevPath: any[]) => [...prevPath, newFilePath]);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setPleaseWaitPopup(false);
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setNotes(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  useEffect(() => {
    getCommentsOfAttribute("VehicleRepairOrder", idNumber.state)
      .then(data => setSaveCommentsValue(data))
      .catch(error => console.error(error));
  }, [isComment]);

  const saveComments = () => {
    post_comment(
      JSON.stringify({
        attributeName: "VehicleRepairOrder",
        attributeId: idNumber.state,
        notes: comments,
        userId: auth?.id,
        createdDate:
          format(new Date(), "yyyy-MM-dd") +
          "T" +
          format(new Date(), "KK:mm:ss"),
      }))
      .then(data => {
        setComments("");
        setIsComment(isComment + 1);
      })
      .catch(error => console.error("Error:", error));
  };

  const deleteTheComment = async (id: number) => {
    if (
        window.confirm(
            "Are you sure you want to delete this comment?"
        )
    ) {
        setPleaseWaitPopup(true);
        delete_comment(id)
            .then(data => {
                setPleaseWaitPopup(false);
                setSaveCommentsValue(saveCommentsValue.filter((item: any) => item.id !== id));
            })
            .catch(error => console.error("Error:", error));
    }
}

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <BusinessIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Repair Orders / Edit Repair Order
          </Typography>
        </Box>

        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "black",
            borderRadius: "0",
            border: "none",
          }}
        >
          <LocalPrintshopOutlinedIcon />
        </Button>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>

        </Container>
      </AppBar>

      <div
        style={{
          width: "100%",
          display: firstTime === pages[1] ? "flex" : "none",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "50%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            General Information
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <label
              htmlFor="vehicle"
              style={{ fontSize: "12px", fontStyle: "bold" }}
            >
              Vehicle
              <br />
              <Select
                styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                className="basic-single"
                classNamePrefix="select"
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={false}
                name="vehicle"
                value={vehicleOptions.find(
                  (item: any) => item.value === vehicle
                )}
                onChange={(vehicle: any) =>
                  setVehicle(vehicle ? vehicle.value : "")
                }
                options={vehicleOptions}
                required
              />
            </label>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="maintenanceType"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Maintenance Type
                    <br />
                    <select
                      name="maintenanceType"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={maintenanceType}
                      onChange={(e) => setMaintenanceType(e.target.value)}
                    >
                      <option value="VAN">VAN</option>
                      <option value="SUV">SUV</option>
                    </select>
                  </label>
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "50%", marginRight: "3%" }}>
                    <label
                      htmlFor="totalInParts"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Total In Parts
                      <br />
                      <input
                        type="number"
                        name="totalInParts"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={totalInParts}
                        onChange={(e) =>
                          setTotalInParts(e.target.valueAsNumber)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ width: "50%" }}>
                    <label
                      htmlFor="totalInLabor"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Total In Labor
                      <br />
                      <input
                        type="number"
                        name="totalInLabor"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={totalInLabour}
                        onChange={(e) =>
                          setTotalInLabour(e.target.valueAsNumber)
                        }
                        required
                      />
                    </label>
                  </div>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="image"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Images
                    <br />
                    <div
                      style={{
                        maxWidth: "50%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {filePath.map((item: any, outerIndex: number) => {
                        return (
                          <>
                            <img
                              src={item.fileName}
                              width={40}
                              height={40}
                              alt="repairOrder"
                              onClick={() => {
                                setFileIndex(outerIndex);
                                setImagePopup(true);
                              }}
                            />
                            <RemoveCircleIcon
                              sx={{
                                fontSize: "14px",
                                color: colorConfigs.topbar.bg,
                                fontWeight: "bold",
                                "&: hover": {
                                  color: "red",
                                },
                              }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this image?"
                                  )
                                ) {
                                  let newFileSet: any[] = filePath.filter(
                                    (_, index: number) => index !== outerIndex
                                  );

                                  if (filePath[outerIndex].id === 0) {
                                    setFilePath(() => newFileSet);
                                  } else {
                                    axios
                                      .delete(
                                        `${baseURL}fileUploadData?id=${filePath[outerIndex].id}`
                                      )
                                      .then((response) => {
                                        setFilePath(() => newFileSet);
                                      })
                                      .catch((error) => {
                                        console.error("Error:", error);
                                      });
                                  }
                                }
                              }}
                            />
                          </>
                        );
                      })}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputFileRef}
                      onChangeCapture={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => {
                          if (inputFileRef.current !== null) {
                            inputFileRef.current.click();
                          }
                        }}
                      >
                        {" "}
                        <AddIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                        Add Image
                      </p>

                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={() => setShowWebcam(true)}
                      >
                        {" "}
                        <LocalSeeIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                      </p>
                    </div>
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ visibility: "hidden" }}>
                  <label
                    htmlFor="vehicleKey"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Vehicle Key
                    <br />
                    <input
                      type="text"
                      name="vehicleKey"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                    />
                  </label>
                </div>

                <div style={{ marginTop: "20px", visibility: "hidden" }}>
                  <label
                    htmlFor="vehicleMode"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Damages
                    <br />
                    <input
                      type="text"
                      name="vehicle"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={damages}
                      onChange={(e) => setDamages(e.target.value)}
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="vehiclSecondaryClass"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Status
                    <br />
                    <select
                      name="vehicleType"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Skipped">Skipped</option>
                      <option value="Done">Done</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <label
                htmlFor="odemeter"
                style={{ fontSize: "12px", fontStyle: "bold" }}
              >
                Notes
              </label>
              <br />

              <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={setEditorState}
                toolbar={{
                  inline: { inDropdown: false },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>

            <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Vehicle Out Information
            </h3>
            <hr />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="dateOut"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Date Out
                  <br />
                  <input
                    type="date"
                    data-date-format="MM-DD-YYYY"
                    name="dateOut"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={dateOut}
                    onChange={(e) => setDateOut(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="timeOut"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Time Out
                  <br />
                  <input
                    type="time"
                    data-time-format="hh:mm:ss"
                    name="timeOut"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={timeOut}
                    onChange={(e) => setTimeOut(e.target.value)}
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="dateDue"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Date Due
                  <br />
                  <input
                    type="date"
                    data-date-format="MM-DD-YYYY"
                    name="dateDue"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={dateDue}
                    onChange={(e) => setDateDue(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="timeDue"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Time Due
                  <br />
                  <input
                    type="time"
                    data-time-format="hh:mm:ss"
                    name="timeDue"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={timeDue}
                    onChange={(e) => setTimeDue(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Internal Management
            </h3>
            <hr />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ width: "50%", marginRight: "3%" }}>
                <label
                  htmlFor="mechanic"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Mechanic
                  <br />
                  <Select
                    styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    name="mechanic"
                    value={mechanicOption.find(
                      (item: any) => item.value === parseInt(mechanic)
                    )}
                    onChange={(mechanic: any) =>
                      setMechanic(mechanic ? mechanic.value : "")
                    }
                    options={mechanicOption}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "100%", marginRight: "3%" }}>
                <label
                  htmlFor="timeDue"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Comments
                </label>
                <br />

                <textarea
                  style={{ width: "100%", height: "15%", resize: "none" }}
                  value={mechanicComments}
                  onChange={(e) => setMechanicComments(e.target.value)}
                />
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#86c0ec",
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save and Create New
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#86c0ec",
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save and Clone
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>

        <div
          style={{
            width: "50%",
          }}
        >
          <h3 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
            Comments
          </h3>
          <hr />

          {saveCommentsValue &&
            saveCommentsValue.map((item: CommentType) => {
              return (
                <AddComments
                id={item.id}
                userId={item.userId}
                createdDate={item.createdDate}
                notes={item.notes}
                deleteTheComment={(id) => deleteTheComment(id)}
                />
              );
            })}

          <textarea
            style={{ width: "100%", height: "15%", resize: "none" }}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => saveComments()}
          >
            Save Comment
          </Button>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: firstTime === pages[0] ? "flex" : "none",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <ViewRepairOrder repairOrderId={idNumber.state} />
      </div>

      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => setShowWebcam(false)}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();

                    handleCapture(imageSrc, `${baseURL}repairOrderFile/upload`, (imageFilePath: string) => {
                      if (imageFilePath) {
                        setFilePath((prevFileString: string[]) => [
                          ...prevFileString,
                          `${baseURL}repairOrderFile/Get?name=${imageFilePath}`,
                        ]);

                        setNewFilePath((prevFileString: string[]) => [
                          ...prevFileString,
                          `${baseURL}repairOrderFile/Get?name=${imageFilePath}`,
                        ]);
    
                        const newFile: any = {
                          id: 0,
                          fileName: `${baseURL}repairOrderFile/Get?name=${imageFilePath}`
                        };
                        setFilePath((prevFileString: any[]) => [
                          ...prevFileString,
                          newFile,
                        ]);
                      }
                    })
                  
                    setShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              Repair Order Image
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {filePath.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  left: "0",
                  top: "50%",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    cursor: fileIndex !== 0 ? "pointer" : "",
                    opacity: fileIndex === 0 ? "0.5" : "1",
                    color: "gray",
                  }}
                  onClick={() => {
                    if (fileIndex !== 0) {
                      setFileIndex(fileIndex - 1);
                    }
                  }}
                />
              </div>
              <img
                width={700}
                height={350}
                src={filePath[fileIndex]?.fileName}
                alt="repairOrder"
              />

              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  right: "0",
                  top: "50%",
                  color: "gray",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    cursor: fileIndex !== filePath.length - 1 ? "pointer" : "",
                    opacity: fileIndex === filePath.length - 1 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== filePath.length - 1) {
                      setFileIndex(fileIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
