import axios from "axios"
import { ticketBaseURL } from "../configs/api";

export const post_Incident = async (data: any, userId: number) => {
    const response = await axios({
        url: `${ticketBaseURL}incident?${userId}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const put_Incident = async (data: any, userId: number) => {
    const response = await axios({
        url: `${ticketBaseURL}incident?${userId}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const getIncidentsReportByAppGroupIdAndByAppName = (appName: string, appGroupId: string, page: number, size: number) => {
    return getFetch(`incident/GetIncidentsReportByAppGroupIdAndByAppName/${appName},${appGroupId},${page},${size}`)
} 

export const getIncidentsReportByAppGroupIdAndByAppNameAndSearchWithIncidentName = (searchValue: string, appName: string, appGroupId: string, page: number, size: number) => {
    return getFetch(`incident/GetIncidentsReportByAppGroupIdAndByAppNameAndSearchWithIncidentName/${searchValue},${appName},${appGroupId},${page},${size}`)
}

export const getIncidentsReportByAppGroupIdAndByAppNameWithStatus = (status: string, appName: string, appGroupId: string, page: number, size: number) => {
    return getFetch(`incident/GetIncidentsReportByAppGroupIdAndByAppNameWithStatus/${status},${appName},${appGroupId},${page},${size}`)
}

export const getIncidentsReportByAppGroupIdAndByAppNameWithStatusAndSearchWithIncidentName = (searchValue: string, status: string, appName: string, appGroupId: string, page: number, size: number) => {
    return getFetch(`incident/GetIncidentsReportByAppGroupIdAndByAppNameWithStatusAndSearchWithIncidentName/${searchValue},${status},${appName},${appGroupId},${page},${size}`)
}

export const getIncidentById = (primaryKey: number) => {
    return getFetch(`incident/${primaryKey}`)
}

const getFetch = async (url: string, params = {}) => {
    const response = await axios({
        url: ticketBaseURL + url,
        method: "GET",
        params: params
    });
    return response.data;
}