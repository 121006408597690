import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import baseURL, { appName } from "../../../configs/api";
import { PDFDocument } from "pdf-lib";
import PleaseWaitPopup from "../../PleaseWaitPopup";

const PrintQuotes = () => {
  const location = useLocation();
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>("");

  // Helper function to convert an ArrayBuffer to base64
  const arrayBufferToBase64 = (buffer: Uint8Array) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    async function fetchAndConvertPdf() {
      setPleaseWaitPopup(true);
      try {
        const response = await axios.get(
          `${baseURL}QuotePdf?quoteId=${id}`,
          {
            responseType: "arraybuffer",
          }
        );

        const pdfData = new Uint8Array(response.data);

        const pdfDoc = await PDFDocument.load(pdfData);

        // Set custom metadata (including the file name)
        pdfDoc.setTitle(`Quote${id}.pdf`);
        pdfDoc.setAuthor(appName);
        pdfDoc.setSubject("Quote");
        pdfDoc.setProducer(appName);
        pdfDoc.setCreator(appName);

        // Serialize the modified PDF to a new Uint8Array
        const modifiedBytes = await pdfDoc.save();

        // Convert the modified Uint8Array to base64
        const modifiedBase64 = arrayBufferToBase64(modifiedBytes);

        setFileUrl(modifiedBase64);

        setPleaseWaitPopup(false);
      } catch (error) {
        setPleaseWaitPopup(false);
        console.error("Error fetching or converting PDF:", error);
      }
    }

    if (id) fetchAndConvertPdf();
  }, [location]);

  return (
    <>
      {!pleaseWaitPopup &&
        <embed
          src={`data:application/pdf;base64,${fileUrl}`}
          type="application/pdf"
          style={{
            width: "100%",
            minHeight: "100vh"
          }}
        />
      }
      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </>
  )
}

export default PrintQuotes