import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import colorConfigs from "../../../configs/colorConfigs";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import baseURL from "../../../configs/api";

type Props = {
  dateAndTimeBox: boolean;
  setDateAndTimeBox: (pickupBox: boolean) => void;
  pickupDate: string;
  setPickupDate: (pickupDateBox: string) => void;
  pickupTime: string;
  setPickupTime: (pickupTimeBox: string) => void;
  returnDate: string;
  setReturnDate: (returnDateBox: string) => void;
  returnTime: string;
  setReturnTime: (returnTimeBox: string) => void;
  setDateAndTimeChange: () => void;
  reservation: any;
};

export default function DateAndTimeChange({
  dateAndTimeBox,
  setDateAndTimeBox,
  pickupDate,
  setPickupDate,
  pickupTime,
  setPickupTime,
  returnDate,
  setReturnDate,
  returnTime,
  setReturnTime,
  setDateAndTimeChange,
  reservation
}: Props) {
  const [pickupDateBox, setPickupDateBox] = useState<string>("");
  const [pickupTimeBox, setPickupTimeBox] = useState<string>("");
  const [returnDateBox, setReturnDateBox] = useState<string>("");
  const [returnTimeBox, setReturnTimeBox] = useState<string>("");

  useEffect(() => {
    setPickupDateBox(pickupDate);
  }, [pickupDate]);

  useEffect(() => {
    setPickupTimeBox(pickupTime);
  }, [pickupTime]);

  useEffect(() => {
    setReturnDateBox(returnDate);
  }, [returnDate]);

  useEffect(() => {
    setReturnTimeBox(returnTime);
  }, [returnTime]);

  const updateReservation = (reservation: any, additionalData: any) => {
    return axios.put(
      `${baseURL}reservation`,
      {
        ...reservation,
        ...additionalData,
      },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
  };

  const handleSave = () => {
    const pickupDateTime = pickupDateBox + "T" + pickupTimeBox;
    const returnDateTime = returnDateBox + "T" + returnTimeBox;

    const additionalData = {
      pickupDate: pickupDateTime,
      returenDate: returnDateTime,
    };

    updateReservation(reservation, additionalData)
      .then(() => {
        setPickupDate(pickupDateBox);
        setPickupTime(pickupTimeBox);
        setReturnDate(returnDateBox);
        setReturnTime(returnTimeBox);
        setDateAndTimeBox(false);
        setDateAndTimeChange();
      })
      .catch((error) => {
        console.error("Error updating reservation:", error);
        alert("Failed to update reservation. Please try again.");
      });
  };

  return (
    <Dialog
      open={dateAndTimeBox}
      maxWidth="md"
      fullScreen={false}
      PaperProps={{
        style: {
          width: "24%",
          display: "flex",
          position: "absolute",
          top: "30px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", width: "100%" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 8px 16px",
          }}
        >
          <p style={{ fontSize: "14px", marginLeft: "5px" }}>
            Update Date and Times
          </p>
          <CloseIcon
            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => setDateAndTimeBox(false)}
          />
        </div>
        <hr style={{ marginBlock: "0px" }} />
      </DialogTitle>
      <DialogContent sx={{ padding: "0px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "8px",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            <div style={{ width: "100%" }}>
              <label
                htmlFor="pickupDate"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Pickup Date
                <br />
                <input
                  type="date"
                  name="pickupDate"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={pickupDateBox}
                  onChange={(e) => setPickupDateBox(e.target.value)}
                />
              </label>
            </div>

            <div style={{ width: "100%" }}>
              <label
                htmlFor="pickupTime"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Pickup Time
                <br />
                <input
                  type="time"
                  name="pickupTime"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={pickupTimeBox}
                  onChange={(e) => setPickupTimeBox(e.target.value)}
                />
              </label>
            </div>
          </div>

          <div
            style={{
              marginTop: "16px",
              marginBottom: "8px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "8px",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            <div style={{ width: "100%" }}>
              <label
                htmlFor="returnDate"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Return Date
                <br />
                <input
                  type="date"
                  name="returnDate"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={returnDateBox}
                  onChange={(e) => setReturnDateBox(e.target.value)}
                />
              </label>
            </div>

            <div style={{ width: "100%" }}>
              <label
                htmlFor="returnTime"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Return Time
                <br />
                <input
                  type="time"
                  name="returnTime"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={returnTimeBox}
                  onChange={(e) => setReturnTimeBox(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBlock: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundColor: "#eeeeee",
          }}
        >
          <div style={{ width: "50%" }} />

          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#e0dee0",
                color: colorConfigs.sidebar.color,
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                fontSize: "12px",
                "&: hover": {
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                },
              }}
              onClick={() => setDateAndTimeBox(false)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                fontSize: "12px",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
