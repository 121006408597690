import React, { useRef, useState } from 'react'
import { ExpandMore, KeyboardArrowRight, Add, LocalSee } from "@mui/icons-material";
import colorConfigs from '../../../configs/colorConfigs';
import axios from 'axios';
import baseURL from '../../../configs/api';
import { Dialog, DialogContent, Button } from '@mui/material';
import Webcam from 'react-webcam';
import ReactQuill from 'react-quill';
import { handleCapture } from '../../../utils/covertTheBase64FormatIntoImage';

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

type Props = {
    isPretendEmails: string;
    setIsPretendEmails: (value: string) => void;
    replyToEmail: string;
    setReplyToEmail: (value: string) => void;
    isDisabledSmsFailureNotificationEmails: string;
    setIsDisabledSmsFailureNotificationEmails: (value: string) => void;
    emailForFailureNotifications: string;
    setEmailForFailureNotifications: (value: string) => void;
    mainLayout: string;
    setMainLayout: (value: string) => void;
    emailSignature: string;
    setEmailSignature: (value: string) => void;
    mailFooter: string;
    setMailFooter: (value: string) => void;
    isShowLogoOnEmails: string;
    setIsShowLogoOnEmails: (value: string) => void;
    mailBarColor: string;
    setMailBarColor: (value: string) => void;
    emailFilePath: string;
    setEmailFilePath: (value: string) => void;
    pleaseWaitPopup: boolean;
    setPleaseWaitPopup: (value: boolean) => void;
};

const EmailSettings = ({
    isPretendEmails,
    setIsPretendEmails,
    replyToEmail,
    setReplyToEmail,
    isDisabledSmsFailureNotificationEmails,
    setIsDisabledSmsFailureNotificationEmails,
    emailForFailureNotifications,
    setEmailForFailureNotifications,
    mainLayout,
    setMainLayout,
    emailSignature,
    setEmailSignature,
    mailFooter,
    setMailFooter,
    isShowLogoOnEmails,
    setIsShowLogoOnEmails,
    mailBarColor,
    setMailBarColor,
    emailFilePath,
    setEmailFilePath,
    pleaseWaitPopup,
    setPleaseWaitPopup,
}: Props) => {
    const [isEmailSettings, setIsEmailSetttings] = useState<boolean>(true);
    const [showWebcam, setShowWebcam] = useState<boolean>(false);

    const webRef = useRef(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPleaseWaitPopup(true);
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            const formData = new FormData();
            formData.append("ImageFile", selectedFiles[0]);

            axios
                .post(`${baseURL}commonFile/upload`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                })
                .then((response) => {
                    setEmailFilePath(response.data);
                    setPleaseWaitPopup(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });

        } else {
            setPleaseWaitPopup(false);
        }
    };

    return (
        <>
            <h5
                style={{
                    marginBottom: "0px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    cursor: "pointer",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                }}
                onClick={() => setIsEmailSetttings(!isEmailSettings)}
            >
                {isEmailSettings ? (
                    <ExpandMore sx={{ fontSize: "20px" }} />
                ) : (
                    <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                )}{" "}
                Email Settings

            </h5>
            <hr />

            {isEmailSettings &&
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
                >

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label htmlFor="isPretendEmails" style={{ fontSize: "12px", fontWeight: "bold" }}>
                                Pretend Emails?
                            </label>{" "}
                            <br />
                            <label style={{ fontSize: "12px" }}>
                                <input
                                    type="radio"
                                    value="Yes"
                                    name="isPretendEmails"
                                    style={{ fontSize: "12px" }}
                                    checked={isPretendEmails === "Yes"}
                                    onChange={(e) =>
                                        setIsPretendEmails(e.target.checked ? "Yes" : "No")
                                    }
                                />
                                Yes
                            </label>
                            <label style={{ fontSize: "12px" }}>
                                <input
                                    type="radio"
                                    value="No"
                                    name="isPretendEmails"
                                    style={{ fontSize: "12px" }}
                                    checked={isPretendEmails === "No"}
                                    onChange={(e) =>
                                        setIsPretendEmails(e.target.checked ? "No" : "Yes")
                                    }
                                />
                                No
                            </label>
                        </div>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="replyToEmail"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Reply To (Email)
                                <br />
                                <input
                                    type="email"
                                    name="replyToEmail"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={replyToEmail}
                                    onChange={(e) => setReplyToEmail(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label htmlFor="isDisabledSmsFailureNotificationEmails" style={{ fontSize: "12px", fontWeight: "bold" }}>
                                Disable SMS failure notification emails?
                            </label>{" "}
                            <br />
                            <label style={{ fontSize: "12px" }}>
                                <input
                                    type="radio"
                                    value="Yes"
                                    name="isDisabledSmsFailureNotificationEmails"
                                    style={{ fontSize: "12px" }}
                                    checked={isDisabledSmsFailureNotificationEmails === "Yes"}
                                    onChange={(e) =>
                                        setIsDisabledSmsFailureNotificationEmails(e.target.checked ? "Yes" : "No")
                                    }
                                />
                                Yes
                            </label>
                            <label style={{ fontSize: "12px" }}>
                                <input
                                    type="radio"
                                    value="No"
                                    name="isDisabledSmsFailureNotificationEmails"
                                    style={{ fontSize: "12px" }}
                                    checked={isDisabledSmsFailureNotificationEmails === "No"}
                                    onChange={(e) =>
                                        setIsDisabledSmsFailureNotificationEmails(e.target.checked ? "No" : "Yes")
                                    }
                                />
                                No
                            </label>
                        </div>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="emailForFailureNotifications"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Email for Failure Notifications
                                <br />
                                <input
                                    type="text"
                                    name="emailForFailureNotifications"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={emailForFailureNotifications}
                                    onChange={(e) => setEmailForFailureNotifications(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px"
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="mainLayout"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Mail Layout
                                <br />
                                <input
                                    type="text"
                                    name="mainLayout"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={mainLayout}
                                    onChange={(e) => setMainLayout(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            padding: "16px 16px 0px 16px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "62px",
                                borderStyle: "solid solid none solid",
                                borderWidth: "1px",
                                borderColor: "gray",
                                textAlign: "center",
                                padding: "5px 5px 0px 5px",
                                fontSize: "12px"
                            }}
                        >
                            English
                        </div>

                        <div
                            style={{
                                width: "100%",
                                borderStyle: "none none solid none",
                                borderWidth: "1px",
                                borderColor: "gray",
                            }}
                        />
                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "10px",
                        padding: "0px 16px 0px 16px",
                    }}>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="preferredDateFormat"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Email Signature
                                <br />
                                <ReactQuill
                                    style={{ height: "150px", width: "100%" }}
                                    theme="snow"
                                    value={emailSignature}
                                    modules={modules}
                                    onChange={setEmailSignature}
                                />
                            </label>
                        </div>

                        <div style={{ width: "50%" }}>
                            <label
                                htmlFor="mailFooter"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Mail Footer
                                <br />
                                <ReactQuill
                                    style={{ height: "150px", width: "100%" }}
                                    theme="snow"
                                    value={mailFooter}
                                    modules={modules}
                                    onChange={setMailFooter}
                                />
                            </label>
                        </div>

                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginTop: "100px"
                    }}>
                        <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>

                            <div style={{ width: "50%" }}>
                                <label
                                    htmlFor="image"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Mail Bar Logo
                                    <br />
                                    <div
                                        style={{
                                            maxWidth: "50%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        {emailFilePath &&
                                            <>
                                                <img
                                                    src={emailFilePath.startsWith("data:image/png") ?
                                                        emailFilePath
                                                        :
                                                        `${baseURL}commonFile/Get?name=${emailFilePath}`}
                                                    width={40}
                                                    height={40}
                                                    alt="mailBarLogo"
                                                />
                                            </>
                                        }
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        name="mailBarLogo"
                                        ref={inputFileRef}
                                        onChangeCapture={onFileChangeCapture}
                                        style={{ display: "none" }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        <p
                                            style={{
                                                border: "1px dashed black",
                                                borderRadius: "3px",
                                                padding: "3px 5px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                            }}
                                            onClick={(e) => {
                                                if (inputFileRef.current !== null) {
                                                    inputFileRef.current.click();
                                                }
                                            }}
                                        >
                                            {" "}
                                            <Add
                                                style={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                }}
                                            />
                                            Add Image
                                        </p>

                                        <p
                                            style={{
                                                border: "1px dashed black",
                                                borderRadius: "3px",
                                                padding: "3px 5px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "12px",
                                                backgroundColor: "white",
                                            }}
                                            onClick={() => setShowWebcam(true)}
                                        >
                                            {" "}
                                            <LocalSee
                                                style={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                }}
                                            />
                                        </p>
                                    </div>
                                </label>

                            </div>

                            <div style={{ width: "50%" }}>
                                <label htmlFor="isShowLogoOnEmails" style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    Show Logo on Emails?
                                </label>{" "}
                                <br />
                                <label style={{ fontSize: "12px" }}>
                                    <input
                                        type="radio"
                                        value="Yes"
                                        name="isShowLogoOnEmails"
                                        style={{ fontSize: "12px" }}
                                        checked={isShowLogoOnEmails === "Yes"}
                                        onChange={(e) =>
                                            setIsShowLogoOnEmails(e.target.checked ? "Yes" : "No")
                                        }
                                    />
                                    Yes
                                </label>
                                <label style={{ fontSize: "12px" }}>
                                    <input
                                        type="radio"
                                        value="No"
                                        name="isShowLogoOnEmails"
                                        style={{ fontSize: "12px" }}
                                        checked={isShowLogoOnEmails === "No"}
                                        onChange={(e) =>
                                            setIsShowLogoOnEmails(e.target.checked ? "No" : "Yes")
                                        }
                                    />
                                    No
                                </label>
                            </div>

                        </div>

                        <div style={{ width: "50%", textAlign: "center" }}>
                            <label
                                htmlFor="mailBarColor"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Main Bar Color
                                <br />
                                <input
                                    type="color"
                                    name="mailBarColor"
                                    style={{
                                        fontSize: "14px",
                                    }}
                                    value={mailBarColor}
                                    onChange={(e) => setMailBarColor(e.target.value)}
                                />
                            </label>
                        </div>

                    </div>

                </div>
            }

            <Dialog
                open={showWebcam}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        marginTop: "-16%",
                    },
                }}
            >
                <DialogContent sx={{ padding: "0px" }}>
                    <Webcam
                        width={480}
                        height={320}
                        ref={webRef}
                        screenshotFormat="image/png"
                        audio={false}
                    />

                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => setShowWebcam(false)}
                            >
                                Cancel
                            </Button>
                        </div>

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "white",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                    },
                                }}
                                onClick={() => {
                                    if (webRef.current !== null) {
                                        //@ts-ignore
                                        const imageSrc = webRef.current.getScreenshot();

                                        handleCapture(imageSrc, `${baseURL}commonFile/upload`, (imageFilePath: string) => {
                                            if (imageFilePath) {
                                                setEmailFilePath(imageFilePath);
                                            }
                                        })

                                        setShowWebcam(false);
                                    }
                                }}
                            >
                                Capture an Image
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EmailSettings