import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import baseURL from '../../configs/api';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useAccessControl from '../hooks/useAccessControl';
import appRoutes from '../../routes/appRoutes';
import { getAccessControl } from '../../services/acessControl';
import { getAllowDenyStatus } from '../../services/allowStatus';
import appAdminRoutes from '../../routes/appAdminRoutes';
import { getPlans } from '../../services/Plan';
import { getGroup } from '../../services/Group';

function Login() {
    const { setAuth }: any = useAuth();
    const { setUserRoleId, setPermissions }: any = useAccessControl();

    const navigate = useNavigate();

    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLParagraphElement>(null);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [standardPlan, setStandardPlan] = useState<any>(null);
    const [isRememberMe, setIsRememberMe] = useState<boolean>(true);

    useEffect(() => {
        getPlans()
            .then((data: any) => {
                const standard = data.filter((plan: any) => plan.planName.includes("Standard"));
                standard.sort((a: any, b: any) => a.billingCycle.localeCompare(b.billingCycle));
                setStandardPlan(standard[0]);
            })
            .catch(error => console.error('Error fetching plans data:', error));
    }, []);

    useEffect(() => {
        userRef.current?.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isProcessing) {
            setIsProcessing(true);
            try {
                const response = await axios.post(`${baseURL}/Auth/login`,
                    JSON.stringify({ userName: user, password: pwd }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                );
                const jsonData: any = jwtDecode(response.data);
              const groupId = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"]
              getGroupData(groupId, response.data);
            } catch (err: any) {
                setErrMsg(err.response.data);
                errRef.current?.focus();
                setIsProcessing(false);
                console.error(err);
            }
        }
    }

    const getGroupData = (id: string, accessToken: string) => {
        getGroup(id)
          .then(res => {
            const adminUserId = res.adminUserId;
            const jsonData: any = jwtDecode(accessToken);
            const id = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
            const roles = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            const givenname = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
            const emailAddress = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
            const groupId = jsonData["http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"]
            const userName = user;
    
            let IsAdmin = false;
    
            if (parseInt(adminUserId) === parseInt(id)) {
              IsAdmin = true;
            }
    
            if (isRememberMe) {
              Cookies.set("token-jj-rental-ui", accessToken, {
                expires: jsonData.exp,
              });
              Cookies.set("role-jj-rental-ui", roles, {
                expires: jsonData.exp,
              });
              Cookies.set("groupId-jj-rental-ui", groupId, {
                expires: jsonData.exp,
              });
              Cookies.set("userId-jj-rental-ui", id, {
                expires: jsonData.exp,
              });
              Cookies.set("isAdmin-jj-rental-ui", IsAdmin ? "1" : "0", {
                expires: jsonData.exp,
              });
            } else {
              Cookies.set("token-jj-rental-ui", accessToken, {
                expires: new Date().setHours(new Date().getHours() + 1)
              });
              Cookies.set("role-jj-rental-ui", roles, {
                expires: new Date().setHours(new Date().getHours() + 1)
              });
              Cookies.set("groupId-jj-rental-ui", groupId, {
                expires: new Date().setHours(new Date().getHours() + 1)
              });
              Cookies.set("userId-jj-rental-ui", id, {
                expires: new Date().setHours(new Date().getHours() + 1)
              });
              Cookies.set("isAdmin-jj-rental-ui", IsAdmin ? "1" : "0", {
                expires: jsonData.exp,
              });
            }
    
            setAuth({ id, userName, givenname, emailAddress, roles, accessToken, groupId, IsAdmin: IsAdmin ? "1" : "0" });
            setUser('');
            setPwd('');      
    
            const userId = jsonData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]
            getUserData(userId, jsonData.exp, roles, accessToken);
          })
          .catch(error => {
            setIsProcessing(false);
            setErrMsg("Please enter username and password");
            console.error(error);
          });
      }

    const fetchPermissions = async (role: string, roleId: number, token: string) => {
        if (role.toLowerCase() === "admin") {
            navigate("/dashboard", { replace: true });
            return;
        }

        try {
            const res: any = await getAccessControl("accessControl", { roleId: roleId }, token.toString());
            const accessControlData = res;
            const response: any = await getAllowDenyStatus();

            const newPermissions: { [key: string]: boolean } = {};
            accessControlData.forEach((item: any) => {
                const normalizedSection = item.section.replace(/\s+/g, '').replace(/-/g, '').toLowerCase();
                newPermissions[normalizedSection] = item.allowDenyStatusId === response.filter((statusItem: any) => statusItem.allowDenyName === 'Allow')[0].id;
            });

            setPermissions(newPermissions);
            handleNavigationForAppRoutes(newPermissions);
        } catch (error) {
            console.error(error);
        }
    };


    const getUserData = (id: string, exp: any, roles: any, token: string) => {
        fetch(`${baseURL}users/${parseInt(id)}`)
            .then((res) => res.json())
            .then((userData) => {
                const roleId = userData.roleId;
                setUserRoleId(roleId);
                Cookies.set("roleId-jj-rental-ui", roleId, {
                    expires: exp,
                });

                fetchPermissions(roles, roleId, token);
                setIsProcessing(false);
            })
            .catch((error) => {
                setIsProcessing(false);
                console.error(error);
            });
    };

    const pages = [
        "dashboarddailymanifest",
        "dashboardbookings",
        "dashboardavailability",
        "dashboardfleet",
        "dashboardextras",
        "dashboardattempts",
    ];

    const handleNavigationForAppRoutes = (permissions: any) => {
        const dashboardRoute = {
            path: "/",
            child: pages.map(page => ({ section: page }))
        };

        const hasDashboardPermission = dashboardRoute.child.some((item: any) => {
            const hasPermission = permissions ? permissions[item.section] : false;
            return hasPermission;
        });

        if (hasDashboardPermission) {
            navigate("/dashboard", { replace: true });
        } else {
            const nextAvailableRoute = appRoutes.find((route: any) => {
                const hasPermission = permissions ? permissions[route.section] : false;

                if (route.child) {
                    const childRoute = route.child.find((child: any) => {
                        const hasChildPermission = permissions ? permissions[child.section] : false;
                        return hasChildPermission;
                    });
                    return childRoute !== undefined;
                }

                return hasPermission;
            });

            if (nextAvailableRoute) {
                const routeToNavigate = nextAvailableRoute.child?.find((child: any) => {
                    const hasChildPermission = permissions ? permissions[child.section] : false;
                    return hasChildPermission;
                }) || nextAvailableRoute;

                const pathToNavigate = routeToNavigate.path ?? "/unauthorized";
                navigate(pathToNavigate, { replace: true });
            } else {
                const nextAdminAvailableRoute = appAdminRoutes?.find((route: any) => {
                    const hasPermission = permissions ? permissions[route.section] : false;

                    if (route.child) {
                        const childRoute = route.child.find((child: any) => {
                            const hasChildPermission = permissions ? permissions[child.section] : false;
                            return hasChildPermission;
                        });
                        return childRoute !== undefined;
                    }

                    return hasPermission;
                });

                if (nextAdminAvailableRoute) {
                    const routeToNavigate = nextAdminAvailableRoute.child?.find((child: any) => {
                        const hasChildPermission = permissions ? permissions[child.section] : false;
                        return hasChildPermission;
                    }) || nextAdminAvailableRoute;

                    const pathToNavigate = routeToNavigate?.path ?? "/unauthorized";
                    navigate(pathToNavigate, { replace: true });
                } else {
                    navigate("/unauthorized", { replace: true });
                }
            }
        }
    };

    const containerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "sans-serif",
        color: "#4b5563",
        height: "100vh",
        padding: "1rem",
        backgroundColor: "#fbfbfb",
    };

    const formContainerStyle = {
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto",
    };

    const formStyle = {
        backgroundColor: "white",
        borderRadius: "1rem",
        padding: "1.5rem",
        boxShadow: "0 2px 16px -3px rgba(6, 81, 237, 0.3)",
    };

    const titleStyle = {
        fontSize: "1.875rem",
        color: "#000",
        fontWeight: "800",
        marginBottom: "2.5rem",
    };

    const inputContainerStyle: React.CSSProperties = {
        position: "relative",
        marginBottom: "2rem",
    };

    const inputStyle = {
        width: "100%",
        padding: "0.75rem",
        fontSize: "0.875rem",
        backgroundColor: "transparent",
        borderBottom: "2px solid #4b5563",
        color: "#111827",
        outline: "none",
        placeholderColor: "#bbb",
    };

    const svgStyle: React.CSSProperties = {
        position: "absolute",
        right: "1rem",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        width: "18px",
        height: "18px",
    };

    const checkboxContainerStyle = {
        display: "flex",
        alignItems: "center",
        marginTop: "1.5rem",
        justifyContent: "space-between",
    };

    const rememberMeStyle = {
        display: "flex",
        alignItems: "center",
    };

    const checkboxStyle = {
        height: "1rem",
        width: "1rem",
        borderRadius: "0.25rem",
        border: "2px solid #bbb",
    };

    const buttonStyle = {
        width: "100%",
        padding: "0.75rem",
        fontSize: "0.875rem",
        fontWeight: "600",
        borderRadius: "9999px",
        backgroundColor: "#295B7E",
        color: "white",
        cursor: "pointer",
        border: "none",
    };

    const errorMsgStyle: React.CSSProperties = {
        color: "red",
        fontSize: "0.875rem",
        marginTop: "1rem",
        textAlign: "center",
    };

    return (
        <div style={containerStyle}>
            <div style={formContainerStyle}>
                <form
                    style={formStyle}
                    onSubmit={handleSubmit}
                >
                    <div style={{ marginBottom: "2.5rem" }}>
                        <h3 style={titleStyle}>Sign in</h3>
                    </div>

                    <div style={inputContainerStyle}>
                        <input
                            name="email"
                            type="text"
                            required
                            ref={userRef}
                            style={inputStyle}
                            placeholder="Enter username"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                        />
                    </div>

                    <div style={inputContainerStyle}>
                        <input
                            name="password"
                            type={isShowPassword ? "text" : "password"}
                            required
                            style={inputStyle}
                            placeholder="Enter password"
                            value={pwd}
                            onChange={(e) => setPwd(e.target.value)}
                        />
                        <div
                            style={svgStyle}
                            onClick={() => setIsShowPassword((prev) => !prev)}
                        >
                            {isShowPassword ? (
                                <VisibilityOff style={{ cursor: 'pointer', color: '#bbb' }} />
                            ) : (
                                <Visibility style={{ cursor: 'pointer', color: '#bbb' }} />
                            )}
                        </div>
                    </div>

                    <div style={checkboxContainerStyle}>
                        <div style={rememberMeStyle}>
                            <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                style={checkboxStyle}
                                checked={isRememberMe}
                                onChange={() => setIsRememberMe(!isRememberMe)}
                            />
                            <label
                                htmlFor="remember-me"
                                style={{ marginLeft: "0.75rem", fontSize: "0.875rem" }}
                            >
                                Remember me
                            </label>
                        </div>
                        <div>
                            <Link
                                to="/forgot-password"
                                style={{ fontSize: "0.875rem", color: "#1d4ed8", fontWeight: "600" }}
                            >
                                Forgot Password?
                            </Link>
                        </div>
                    </div>

                    <div style={{ marginTop: "1rem", textAlign: "left" }}>
                        <p style={{ fontSize: "0.875rem", color: "#4b5563" }}>
                            Don't have an account yet?{" "}
                            <Link
                                to="/checkout"
                                state={{
                                    planId: standardPlan?.id,
                                    planName: standardPlan?.planName,
                                    price: 0,
                                    billingCycle: 'month',
                                    source: 'register'
                                }}
                                style={{ fontWeight: "600", color: "#1d4ed8" }}
                            >
                                Register
                            </Link>
                        </p>
                    </div>

                    <div style={{ marginTop: "1.5rem" }}>
                        <button type="submit" style={buttonStyle}>
                            {isProcessing ? (
                                <span>Loading...</span>
                            ) : (
                                "Sign in"
                            )}
                        </button>
                    </div>

                    {errMsg && (
                        <p ref={errRef} style={errorMsgStyle}>
                            {errMsg}
                        </p>
                    )}
                </form>
            </div>
        </div>
    )
}

export default Login