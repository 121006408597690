import { format } from "date-fns";
import React, { useState } from "react";
import EditEarlyReturnModal from "./EditEarlyReturnModal";

type EarlyReturnListProps = {
    earlyReturnData: any[],
    setEarlyReturnRefresh: (value: boolean) => void;
}

const EarlyReturnList = ({ earlyReturnData, setEarlyReturnRefresh }: EarlyReturnListProps) => {
    const [editEarlyReturnIsOpen, setEditEarlyReturnIsOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>({});



    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "5%",
                        marginBottom: "0px",
                    }}
                >
                    #
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "30%",
                        marginBottom: "0px",
                    }}
                >
                    Old Return
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "30%",
                        marginBottom: "0px",
                    }}
                >
                    New Return
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "10%",
                        marginBottom: "0px",
                    }}
                >
                    Comment
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "20%",
                        marginBottom: "0px",
                    }}
                >
                    Created On
                </p>
            </div>
            <hr style={{ marginBlock: "0px" }} />

            {earlyReturnData?.map((item: any, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "5%",
                                    marginBottom: "0px",
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}

                                onClick={() => {
                                    setModalData(item);
                                    setEditEarlyReturnIsOpen(true);
                                }}
                            >
                                {item?.id}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "30%",
                                    marginBottom: "0px",

                                }}
                            >
                                {format(new Date(item?.oldReturenDate), "dd-MM-yyyy hh:mm aaaaa'm'")}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "30%",
                                    marginBottom: "0px",
                                }}
                            >
                                {format(new Date(item?.newReturenDate), "dd-MM-yyyy hh:mm aaaaa'm'")}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "10%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.comment}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "20%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.createdBy}
                            </p>
                        </div>
                        <hr style={{ marginBlock: "0px" }} />
                    </React.Fragment>
                );
            })}

            <EditEarlyReturnModal
                modalData={modalData}
                editEarlyReturnIsOpen={editEarlyReturnIsOpen}
                setEditEarlyReturnIsOpen={setEditEarlyReturnIsOpen}
                setEarlyReturnRefresh={setEarlyReturnRefresh}
            />
        </>
    )
}

export default EarlyReturnList;