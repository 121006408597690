import axios from "axios"
import baseURL from "../configs/api"

export const post_ReservationExtension = (data: any, userId: number) => {
    return axios({
        url: `${baseURL}extension/?${userId}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_ReservationExtension = (data: any, userId: number) => {
    return axios({
        url: `${baseURL}extension?${userId}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}


export const delete_ReservationExtension = (primaryKey: number, userId: number) => {
    return axios({
        url: `${baseURL}extension`,
        method: "DELETE",
        params: {id: primaryKey, userId: userId}
    }).then(response => response.data);
}

export const getReservationExtensionByReservationId= (primaryKey: string) => {
    return getFetch(`extension/GetExtensionsByReservationId/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}