import axios from "axios";
import { ticketBaseURL } from "../configs/api";

export const getTicketStatusesByAttribute = (attribute: string) => {
    return getFetch(`ticketStatus/GetTicketStatusesByAttribute/${attribute}`)
}

const getFetch = async (url: string, params = {}) => {
    const response = await axios({
        url: ticketBaseURL + url,
        method: "GET",
        params: params
    });
    return response;
}
