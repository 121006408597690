import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LayersIcon from "@mui/icons-material/Layers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Toolbar,
  Box,
  Typography,
  Button,
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import baseURL from "../../../../configs/api";
import axois from "../../../../configs/axois";
import colorConfigs from "../../../../configs/colorConfigs";
import PaymentScheduleAfterPickup from "./PaymentScheduleAfterPickup";
import PaymentScheduleBeforePickup from "./PaymentScheduleBeforePickup";
import moment from 'moment-timezone';
import Select from 'react-select';

const pages = [
  "Edit",
  "Payment Schedule After Pickup",
  "Payment Schedule Before Pickup",
];

export default function EditBranch() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const [isFinancialSpecifications, setIsFinancialSpecifications] =
    useState<boolean>(true);
  const [isAdvancedBranchSettings, setIsAdvancedBranchSettings] =
    useState<boolean>(false);
  const [isTextSettings, setIsTextSettings] = useState<boolean>(false);
  const [isOnlineBookings, setIsOnlineBookings] = useState<boolean>(false);

  const [id, setId] = useState();
  const [branchName, setBranchName] = useState<string>("");
  const [websiteLink, setWebsiteLink] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [prefixForReservation, setPrefixForReservation] = useState<string>("");
  const [supportedCurrencies, setSupportedCurrencies] = useState<string>("");
  const [locationFeeLabel, setLocationFeeLabel] = useState<string>("");
  const [locationFee, setLocationFee] = useState<string>("");
  const [taxLabel, setTaxLabel] = useState<string>("");
  const [tax, setTax] = useState<string>("");
  const [minHoursForDay, setMinHoursForDay] = useState<string>("");
  const [minAllowedHoursToBook, setMinAllowedHoursToBook] = useState<string>("");
  const [
    defaultFuelPricePerLiterOrGallon,
    setDefaultFuelPricePerLiterOrGallon,
  ] = useState<string>("");
  const [timeZone, setTimeZone] = useState('');
  const [timezoneOptions, setTimezoneOptions] = useState<any[]>([]);

  const [firstTime, setFirstTime] = React.useState<String>(pages[0]);

  useEffect(() => {
    const getTimezoneOptions = () => {
      const timezones = moment.tz.names();
      return timezones.map((timezone) => {
        const offset = moment.tz(timezone).format('Z');
        const value = `(GMT ${offset}) ${timezone} ${moment.tz(timezone).zoneAbbr()}`;
        const label = `(GMT ${offset}) ${timezone} ${moment.tz(timezone).zoneAbbr()}`;
        return { value, label };
      });
    };

    setTimezoneOptions(getTimezoneOptions());
  }, []);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const idNumber = useLocation();

  useEffect(() => {
    axois
      .get(`${baseURL}branch/${idNumber.state}`)
      .then((response) => {
        setId(response.data.id);
        setBranchName(response.data.branchName);
        setWebsiteLink(response.data.websiteLink);
        setEmailAddress(response.data.emailAddress);
        setPhoneNumber(response.data.phoneNumber);
        setPrefixForReservation(response.data.prefixForReservation);
        setSupportedCurrencies(response.data.supportedCurrencies);
        setLocationFeeLabel(response.data.locationFeeLabel);
        setLocationFee(response.data.locationFee);
        setTaxLabel(response.data.taxLabel);
        setTax(response.data.tax);
        setMinHoursForDay(response.data.minHoursForDay);
        setDefaultFuelPricePerLiterOrGallon(
          response.data.defaultFuelPricePerLiterOrGallon
        );
        setTimeZone(response.data.timeZone);
        setMinAllowedHoursToBook(response.data.minAllowedHoursToBook);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const onChangePhoneNumber = (value: string) => {
    const { length } = value;

    const regex = () => value.replace(/[^\d.]+/g, '');

    const areaCode = () => `(${regex().slice(0, 3)})`;

    const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

    const trailer = (start: number) => `${regex().slice(start, regex().length)}`;
    if (length < 3) {
      setPhoneNumber(regex());
    } else if (length >= 3 && length < 10) {
      setPhoneNumber(`${areaCode()} ${trailer(3)}`);
    } else if (length === 5) {
      setPhoneNumber(`${areaCode().replace(")", "")}`);
    } else if (length >= 10 && length < 15) {
      setPhoneNumber(`${firstSix()}-${trailer(6)}`);
    }
  }

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .put(
        `${baseURL}branch`,
        JSON.stringify({
          id,
          branchName,
          websiteLink,
          emailAddress,
          prefixForReservation,
          supportedCurrencies,
          locationFeeLabel,
          locationFee,
          taxLabel,
          tax,
          defaultFuelPricePerLiterOrGallon,
          isImages: false,
          isActive: false,
          isCalenderView: false,
          defaultLocationId: 0,
          governmentTax: 0,
          phoneNumber,
          minHoursForDay,
          timeZone,
          minAllowedHoursToBook
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteTheBranch = async () => {
    if (window.confirm("Are you sure you want to delete this branch?") === true) {
      await axios
        .delete(`${baseURL}branch?id=${id}`)
        .then((response) => {
          console.log(response);
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <LayersIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Branches - {branchName}
          </Typography>
        </Box>
        <div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => deleteTheBranch()}
          >
            Delete
          </Button>
        </div>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <div
        style={{
          width: "100%",
          display: firstTime === pages[0] ? "flex" : "none",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "75%",
            marginRight: "3%",
          }}
        >
          <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            Branch Overview
          </h5>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="name"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Name
                    <br />
                    <input
                      type="text"
                      name="name"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={branchName}
                      onChange={(e) => setBranchName(e.target.value)}
                      required
                    />
                  </label>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="emailAddress"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Email Address
                      <br />
                      <input
                        type="email"
                        name="emailAddress"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="image"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Phone Number
                      <br />
                      <input
                        type="text"
                        name="phoneNumber"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={phoneNumber}
                        onChange={(e) => onChangePhoneNumber(e.target.value)}
                        required
                      />
                    </label>
                  </div> 

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="minAllowedHoursToBook"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Minimum Allowed Hours To Book
                      <br />
                      <input
                        type="number"
                        name="minAllowedHoursToBook"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minAllowedHoursToBook}
                        onChange={(e) => setMinAllowedHoursToBook(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="websiteLink"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Website Link
                    <br />
                    <input
                      type="text"
                      name="websiteLink"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={websiteLink}
                      onChange={(e) => setWebsiteLink(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="prefixForReservation"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Prefix for Reservation #
                    <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                    <br />
                    <input
                      type="text"
                      name="prefixForReservation"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={prefixForReservation}
                      onChange={(e) => setPrefixForReservation(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="image"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Minimum Hours For Day
                      <br />
                      <input
                        type="number"
                        name="minHoursForDay"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minHoursForDay}
                        onChange={(e) => setMinHoursForDay(e.target.value)}
                        required
                      />
                    </label>
                  </div>


              </div>
            </div>

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
              onClick={() =>
                setIsFinancialSpecifications(!isFinancialSpecifications)
              }
            >
              {isFinancialSpecifications ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Financial Specifications
            </h5>
            <hr />

            {isFinancialSpecifications && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="supportedCurrencies"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Supported Currencies
                        <br />
                        <input
                          type="text"
                          name="supportedCurrencies"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={supportedCurrencies}
                          onChange={(e) =>
                            setSupportedCurrencies(e.target.value)
                          }
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="locationFee"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Location Fee (%)
                        <br />
                        <input
                          type="number"
                          name="locationFee"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={locationFee}
                          onChange={(e) => setLocationFee(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="tax"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Tax (%)
                        <br />
                        <input
                          type="number"
                          name="tax"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={tax}
                          onChange={(e) => setTax(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="defaultFuelPricePerLiterOrGallon"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Default Fuel Price Per Liter or Gallon
                        <br />
                        <input
                          type="text"
                          name="emadefaultFuelPricePerLiterOrGallonilAddress"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={defaultFuelPricePerLiterOrGallon}
                          onChange={(e) =>
                            setDefaultFuelPricePerLiterOrGallon(e.target.value)
                          }
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div style={{ marginTop: "10px", visibility: "hidden" }}>
                      <label
                        htmlFor="locationFeeLabel"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Location Fee Label
                        <br />
                        <input
                          type="text"
                          name="locationFeeLabel"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={locationFeeLabel}
                          onChange={(e) => setLocationFeeLabel(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="locationFeeLabel"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Location Fee Label
                        <br />
                        <input
                          type="text"
                          name="locationFeeLabel"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={locationFeeLabel}
                          onChange={(e) => setLocationFeeLabel(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="taxLabel"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Tax Label
                        <br />
                        <input
                          type="text"
                          name="taxLabel"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={taxLabel}
                          onChange={(e) => setTaxLabel(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                  </div>
                </div>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() =>
                setIsAdvancedBranchSettings(!isAdvancedBranchSettings)
              }
            >
              {isAdvancedBranchSettings ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Advanced Branch Settings
            </h5>
            <hr />

            {isAdvancedBranchSettings && (
              <>
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="timezone"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      TimeZone
                      <br />
                      <Select
                        placeholder='Select Time Zone'
                        value={timezoneOptions.find(option => option.value === timeZone)}
                        options={timezoneOptions}
                        onChange={(selectedOption) => setTimeZone(selectedOption ? selectedOption.value : '')}
                        isSearchable
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: "100%",
                            fontSize: "14px",
                          }),
                          menu: (styles) => ({
                            ...styles,
                            marginBottom: "40px",
                            height: "auto",
                          }),
                        }}
                      />
                    </label>
                  </div>
                </div>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
              onClick={() => setIsTextSettings(!isTextSettings)}
            >
              {isTextSettings ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Text Settings
            </h5>
            <hr />

            {isTextSettings && (
              <>
                <label>Text Settings</label>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
              onClick={() => setIsOnlineBookings(!isOnlineBookings)}
            >
              {isOnlineBookings ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Online Bookings
            </h5>
            <hr />

            {isOnlineBookings && (
              <>
                <label>Online Bookings</label>
              </>
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>

        <div
          style={{
            width: "25%",
          }}
        >
          <h5 style={{ marginBottom: "0px", paddingBottom: "0px" }}>Links</h5>
          <hr />

          <h6
            style={{ fontSize: "13px", marginTop: "22px", marginBottom: "0px" }}
          >
            Public Link for Reservations
          </h6>
          <p style={{ color: "blue", fontSize: "12px", marginTop: "0px" }}>
            Branch Link
          </p>

          <h6
            style={{ fontSize: "13px", marginTop: "16px", marginBottom: "0px" }}
          >
            Public Link for Packages
          </h6>
          <p style={{ color: "blue", fontSize: "12px", marginTop: "0px" }}>
            Branch Link Packages
          </p>

          <h6
            style={{ fontSize: "13px", marginTop: "16px", marginBottom: "0px" }}
          >
            Public Link for Reservations with Packages
          </h6>
          <p style={{ color: "blue", fontSize: "12px", marginTop: "0px" }}>
            Branch Link Packages
          </p>

          <h6
            style={{ fontSize: "13px", marginTop: "16px", marginBottom: "0px" }}
          >
            My Reservations Link
          </h6>
          <p style={{ color: "blue", fontSize: "12px", marginTop: "0px" }}>
            My Reservations Link
          </p>

          <h6
            style={{ fontSize: "13px", marginTop: "16px", marginBottom: "0px" }}
          >
            My Package Reservations Link
          </h6>
          <p style={{ color: "blue", fontSize: "12px", marginTop: "0px" }}>
            My Package Reservations Link
          </p>

          <h6
            style={{ fontSize: "13px", marginTop: "16px", marginBottom: "0px" }}
          >
            Public Link for Calendar
          </h6>
          <p style={{ color: "blue", fontSize: "12px", marginTop: "0px" }}>
            {" "}
            Public Link for Calendar
          </p>

          <h5 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
            Website Integration
          </h5>
          <hr />

          <h6
            style={{ fontSize: "10px", marginTop: "22px", marginBottom: "0px" }}
          >
            Reservation Form
          </h6>
          <button
            style={{
              paddingBlock: "8px",
              backgroundColor: "#81D4FA",
              color: "white",
              fontSize: "10px",
              border: "none",
            }}
          >
            Copy Snippet
          </button>

          <h6
            style={{ fontSize: "10px", marginTop: "16px", marginBottom: "0px" }}
          >
            Reservations
          </h6>
          <button
            style={{
              paddingBlock: "8px",
              backgroundColor: "#81D4FA",
              color: "white",
              fontSize: "10px",
              border: "none",
            }}
          >
            Copy Snippet
          </button>

          <h6
            style={{ fontSize: "10px", marginTop: "16px", marginBottom: "0px" }}
          >
            My Reservations
          </h6>
          <button
            style={{
              paddingBlock: "8px",
              backgroundColor: "#81D4FA",
              color: "white",
              fontSize: "10px",
              border: "none",
            }}
          >
            Copy Snippet
          </button>

          <h6
            style={{ fontSize: "10px", marginTop: "16px", marginBottom: "0px" }}
          >
            Quotes
          </h6>
          <button
            style={{
              paddingBlock: "8px",
              backgroundColor: "#81D4FA",
              color: "white",
              fontSize: "10px",
              border: "none",
            }}
          >
            Copy Snippet
          </button>

          <h6
            style={{ fontSize: "10px", marginTop: "16px", marginBottom: "0px" }}
          >
            Package Quotes
          </h6>
          <button
            style={{
              paddingBlock: "8px",
              backgroundColor: "#81D4FA",
              color: "white",
              fontSize: "10px",
              border: "none",
            }}
          >
            Copy Snippet
          </button>

          <h6
            style={{ fontSize: "10px", marginTop: "16px", marginBottom: "0px" }}
          >
            Payment Requests
          </h6>
          <button
            style={{
              paddingBlock: "8px",
              backgroundColor: "#81D4FA",
              color: "white",
              fontSize: "10px",
              border: "none",
            }}
          >
            Copy Snippet
          </button>
        </div>

      </div>

      <div
        style={{
          width: "100%",
          display: firstTime === pages[1] ? "flex" : "none",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <PaymentScheduleAfterPickup branchId={idNumber.state} />
      </div>

      <div
        style={{
          width: "100%",
          display: firstTime === pages[2] ? "flex" : "none",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <PaymentScheduleBeforePickup branchId={idNumber.state} />
      </div>
    </>
  );
}
