import {
  Button,
  CSSObject,
  Divider,
  IconButton,
  List,
  Stack,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SlidebarItemWithOutCollapse from "./SlidebarItemWithOutCollapse";
import SidebarItemCollapse from "./SidebarItemCollapse";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAccessControl from "../hooks/useAccessControl";
import appAdminRoutes from "../../routes/appAdminRoutes";
import Cookies from "js-cookie";

type Props = {
  handleScreen: () => void;
};


const collapseSidebarItem = [
  {
    index: true,
    path: "/dashboard",
    state: "dashboard",
    section: "Dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <i className="bx bxs-home"></i>,
    },
  },
  {
    path: "/carrental",
    state: "carrental",
    sidebarProps: {
      displayText: "Car Rental",
      icon: (
        <>
          <span>
            <i className="bx bx-calendar-check"></i>
          </span>
          <i className="bx bxs-chevron-down arrow"></i>
        </>
      )
    },
    child: [
      {
        path: "/carrental/reservations",
        state: "carrental.reservations",
        section: "dashboardreservations",
        sidebarProps: {
          displayText: "Reservations",
          icon: <></>
        },
      },
      {
        path: "/carrental/reservationattempts",
        state: "carrental.reservationsattempts",
        section: "dashboardreservationattempts",
        sidebarProps: {
          displayText: "Reservation Attempts",
          icon: <></>
        },
      },
      {
        path: "/carrental/quotes",
        state: "carrental.quotes",
        section: "dashboardquotes",
        sidebarProps: {
          displayText: "Quotes",
          icon: <></>
        },
      },
      {
        path: "/carrental/payments",
        state: "carrental.payments",
        section: "dashboardpayments",
        sidebarProps: {
          displayText: "Payments",
          icon: <></>
        },
      },
      {
        path: "/carrental/calendar",
        state: "carrental.calendar",
        section: "dashboardcalendar",
        sidebarProps: {
          displayText: "Calendar",
          icon: <></>
        },
      },
    ],
  },
  {
    path: "/fleet",
    state: "fleet",
    sidebarProps: {
      displayText: "Fleet",
      icon: (
        <>
          <span>
            <i className="bx bxs-car"></i>
          </span>
          <i className="bx bxs-chevron-down arrow"></i>
        </>
      )
    },
    child: [
      {
        path: "/fleet/vehicle",
        state: "fleet.vehicles",
        section: "dashboardvehicles",
        sidebarProps: {
          displayText: "Vehicles",
          icon: <></>
        },
      },
      {
        path: "/fleet/maintenance",
        state: "fleet.maintenance",
        section: "dashboardmaintenance",
        sidebarProps: {
          displayText: "Maintenance",
          icon: <></>
        },
      },
      {
        path: "/fleet/repairorders",
        state: "fleet.repairorders",
        section: "dashboardrepairorders",
        sidebarProps: {
          displayText: "Repair Orders",
          icon: <></>
        },
      },
    ],
  },
  {
    path: "/contacts",
    state: "contacts",
    sidebarProps: {
      displayText: "Contacts",
      icon: (
        <>
          <span>
            <i className='bx bxs-group' ></i>
          </span>
          <i className="bx bxs-chevron-down arrow"></i>
        </>
      )
    },
    child: [
      {
        path: "/contacts/customers",
        state: "contacts.Customers",
        section: "dashboardcustomers",
        sidebarProps: {
          displayText: "Customers",
          icon: <></>
        },
      },
      {
        path: "/contacts/CustomersEnquiry",
        state: "contacts.CustomersEnquiry",
        section: "dashboardcustomersenquiry",
        sidebarProps: {
          displayText: "Customers Enquiry",
          icon: <></>
        },
      }
    ],
  },
  {
    path: "/checklist/checklist",
    state: "checklist.checklist",
    section: "dashboardchecklist",
    sidebarProps: {
      displayText: "Checklist",
      icon: <i className="bx bxs-check-square"></i>
    },
  },
  {
    path: "/forms",
    state: "forms",
    sidebarProps: {
      displayText: "Forms",
      icon: (
        <>
          <span>
            <i className="bx bx-file"></i>
          </span>
          <i className="bx bxs-chevron-down arrow"></i>
        </>
      )
    },
    child: [
      {
        path: "/forms/fires",
        state: "forms.fires",
        section: "dashboardfires",
        sidebarProps: {
          displayText: "Fires",
          icon: <></>
        },
      },
    ],
  },
  {
    path: "/reports",
    state: "reports",
    section: "dashboardreports",
    sidebarProps: {
      displayText: "Reports",
      icon: <i className="bx bx-file-blank"></i>
    },
  },
];

const openedMixin = (theme: Theme): CSSObject => ({
  width: sizeConfigs.sidebar.width,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0",
  [theme.breakpoints.up("sm")]: {
    width: "0",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "30px",
  padding: theme.spacing(0, 1),
  backgroundColor: colorConfigs.sidebar.bg,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: sizeConfigs.sidebar.width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = ({ handleScreen }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const { permissions }: any = useAccessControl();
  const [showSettingsButton, setShowSettingsButton] = useState<boolean>(true);
  const role = Cookies.get("role-jj-rental-ui") || '';
  const isAdmin = role.toLowerCase() === 'admin';

  const handleNavigation = () => {
    if (isAdmin || (permissions && permissions["adminsettings"])) {
      navigate("/admin");
      return;
    }

    const nextAvailableRoute = appAdminRoutes.find((route: any) => {
      const hasPermission = permissions ? permissions[route.section] : false;

      if (route.child) {
        const childRoute = route.child.find((child: any) => {
          const hasChildPermission = permissions ? permissions[child.section] : false;
          return hasChildPermission;
        });

        if (childRoute) {
          setShowSettingsButton(true);
          return true;
        } else {
          setShowSettingsButton(false);
        }
      }

      return hasPermission;
    });

    if (nextAvailableRoute) {
      const routeToNavigate = nextAvailableRoute.child?.find((child: any) => {
        return permissions && permissions[child.section];
      }) || nextAvailableRoute;

      const pathToNavigate = routeToNavigate.path ?? "/unauthorized";
      navigate(pathToNavigate);
    } else {
      
      navigate("/unauthorized");
    }
  };

  useEffect(() => { 
    const hasChildRoutesWithPermission = appAdminRoutes.find((route: any) => {
      const hasPermission = permissions ? permissions[route.section] : false;

      if (route.child) {
        const childRoute = route.child.find((child: any) => {
          const hasChildPermission = permissions ? permissions[child.section] : false;
          return hasChildPermission;
        });

        return childRoute !== undefined;
      }

      return hasPermission;
    });  

    setShowSettingsButton(hasChildRoutesWithPermission ? true: false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, appAdminRoutes]);

  const pages = [
    "dashboarddailymanifest",
    "dashboardbookings",
    "dashboardavailability",
    "dashboardfleet",
    "dashboardextras",
    "dashboardattempts",
  ];

  const hasAnyDashboardChildAccess = () => {
    const hasAccess = pages.some(page => permissions[page] === true);
    return hasAccess;
  };

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0,
          borderRight: "1px solid #eeeeee",
        }}
      >
        <DrawerHeader />
        <List
          disablePadding
          sx={{
            height: "100%",
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          }}
        >
          {appRoutes.map((route, index) => {
            if (isAdmin) {
              return route.sidebarProps && route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SlidebarItemWithOutCollapse item={route} key={index} />
              );
            }

            const hasAccessSection = route?.section ? permissions[route.section] : route.child ? route.child?.filter((child: any) => permissions[child.section] === true).length > 0 ? true : false : false;

            const isDashboard = route.path === "/dashboard";

            const dashboardHasAccess = isAdmin || (isDashboard ? hasAnyDashboardChildAccess() : hasAccessSection);

            if (dashboardHasAccess) {
              return route.sidebarProps && route.child
                ? <SidebarItemCollapse item={route} key={index} />
                : <SlidebarItemWithOutCollapse item={route} key={index} />;
            }

            return null;
          })}

        </List>

        <>
          <Divider />
          <Stack
            direction="row"
            sx={{
              width: sizeConfigs.sidebar.width,
              background: "#cbdde9",
              display: "flex",
              justifyContent: isAdmin || showSettingsButton ? "center" : "flex-end",
              alignItems: "center",
            }}
          >
            {(isAdmin || showSettingsButton) && <Button
              onClick={handleNavigation}
              sx={{
                paddingBlock: "10px",
                width: "100%",
                color: colorConfigs.sidebar.color,
                borderRadius: "0px",
                "&: hover": {
                  backgroundColor: colorConfigs.sidebar.activeBg,
                  color: "#FFFFFF"
                },
              }}
            >
              <MiscellaneousServicesIcon sx={{ fontSize: "16px" }} />
              <Typography
                variant="h6"
                fontSize={14}
                sx={{ paddingLeft: "8px", paddingRight: "60px" }}
              >
                Settings
              </Typography>
            </Button>
            }

            <DeleteForeverOutlinedIcon
              sx={{ fontSize: "16px", marginRight: "20px", marginLeft: "10px" }}
            />

            <IconButton
              onClick={() => {
                setOpen(!open);
                handleScreen();
              }}
            >
              <FullscreenExitIcon
                sx={{ fontSize: "16px", marginRight: "10px" }}
              />
            </IconButton>

          </Stack>
        </>
      </Drawer>

      {!open && (
        <>
          <div className="sidebar close">
            <ul className="nav-links">
              {collapseSidebarItem?.map((item: any, index: number) => {
                const hasCollapseAccessSection = item?.section ? isAdmin || permissions[item.section] : item.child ? item.child?.filter((child: any) => isAdmin || permissions[child.section] === true).length > 0 ? true : false : false;

                const isDashboard = item.path === "/dashboard";
                const dashboardHasAccess = isAdmin || (isDashboard ? hasAnyDashboardChildAccess() : hasCollapseAccessSection);

                if (dashboardHasAccess) {
                  return (
                    item?.child
                      ?
                      <li key={index}>
                        <div className="iocn-link">
                          {item?.sidebarProps?.icon}
                        </div>

                        <ul className="sub-menu">
                          <li>
                            <span className="link_name">
                              {item?.sidebarProps?.displayText}
                            </span>
                          </li>

                          {item?.child?.map((childItem: any, i: number) => {
                            const hasAccessChild = childItem?.section ? (isAdmin || permissions[childItem.section]) : false;

                            if (hasAccessChild) {
                              return (
                                <li key={i}>
                                  <Link to={childItem?.path}>
                                    {childItem?.sidebarProps?.displayText}
                                  </Link>
                                </li>
                              )
                            }

                            return null;
                          })}
                        </ul>
                      </li>
                      :
                      <li key={index}>
                        <span>
                          <Link to={item?.path}>
                            {item?.sidebarProps?.icon}
                          </Link>
                        </span>
                        <ul className="sub-menu blank">
                          <li>
                            <Link to={item?.path} className="link_name">
                              {item?.sidebarProps?.displayText}
                            </Link>
                          </li>
                        </ul>
                      </li>
                  )
                }

                return null;
              })}

              {(isAdmin || showSettingsButton) && (
                <li className="not-list-icon">
                  <span>
                    <IconButton onClick={handleNavigation}>
                      <MiscellaneousServicesIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </span>
                </li>
              )}

              <li className="not-list-icon">
                <span>
                  <IconButton onClick={() => console.log("delete")}>
                    <DeleteForeverOutlinedIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </span>
              </li>

              <li className="not-list-icon">
                <span>
                  <IconButton
                    onClick={() => {
                      setOpen(!open);
                      handleScreen();
                    }}
                  >
                    <FullscreenExitIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </span>
              </li>

            </ul>
          </div>
        </>
      )}

    </>
  );
};

export default Sidebar;
