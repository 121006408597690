import saveAs from 'file-saver';
import useAttachments from './hooks/useAttachments';
import { ticketBaseURL } from '../configs/api';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect } from 'react';

const AttachmentView = () => {
    const { viewImages, setViewImages, attachments, currentIndex, setCurrentIndex }: any = useAttachments();

    useEffect(() => {
        if (viewImages) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [viewImages]);

    const downloadImage = (index: number) => {
        saveAs(
            `${ticketBaseURL}commonFile/download?name=${attachments[index]?.fileName}`,
            attachments[index]?.fileName
        );
    };

    const prevAttachment = () => {
        if (currentIndex !== 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const nextAttachment = () => {
        if (currentIndex < attachments.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    return (
        <div
            style={{
                display: viewImages ? "flex" : "none",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: "10000",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
            }}
        >
            <div
                style={{
                    width: "100%",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    position: "relative",
                    zIndex: "10001",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "10px 24px",
                        width: "100%",
                        height: "4rem",
                    }}
                >
                    <div style={{ width: "33.3333%", display: "flex", justifyContent: "flex-start" }}>
                        <span
                            style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "white",
                            }}>
                            {attachments[currentIndex]?.fileName ? attachments[currentIndex]?.fileName : "-"}
                        </span>
                    </div>

                    <div style={{ width: "33.3333%", display: "flex", justifyContent: "center" }}>
                        <button
                            type="button"
                            onClick={() => downloadImage(currentIndex)}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "8px",
                                color: "white",
                                fontSize: "14px",
                                fontWeight: "600",
                                outline: "none",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                                opacity: 1,
                            }}
                        >
                            <SaveAltIcon />
                        </button>
                    </div>

                    <div style={{ width: "33.3333%", display: "flex", justifyContent: "flex-end" }}>
                        <button
                            type="button"
                            onClick={() => setViewImages(false)}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px",
                                borderRadius: "50%",
                                color: "white",
                                fontSize: "14px",
                                fontWeight: "600",
                                border: "1px solid white",
                                outline: "none",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                opacity: 1,
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px 24px",
                        width: "100%",
                        flex: 1,
                    }}
                >
                    <div style={{ width: "8.3333%", display: "flex", justifyContent: "flex-start" }}>
                        <button
                            type="button"
                            onClick={prevAttachment}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px",
                                borderRadius: "50%",
                                color: "white",
                                fontSize: "14px",
                                fontWeight: "600",
                                border: "1px solid white",
                                outline: "none",
                                backgroundColor: "transparent",
                                cursor: currentIndex === 0 ? "default" : "pointer",
                                opacity: currentIndex === 0 ? 0.3 : 1,
                            }}
                        >
                            <KeyboardArrowLeftIcon />
                        </button>
                    </div>

                    <div style={{ width: "83.3333%", display: "flex", justifyContent: "center" }}>
                        <img
                            src={
                                attachments[currentIndex]?.fileName
                                    ? `${ticketBaseURL}commonFile/Get?name=${attachments[currentIndex]?.fileName}`
                                    : `./images/organization.png`
                            }
                            alt={`leads-attachment-${currentIndex}`}
                            style={{
                                borderRadius: "8px",
                                width: "80%",
                                height: "500px",
                                objectFit: "cover",
                                boxShadow: "rgba(0, 0, 0, 0.8)",
                            }}
                        />
                    </div>

                    <div style={{ width: "8.3333%", display: "flex", justifyContent: "flex-end" }}>
                        <button
                            type="button"
                            onClick={nextAttachment}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px",
                                borderRadius: "50%",
                                color: "white",
                                fontSize: "14px",
                                fontWeight: "600",
                                border: "1px solid white",
                                outline: "none",
                                backgroundColor: "transparent",
                                cursor: currentIndex < attachments.length - 1 ? "pointer" : "default",
                                opacity: currentIndex < attachments.length - 1 ? 1 : 0.3,
                            }}
                        >
                            <KeyboardArrowRightIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttachmentView;