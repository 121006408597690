import React, { useEffect, useState } from "react";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { format } from "date-fns";
import axios from "axios";
import baseURL from "../../configs/api";
import { Link } from "react-router-dom";

type Props = {
  date: Date;
};

const AllTodaysPickups = ({ date }: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (date) {
      const updatedDate = new Date(date);
      let formatDateString = `${updatedDate.getFullYear()}-${updatedDate.getMonth() + 1}-${updatedDate.getDate()}T23:59:59Z`;

      axios
        .get(`${baseURL}dailyManifest/TodaysPickups/${formatDateString}`)
        .then((response) => {
          setData(response.data);
          setFilterData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [date]);

  useEffect(() => {
    setFilterData(data.filter((_: any) => _?.bookingNumber?.toLowerCase()?.includes(searchValue?.toLowerCase())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Quote":
        return "#bdbdbd";
      case "Open":
        return "#f0ad4e";
      case "Rental":
        return "#66BB6A";
      case "Completed":
        return "#253455";
      case "Cancel":
        return "#ff0000";
      case "Pending":
        return "#808080";
      case "No Show":
        return "#e0e0e0";
      default:
        return "#9E9E9E";
    }
  };

  return (
    <>
      {data.length === 0 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "12px 8px",
            background: "#f2f7fa",
            fontWeight: "bold",
            fontSize: "14px",
            margin: "10px 10px 40px 10px"
          }}
        >
          No Vehicle are available for pickup today
        </div>
      ) : (
        <div style={{ width: "100%", margin: "10px 10px 40px 10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              background: "#f2f7fa",
            }}
          >
            <div>
              <h2 style={{ fontSize: "14px", marginBlock: "0px" }}>
                All Today's Pickups ({data.length})
              </h2>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>

              <input
                type="text"
                placeholder="Search..."
                style={{ marginRight: "10px", padding: "5px" }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)} />
              <PictureAsPdfOutlinedIcon />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Pickup Time</th>
                <th>Res Nr.</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Rental Days</th>
                <th>Amount Due</th>
                <th>Vehicle</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody style={{ background: "#f5f5f5" }}>
              {filterData.map((item, index) => (
                <tr key={index}>
                  <td>{format(new Date(item.pickupDate), "KK:mm a")}</td>
                  <td>
                    <Link
                      to="/carrental/reservations/editreservation"
                      state={item.reservationId}
                    >
                      {item.bookingNumber}
                    </Link>
                  </td>
                  <td>{item.contact}</td>
                  <td>{item.email}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.rentalDays}</td>
                  <td>${item.outstandingBalance}</td>
                  <td style={{ width: "280px" }}>{item.vehicleName}</td>
                  <td>
                    <p
                      style={{
                        width: item?.status === "No Show" ? "50px" : item?.status === "Completed" ? "60px" : "45px",
                        fontSize: "10px",
                        backgroundColor: getStatusColor(item.status),
                        color: "#fff",
                        padding: "2px 4px",
                        borderRadius: "2px",
                        textAlign: "center",
                        margin: "0px",
                      }}
                    >
                      {item.status}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default AllTodaysPickups;
