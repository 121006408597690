import axios from "axios";
import { ticketBaseURL } from "../configs/api";

export const post_fileUpload = (data: any) => {
    return axios({
        url: `${ticketBaseURL}fileUploadData`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_fileUpload = (primaryKey: string) => {
    return axios({
        url: `${ticketBaseURL}fileUploadData`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getFileUploads = (attributeName: string, attributeId: string) => {
    return axios({
    url: `${ticketBaseURL}fileUploadData/${attributeName}/${attributeId}`,
        method: "GET"
    }).then(response => response.data);
}