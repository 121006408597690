const PrivacyPolicy = () => {
  return (
    <div
      style={{
        margin: "auto",
        padding: "1.5rem",
        backgroundColor: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "0.5rem",
      }}
    >
      <h1
        style={{
          fontSize: "2.25rem",
          fontWeight: "800",
          color: "#1F2937",
          marginBottom: "1.5rem",
          textAlign: "center",
        }}
      >
        Privacy Policy
      </h1>

      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
          color: "#2D3748",
          marginTop: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        1. Introduction
      </h2>
      <p
        style={{
          color: "#4A5568",
          lineHeight: "1.75",
          marginBottom: "1.5rem",
        }}
      >
        At <strong>RD Rental</strong>, we are committed to protecting your privacy. This privacy policy outlines how we collect, use, and safeguard your personal information when using our vehicle rental software. By using RD Rental, you consent to the collection and use of your information in accordance with this policy.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
          color: "#2D3748",
          marginTop: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        2. Information We Collect
      </h2>
      <p
        style={{
          color: "#4A5568",
          marginBottom: "1rem",
        }}
      >
        We collect the following types of information for the purpose of providing and maintaining our rental services:
      </p>
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "1.5rem",
          color: "#4A5568",
          marginBottom: "1.5rem",
        }}
      >
        <li>
          <strong>Personal Information:</strong> Your name, email address, phone number, driver’s license details, and any other contact information you provide when signing up or renting a vehicle.
        </li>
        <li>
          <strong>Rental Information:</strong> Details of the vehicle(s) you rent, rental dates, pickup/drop-off locations, and payment information.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use the software, including device type, IP address, and access times.
        </li>
        <li>
          <strong>Camera Access:</strong> We may request access to your device's camera to capture images of the rental vehicle at the time of pickup and return. These images are used to document the vehicle's condition and ensure accuracy during the rental process. We will explicitly ask for your permission before accessing the camera, and you can deny this request at any time.
        </li>
      </ul>

      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
          color: "#2D3748",
          marginTop: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        3. How We Use Your Information
      </h2>
      <p
        style={{
          color: "#4A5568",
          marginBottom: "1rem",
        }}
      >
        We use the information we collect for the following purposes:
      </p>
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "1.5rem",
          color: "#4A5568",
          marginBottom: "1.5rem",
        }}
      >
        <li>To provide and maintain RD Rental services, including vehicle availability, rental processing, and payment handling.</li>
        <li>To communicate with you about your rental booking, vehicle availability, payment receipts, and any important updates or changes to your reservation.</li>
        <li>To improve the functionality of our software and customer experience based on feedback and usage patterns.</li>
        <li>To ensure the security of the rental platform, including protecting against unauthorized access and fraud.</li>
        <li>
          To capture and store images of rental vehicles during pickup and return using your camera (if permission is granted). This helps us document the condition of the vehicle and ensure accuracy in the rental process.
        </li>
      </ul>

      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
          color: "#2D3748",
          marginTop: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        4. Sharing Your Information
      </h2>
      <p
        style={{
          color: "#4A5568",
          marginBottom: "1rem",
        }}
      >
        We value your privacy and will not sell or rent your personal information to third parties. However, we may share your information in the following cases:
      </p>
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "1.5rem",
          color: "#4A5568",
          marginBottom: "1.5rem",
        }}
      >
        <li>
          <strong>With Service Providers:</strong> We may share your information with trusted third-party service providers who assist in the operation of the rental service, such as payment processors, insurance providers, or vehicle service providers.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to legal requests from authorities, such as law enforcement or government agencies.
        </li>
      </ul>

      <p
        style={{
          color: "#4A5568",
          marginBottom: "1.5rem",
        }}
      >
        RD Rental is committed to safeguarding your personal data and will only share it when necessary to ensure smooth operation of the rental process and comply with legal obligations.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
          color: "#2D3748",
          marginTop: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        5. Data Security
      </h2>
      <p
        style={{
          color: "#4A5568",
          marginBottom: "1.5rem",
        }}
      >
        We implement a variety of security measures to protect your personal information from unauthorized access, use, or disclosure. This includes encryption, access controls, and secure communication protocols. However, no method of electronic transmission or storage can guarantee 100% security.
      </p>

      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
          color: "#2D3748",
          marginTop: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        6. Your Rights
      </h2>
      <p
        style={{
          color: "#4A5568",
          marginBottom: "1rem",
        }}
      >
        As a user of RD Rental, you have the following rights regarding your personal information:
      </p>
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "1.5rem",
          color: "#4A5568",
          marginBottom: "1.5rem",
        }}
      >
        <li>Access the personal information we hold about you.</li>
        <li>Request corrections to any inaccurate or incomplete information.</li>
        <li>Request deletion of your information, subject to our legal obligations or retention requirements.</li>
        <li>Withdraw your consent for camera usage at any time by adjusting your device permissions.</li>
      </ul>

      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
          color: "#2D3748",
          marginTop: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        7. Changes to This Privacy Policy
      </h2>
      <p
        style={{
          color: "#4A5568",
          marginBottom: "1.5rem",
        }}
      >
        We may update this privacy policy from time to time. If we make any changes, we will post the updated policy on this page, and you will be notified via email if necessary. Your continued use of RD Rental after the updates signifies your acceptance of the revised privacy policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;