import { ReactNode, createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getAccessControl } from "../../services/acessControl";
import { getAllowDenyStatus } from "../../services/allowStatus";
import RentalJumper from "../RentalJumper";

interface AccessControl {
    id: number;
    roleId: number;
    groupName: string;
    section: string;
    action: string;
    allowDenyStatusId: number;
}

const AccessControlContext = createContext({});

export const AccessControlProvider = ({ children }: { children: ReactNode }) => {
    const [userRoleId, setUserRoleId] = useState<string>(Cookies.get("roleId-jj-rental-ui") || "");
    const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const token = Cookies.get("token-jj-rental-ui");
    const role = Cookies.get("role-jj-rental-ui") || '';

    useEffect(() => {
        const fetchPermissions = async () => {
            setLoading(true);
            if (userRoleId && token) {
                if (role.toLowerCase() === "admin") {
                    setLoading(false);
                    return;
                }
                try {
                    const res: any = await getAccessControl("accessControl", { roleId: userRoleId }, token.toString());
                    const accessControlData: AccessControl[] = res;
                    const response: any = await getAllowDenyStatus();

                    const newPermissions: { [key: string]: boolean } = {};
                    accessControlData.forEach(item => {
                        const normalizedSection = item.section.replace(/\s+/g, '').replace(/-/g, '').toLowerCase();
                        newPermissions[normalizedSection] = item.allowDenyStatusId === response.filter((statusItem: any) => statusItem.allowDenyName === 'Allow')[0].id;
                    });

                    setPermissions(newPermissions);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchPermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRoleId]);

    return (
        <AccessControlContext.Provider value={{ permissions, setPermissions, userRoleId, setUserRoleId }}>
            {loading ? <RentalJumper /> : children}
        </AccessControlContext.Provider>
    );
}

export default AccessControlContext;