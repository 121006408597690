import axios from "axios"
import baseURL from "../configs/api"

export const put_Reservation = (data: any) => {
    return axios({
        url: `${baseURL}reservation`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const getReservations = () => {
    return getFetch("reservation");
}

export const getReservation = (primaryKey: string) => {
    return getFetch(`reservation/${primaryKey}`);
}


const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}