import { Box, Menu, MenuItem, Stack, Toolbar, Typography, styled } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import SearchIcon from "@mui/icons-material/Search";
import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import useAuth from "../hooks/useAuth";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import useQuickLinks from "../hooks/useQuickLinks";
import baseURL from "../../configs/api";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const topBarValues = [
  {
    value: "alternatePlane1",
    label: "Alternate Plane",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-plane-167-474991.png",
  },
  {
    value: "alternatePlane2",
    label: "Alternate Plane",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-plane-1440142-1214643.png",
  },
  {
    value: "mailBulk",
    label: "Mail Bulk",
    icon: "https://cdn.iconscout.com/icon/premium/png-512-thumb/bulk-mail-8106087-6606997.png",
  },
  {
    value: "alternateCar",
    label: "Alternate Car",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-car-1780538-1517628.png",
  },
  {
    value: "location",
    label: "Location",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-gps-fixed-1779970-1518174.png",
  },
  {
    value: "addressCard",
    label: "Address Card",
    icon: "https://cdn.iconscout.com/icon/premium/png-512-thumb/address-card-2240304-1867652.png",
  },
];

const Topbar = () => {
  const { auth, setAuth }: any = useAuth();
  const { quickLinks, setQuickLinks }: any = useQuickLinks();

  const logout = () => {
    setAuth(null);
    Cookies.remove("token-jj-rental-ui");
    Cookies.remove("roleId-jj-rental-ui");
    Cookies.remove("role-jj-rental-ui");
    Cookies.remove("groupId-jj-rental-ui");
    Cookies.remove("userId-jj-rental-ui");
    Cookies.remove("isAdmin-jj-rental-ui");
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetch(`${baseURL}QuickLinks`)
      .then((res) => res.json())
      .then((data) => {
        setQuickLinks(data);
      })
      .catch((error) => console.error("Error fetching quickLinks:", error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLinkClick = (link: string, isNewTab: boolean) => {
    if (isNewTab) {
      window.open(link, '_blank');
    } else {
      window.location.href = link;
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        marginBlock: "0"
      }}
    >
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="images/logo/rd-rental-logo.jpeg" alt="Logo" style={{ marginRight: '8px', height: '32px' }} />
          <Typography variant="h6" sx={{ color: "#ffffff", fontSize: "18px" }}>
            RD Rental
          </Typography>
        </Box>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchIcon sx={{ color: "#ffffff", fontSize: "18px", marginRight: "24px", marginTop: "5px" }} />
            <a href="/#/support/support-tickets">
              <HelpIcon sx={{ color: "#ffffff", fontSize: "18px", marginRight: "16px", marginTop: "10px" }} />
            </a>

          {quickLinks && quickLinks.length > 0 ? (
            quickLinks
              .filter((item: any) => item.isOnTopBar && item.isActive)
              .map((item: any) => {
                const iconData = topBarValues.find((icon) => icon.value === item.topBarIcon);

                return iconData ? (
                  <button
                    key={item.id}
                    onClick={() => handleLinkClick(item.link, item.isOpenInNewTab)}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      padding: '5px',
                      margin: '8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={iconData.icon}
                        alt={iconData.label}
                        style={{ width: '18px', height: '18px', marginRight: '8px', filter: 'invert(1) grayscale(100%)' }}
                      />
                    </div>
                  </button>
                ) : null;
              })
          ) : (
            <span>Loading...</span>
          )}

          <div >
            <button
              style={{ backgroundColor: colorConfigs.topbar.bg, border: "none", cursor: "pointer" }}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <Stack direction="row" spacing={1} alignItems={"center"} justifyContent={"center"}>
                <PersonIcon sx={{ color: "#ffffff", fontSize: "18px" }} />
                <Typography sx={{ color: "#ffffff", fontSize: "14px", }}>{auth?.givenname}</Typography>
                {open ? <ArrowDropUpIcon sx={{ color: "#ffffff", fontSize: "18px" }} /> : <ArrowDropDownIcon sx={{ color: "#ffffff", fontSize: "18px" }} />}
              </Stack>
            </button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              PaperProps={{ sx: { width: '164px', borderRadius: "0px", marginLeft: "18px", marginTop: "26px" } }}
            >
              <MenuItem sx={{ fontSize: "12px", marginBlock: "0px" }}>
                <PersonIcon sx={{ color: "#000000", fontSize: "16px", marginRight: "2px", paddingTop: "2px" }} />Profile
              </MenuItem>
              <hr style={{ marginBlock: "0px" }} />
              <MenuItem sx={{ fontSize: "12px" }} onClick={() => logout()}>
                <LogoutOutlinedIcon sx={{ color: "#000000", fontSize: "16px", marginRight: "2px", paddingTop: "2px" }} />Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
