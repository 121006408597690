import { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import { format } from "date-fns";
import axois from "../../../configs/axois";
import SignatureCanvas from "react-signature-canvas";
import PleaseWaitPopup from "../../PleaseWaitPopup";
import useAuth from "../../../components/hooks/useAuth";
import { post_reservationDiscountDiscount } from "../../../services/reservationDiscount";
import moment from "moment-timezone";
import { getCustomerExtraInformationsByCustomerId } from "../../../services/customerExtraInformation";

type ExtraInformation = {
  id: number;
  idNumber: string;
  issueDate: string;
  exprationDate: string;
  filePath: string;
  idTypeId: string;
  customerId: number;
  type: string;
};

type Props = {
  goToNextPage: (page: number) => void;
  vehicleClassId: string;
  outstandingBalance: number;
  branchId: string;
  pickupDate: string;
  pickupTime: string;
  returnDate: string;
  returnTime: string;
  pickupLocation: string;
  returnLocation: string;
  locations: any[];
  customerId: string;
  localAddress: string;
  walkInCustomer: boolean;
  currentPage: string;
  setPrimaryKey: (id: string) => void;
  primaryKey: string;
  branches: any[];
  reservation: any;
  checkExtraValue: any[];
  selectedVehicleClass: any;
  reservationUpdate: () => void;
  customersUpdate: () => void;
  securityDepositAmountToPaid: string;
  discountList: any[];
  isCompletedOrCancelled: boolean;
};

export default function Confirm({
  goToNextPage,
  outstandingBalance,
  vehicleClassId,
  branchId,
  pickupDate,
  pickupTime,
  returnDate,
  returnTime,
  pickupLocation,
  returnLocation,
  locations,
  customerId,
  localAddress,
  walkInCustomer,
  currentPage,
  setPrimaryKey,
  primaryKey,
  branches,
  reservation,
  checkExtraValue,
  selectedVehicleClass,
  reservationUpdate,
  customersUpdate,
  securityDepositAmountToPaid,
  discountList,
  isCompletedOrCancelled
}: Props) {
  const { auth }: any = useAuth();
  const [extraRow, setExtraRow] = useState<ExtraInformation[]>([]);
  const [type, setType] = useState<any[]>([]);

  const [isCustomerInformation, setIsCutomerInformation] =
    useState<boolean>(true);
  const [isExtraInformation, setIsExtraInformation] = useState<boolean>(true);
  const [isSignatures, setIsSignatures] = useState<boolean>(true);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [Street, setStreet] = useState<string>("");
  const [street2, setStreet2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [birthday, setBirthday] = useState("");
  const [confrimStatus, setConfrimStatus] = useState<string>("Open");
  const [totalDays, setTotalDays] = useState(0);
  const [totalWeeks, setTotalWeeks] = useState(0);
  const [totalMonths, setTotalMonths] = useState(0);

  let signaturePadRef = useRef<SignatureCanvas>(null);

  const [signatureURL, setSignatureURL] = useState<any | null>();
  const [signatureChange, setSignatureChange] = useState<boolean>(true);

  const [pickupLocationName, setPickupLocationName] = useState<string>("");
  const [returnLocationName, setReturnLocationName] = useState<string>("");

  const [pleaseWait, setPleaseWait] = useState<boolean>(false);
  const [formattedCreatedAt, setFormattedCreatedAt] = useState<string>("");

  let latestPickup = new Date();
  let latestReturn = new Date();
  if (pickupDate) latestPickup = new Date(pickupTime ? `${pickupDate}T${pickupTime}` : pickupDate);
  if (returnDate) latestReturn = new Date(returnTime ? `${returnDate}T${returnTime}` : returnDate);

  useEffect(() => {
    if (branches) {
      const branch = branches.find((item: any) => item.id === parseInt(branchId));
      if (branch) {
        const branchTimezone = branch.timeZone;

        if (branchTimezone) {
          setFormattedCreatedAt(branchTimezone);
        }
      }
    }
  }, [branchId, branches]);

  const UpdateTimezone =
    formattedCreatedAt !== "" &&
      formattedCreatedAt !== null &&
      formattedCreatedAt !== "string" &&
      formattedCreatedAt !== "null"
      ? new Date().toLocaleString("en-US", {
        timeZone: formattedCreatedAt?.split(" ")[2],
      })
      : new Date().toLocaleString("en-US", {
        timeZone: moment.tz.guess(),
      });

  useEffect(() => {
    if (locations.length > 0) setPickupLocationName(locations?.find((item: any) => item.id === parseInt(pickupLocation))?.locationName);
  }, [locations, pickupLocation])

  useEffect(() => {
    if (locations.length > 0) setReturnLocationName(locations?.find((item: any) => item.id === parseInt(returnLocation))?.locationName);
  }, [locations, returnLocation])

  useEffect(() => {
    if (customerId) {
      axios
        .get(`${baseURL}Customer/${customerId}`)
        .then((response) => {
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setEmailAddress(response.data.emailAddress);
          setPhoneNumber(response.data.phoneNumber);
          setStreet(response.data.street);
          setStreet2(response.data.street2);
          setCity(response.data.city);
          setState(response.data.state);
          setCountry(response.data.country);
          setZip(response.data.zip);
          setBirthday(
            new Intl.DateTimeFormat("en-US").format(
              new Date(response.data.birthday)
            )
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [customerId]);

  useEffect(() => {
    if (currentPage === 'Confirm') {
      getCustomerExtraInformationsByCustomerId(customerId)
      .then((response) => {
        setExtraRow(() =>
          response.map((item: ExtraInformation, index: number) => {
            return {
              id: index + 1,
              idNumber: item.idNumber,
              issueDate: format(new Date(item.issueDate), "yyyy-MM-dd"),
              exprationDate: format(new Date(item.exprationDate), "yyyy-MM-dd"),
              filePath: item.filePath,
              idTypeId: item.idTypeId,
              customerId: item.customerId,
              type: item.id,
            };
          })
        );

        setExtraRow((extraRow) =>
          extraRow.filter(
            (item: ExtraInformation) => parseInt(customerId) === item.customerId
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }, [customerId, currentPage]);

  useEffect(() => {
    axois
      .get(`${baseURL}IdType`)
      .then((response) => {
        setType(() => response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (reservation && signatureChange) {
      axios
        .get(`${baseURL}fileUploadData/Reservation/${reservation?.id}`)
        .then((response) => {
          setSignatureURL(() =>
            response.data.find((item: any) => item.tabName === "Confirm")
          );
          setSignatureChange(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reservation, signatureChange]);

  useEffect(() => {
    if (selectedVehicleClass && !reservation) {
      if (selectedVehicleClass?.days >= 30) {
        setTotalMonths(
          parseInt((selectedVehicleClass?.days % 30) + "")
        );
        setTotalWeeks(
          parseInt(
            parseInt((selectedVehicleClass?.days % 30) + "") / 7 + ""
          )
        );
        setTotalDays(
          parseInt(
            (parseInt((selectedVehicleClass?.days % 30) + "") % 7) + ""
          )
        );
      } else if (selectedVehicleClass?.days >= 7) {
        setTotalWeeks(parseInt(selectedVehicleClass?.days / 7 + ""));
        setTotalDays(parseInt((selectedVehicleClass?.days % 7) + ""));
      } else {
        setTotalDays(parseInt(selectedVehicleClass?.days));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicleClass]);

  useEffect(() => {
    if (reservation) {
      setTotalDays(reservation?.totalDays);
      setTotalWeeks(reservation?.totalWeeks);
      setTotalMonths(reservation?.totalYears);
    }
  }, [reservation]);

  const confirmTheReservation = async () => {
    if (!isCompletedOrCancelled) {
      setPleaseWait(true);

      if (selectedVehicleClass?.availableVehicles <= 0) {
        setPleaseWait(false);
        window.alert("Vehilce Class is Not Available");
        return;
      }

      if (parseInt(primaryKey) !== 0) {
        await axios
          .put(
            `${baseURL}reservation`,
            JSON.stringify({
              id: primaryKey,
              bookingNumber: reservation ? reservation.bookingNumber : "",
              customerId: customerId,
              pickupDate: pickupDate + "T" + pickupTime,
              returenDate: returnDate + "T" + returnTime,
              pickupLoationId: Number(pickupLocation),
              returenLoationId: Number(returnLocation),
              walkInCustomer: walkInCustomer,
              localAddress: localAddress,
              vehicleClassId: vehicleClassId,
              vehicleId: reservation ? reservation?.vehicleId : 0,
              totalPrice: reservation
                ? reservation?.totalPrice
                : outstandingBalance,
              totalRevenue: reservation ? reservation?.totalRevenue : 0,
              totalPaid: reservation ? reservation?.totalPaid : 0,
              totalRefunded: reservation ? reservation?.totalRefunded : 0,
              outstandingBalance: reservation
                ? reservation?.outstandingBalance
                : outstandingBalance,
              securityDepositAmount: securityDepositAmountToPaid,
              securityDepositAmountPaid: reservation ? reservation?.securityDepositAmountPaid : 0,
              status: reservation ? reservation.status : confrimStatus,
              branchId: branchId,
              totalDays: totalDays,
              dailyRate: reservation
                ? reservation.dailyRate
                : selectedVehicleClass?.dailyRate,
              totalHours: reservation
                ? reservation.totalHours
                : selectedVehicleClass?.hours,
              hourlyRate: reservation
                ? reservation.hourlyRate
                : selectedVehicleClass?.hourlyRate,
              totalWeeks: totalWeeks,
              weeklyRate: reservation
                ? reservation.weeklyRate
                : selectedVehicleClass?.weeklyRate,
              totalYears: totalMonths,
              yearlyRate: reservation
                ? reservation.yearlyRate
                : selectedVehicleClass?.monthilyRate,
              createdBy: auth?.givenname,
              createdAt: new Date(UpdateTimezone).toLocaleString(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            bookedReservation(primaryKey);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        await axios
          .post(
            `${baseURL}reservation`,
            JSON.stringify({
              bookingNumber: "",
              customerId: customerId,
              pickupDate: pickupDate + "T" + pickupTime,
              returenDate: returnDate + "T" + returnTime,
              pickupLoationId: Number(pickupLocation),
              returenLoationId: Number(returnLocation),
              walkInCustomer: walkInCustomer,
              localAddress: localAddress,
              vehicleClassId: vehicleClassId,
              vehicleId: reservation ? reservation.vehicleId : 0,
              totalPrice: reservation
                ? reservation?.totalPrice
                : outstandingBalance,
              totalRevenue: reservation ? reservation?.totalRevenue : 0,
              totalPaid: reservation ? reservation?.totalPaid : 0,
              totalRefunded: reservation ? reservation?.totalRefunded : 0,
              outstandingBalance: reservation
                ? reservation?.outstandingBalance
                : outstandingBalance,
              securityDepositAmount: securityDepositAmountToPaid,
              securityDepositAmountPaid: reservation ? reservation?.securityDepositAmountPaid : 0,
              status: reservation ? reservation.status : confrimStatus,
              branchId: branchId,
              totalDays: totalDays,
              dailyRate: reservation
                ? reservation.dailyRate
                : selectedVehicleClass?.dailyRate,
              totalHours: reservation
                ? reservation.totalHours
                : selectedVehicleClass?.hours,
              hourlyRate: reservation
                ? reservation.hourlyRate
                : selectedVehicleClass?.hourlyRate,
              totalWeeks: totalWeeks,
              weeklyRate: reservation
                ? reservation.weeklyRate
                : selectedVehicleClass?.weeklyRate,
              totalYears: totalMonths,
              yearlyRate: reservation
                ? reservation.yearlyRate
                : selectedVehicleClass?.monthilyRate,
              createdBy: auth?.givenname,
              createdAt: new Date(UpdateTimezone).toLocaleString(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then((response) => {
            bookedReservation(response.data.id);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  }

  const bookedReservation = (currentPrimaryKey: string) => {
    if (!isCompletedOrCancelled) {
      if (checkExtraValue.length !== 0) {
        if (!reservation) {
          let count = 0;
          checkExtraValue?.forEach((item: any, _: number, arr: any[]) => {
            axios
              .post(
                `${baseURL}reservationAdditionalCharge`,
                JSON.stringify({
                  reservatinId: currentPrimaryKey,
                  additionalChargeByBranchId: item.id,
                  quantity: 1,
                  basePrice: item.amountOrPercentage,
                }),
                {
                  headers: { "Content-Type": "application/json" },
                  withCredentials: true,
                }
              )
              .then(() => {
                count++;
                if (arr.length === count) {
                  saveDiscountReservationList(currentPrimaryKey);
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
        } else {
          if (reservation?.customerId === customerId) {
            saveSignature(currentPrimaryKey);
          } else {
            savePrimaryDriver(currentPrimaryKey);
          }
        }
      } else {
        if (reservation) {
          if (reservation?.customerId === customerId) {
            saveSignature(currentPrimaryKey);
          } else {
            savePrimaryDriver(currentPrimaryKey);
          }
        } else {
          saveDiscountReservationList(currentPrimaryKey);
        }
      }
    }
  }

  const saveDiscountReservationList = (currentPrimaryKey: string) => {
    if (!isCompletedOrCancelled) {
      if (discountList.length !== 0) {
        if (!reservation) {
          let count = 0;
          discountList?.forEach(async (item: any) => {
            await post_reservationDiscountDiscount(currentPrimaryKey, item.discountDefinition, "Manual", item.discountAmount, true, auth)
              .then(() => {
                count++;
                if (discountList.length === count) {
                  savePrimaryDriver(currentPrimaryKey);
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          })
        } else {
          if (reservation?.customerId === customerId) {
            saveSignature(currentPrimaryKey);
          } else {
            savePrimaryDriver(currentPrimaryKey);
          }
        }
      } else {
        if (reservation) {
          if (reservation?.customerId === customerId) {
            saveSignature(currentPrimaryKey);
          } else {
            savePrimaryDriver(currentPrimaryKey);
          }
        } else {
          savePrimaryDriver(currentPrimaryKey);
        }
      }
    }
  }

  const savePrimaryDriver = (currentPrimaryKey: string) => {
    if (!isCompletedOrCancelled) {
      axios
        .post(
          `${baseURL}reservationAdditionalDriver`,
          JSON.stringify({
            reservationId: currentPrimaryKey,
            customerId: customerId,
            primaryDriver: true,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          customersUpdate();
          saveSignature(currentPrimaryKey);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  const converTheBase64IntoFileAndUploadToServer = (canvas: HTMLCanvasElement, callback: any) => {
    if (!isCompletedOrCancelled) {
      canvas.toBlob((blob) => {
        if (blob) {
          const formData = new FormData();
          formData.append("ImageFile", blob, 'signature.png');
          axios
            .post(`${baseURL}reservationFile/upload`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
              withCredentials: true,
            })
            .then((response) => {
              callback(response.data);
            })
            .catch((error) => {
              console.error("Error:", error);
              callback("");
            });
        } else {
          console.error("Error: Blob is null");
          callback("");
        }
      }, 'image/png');
    }
  }

  const saveSignature = (currentPrimaryKey: string) => {
    if (!isCompletedOrCancelled) {
      const canvas = signaturePadRef.current?.getTrimmedCanvas();
      if (canvas) {
        converTheBase64IntoFileAndUploadToServer(canvas, (imageFilePath: string) => {
          if (imageFilePath) {
            axios
              .post(
                `${baseURL}fileUploadData`,
                JSON.stringify({
                  attributeName: "Reservation",
                  attributeId: currentPrimaryKey,
                  fileName: imageFilePath,
                  tabName: "Confirm",
                  userId: auth?.id ?? 0,
                  createdDate: new Date(),
                }),
                {
                  headers: { "Content-Type": "application/json" },
                  withCredentials: true,
                }
              )
              .then(() => {
                setSignatureChange(true);
                signaturePadRef.current?.clear();

                setPrimaryKey(currentPrimaryKey);
                goToNextPage(5);
                reservationUpdate();
                setPleaseWait(false);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          } else {
            setPrimaryKey(currentPrimaryKey);
            goToNextPage(5);
            reservationUpdate();
            setPleaseWait(false);
          }
        });
      } else {
        setPrimaryKey(currentPrimaryKey);
        goToNextPage(5);
        reservationUpdate();
        setPleaseWait(false);
      }
    }
  }

  const deleteTheSignatureURL = async () => {
    if (!isCompletedOrCancelled) {
      if (
        window.confirm("Are you sure you want to delete this Signature?") === true
      ) {
        if (signatureURL?.id === 0) {
          setSignatureURL(null);
        } else {
          await axios
            .delete(`${baseURL}fileUploadData?id=${signatureURL?.id}`)
            .then(() => {
              setSignatureURL(null);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    }
  };

  const changeStatusToQuote = () => {
    if (!isCompletedOrCancelled) {
      if (
        window.confirm(
          "Are you sure you want to change reservation to Quote?"
        ) === true
      ) {
        axios
          .put(
            `${baseURL}reservation`,
            {
              ...reservation,
              status: "Quote",
            },
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            reservationUpdate();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "24px",
        }}
      >
        <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
          Date & Time
        </h5>
        <hr />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBlock: "0px",
              fontWeight: "bold",
            }}
          >
            Pickup Date
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBlock: "0px",
              fontWeight: "bold",
            }}
          >
            Pickup Time
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBlock: "0px",
              fontWeight: "bold",
            }}
          >
            Return Date
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBlock: "0px",
              fontWeight: "bold",
            }}
          >
            Return Time
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBlock: "0px",
            }}
          >
            {format(new Date(latestPickup), "MM/dd/yyyy")}
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBlock: "0px",
            }}
          >
            {format(new Date(latestPickup), "KK:mm a")}
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBlock: "0px",
            }}
          >
            {format(new Date(latestReturn), "MM/dd/yyyy")}
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBlock: "0px",
            }}
          >
            {format(new Date(latestReturn), "KK:mm a")}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBottom: "0px",
              fontWeight: "bold",
            }}
          >
            Pickup Location
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginBottom: "0px",
              fontWeight: "bold",
            }}
          >
            Return Location
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginTop: "0px",
            }}
          >
            {pickupLocationName}
          </p>
          <p
            style={{
              fontSize: "12px",
              textAlign: "start",
              width: "25%",
              marginTop: "0px",
            }}
          >
            {returnLocationName}
          </p>
        </div>

        <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
          Selected Vehicle Class
        </h5>
        <hr />

        {
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              padding: "0px 16px"
            }}
          >

            <div style={{
              width: "65%",
              display: "flex",
              flexDirection: "row",
              gap: "16px"
            }}>

              <div style={{ width: "50%" }}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={
                    selectedVehicleClass &&
                    (selectedVehicleClass.imagePath.startsWith(
                      "data:image/png;base64,"
                    )
                      ? selectedVehicleClass.imagePath
                      : `${baseURL}vehicleClassFile/Get?name=` +
                      selectedVehicleClass.imagePath)
                  }
                  alt="vehicle"
                />
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h5 style={{ paddingBlock: "0px", marginBlock: "0px" }}>
                  {selectedVehicleClass?.vehicleName}
                </h5>
                <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                  {parseFloat(
                    selectedVehicleClass?.distanceLimitperDay
                  ).toFixed(2)}{" "}
                  miles per day
                </p>
                <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                  Radio/CD Player
                </p>
                <p style={{ marginBlock: "0px", fontSize: "12px" }}>
                  Extra Miles - $
                  {parseInt(
                    selectedVehicleClass?.additionalChargeforExceededDistance
                  ).toFixed(2)}{" "}
                  per mile
                </p>
              </div>

            </div>

            <div
              style={{
                width: "35%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {selectedVehicleClass?.recommended && (
                <h5
                  style={{
                    backgroundColor: "#81D4FA",
                    color: "white",
                    textAlign: "center",
                    marginTop: "0px",
                    marginBottom: "8px",
                    paddingBlock: "3px",
                  }}
                >
                  Recommended
                </h5>
              )}

              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  border: "1px solid black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "end",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                  <strong>
                    $
                    {parseInt(selectedVehicleClass?.dailyRate).toFixed(
                      2
                    )}
                  </strong>
                  /Day
                </p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                  $
                  {parseFloat(selectedVehicleClass?.days) >= 30
                    ? (
                      parseInt(
                        (
                          parseInt(selectedVehicleClass?.days) / 30
                        ).toFixed(0)
                      ) *
                      Number(selectedVehicleClass?.monthilyRate) +
                      parseInt(
                        parseInt(
                          (parseInt(selectedVehicleClass?.days) %
                            30) +
                          ""
                        ) /
                        7 +
                        ""
                      ) *
                      Number(selectedVehicleClass?.weeklyRate) +
                      parseInt(
                        (
                          (parseInt(selectedVehicleClass?.days) %
                            30) %
                          7
                        ).toFixed(0)
                      ) *
                      Number(selectedVehicleClass?.dailyRate) +
                      parseFloat(selectedVehicleClass?.hours) *
                      Number(selectedVehicleClass?.hourlyRate)
                    ).toFixed(2)
                    : parseFloat(selectedVehicleClass?.days) >= 7
                      ? (
                        parseInt(
                          (
                            parseInt(selectedVehicleClass?.days) / 7
                          ).toFixed(0)
                        ) *
                        Number(selectedVehicleClass?.weeklyRate) +
                        parseInt(
                          (
                            parseInt(selectedVehicleClass?.days) % 7
                          ).toFixed(0)
                        ) *
                        Number(selectedVehicleClass?.dailyRate) +
                        parseFloat(selectedVehicleClass?.hours) *
                        Number(selectedVehicleClass?.hourlyRate)
                      ).toFixed(2)
                      : (
                        parseFloat(selectedVehicleClass?.days) *
                        Number(selectedVehicleClass?.dailyRate) +
                        parseFloat(selectedVehicleClass?.hours) *
                        Number(selectedVehicleClass?.hourlyRate)
                      ).toFixed(2)}{" "}
                  total for {selectedVehicleClass?.days} Day{" "}
                  {selectedVehicleClass?.hours !== 0 &&
                    " and " + selectedVehicleClass?.hours + " hours"}
                </p>
                <p style={{ fontSize: "12px", marginBlock: "0px" }}>
                  Excl. taxes & insurance(s){" "}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    marginBlock: "0px",
                    color: "#81D4FA",
                  }}
                >
                  Vehicles Available:{" "}
                  {selectedVehicleClass?.availableVehicles} (
                  {(
                    (parseFloat(
                      selectedVehicleClass?.availableVehicles
                    ) *
                      100) /
                    parseFloat(selectedVehicleClass?.vehicleCount)
                  ).toFixed(2)}
                  %)
                </p>
              </div>
            </div>
          </div>
        }
        <hr style={{ marginTop: "16px" }} />

        <h5
          style={{
            paddingBottom: "0px",
            marginBottom: "0px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            cursor: "pointer",
            WebkitUserSelect: "none" /* Safari */,
            msUserSelect: "none" /* IE 10 and IE 11 */,
            userSelect: "none" /* Standard syntax */,
          }}
          onClick={() => setIsCutomerInformation(!isCustomerInformation)}
        >
          {isCustomerInformation ? (
            <ExpandMoreIcon sx={{ fontSize: "20px" }} />
          ) : (
            <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
          )}{" "}
          Customer Information
        </h5>
        <hr />

        <div>
          {isCustomerInformation && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="firstName"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    First Name
                  </label>
                  <br />
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "25%",
                      marginBlock: "0px",
                    }}
                  >
                    {firstName}
                  </p>

                  <div style={{ marginTop: firstName ? "10px" : "20px" }}>
                    <label
                      htmlFor="emailAddress"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Email Address
                      <br />
                    </label>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBlock: "0px",
                      }}
                    >
                      {emailAddress}
                    </p>
                  </div>

                  <div style={{ marginTop: emailAddress ? "10px" : "20px" }}>
                    <label
                      htmlFor="Street"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Street
                      <br />
                    </label>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBlock: "0px",
                      }}
                    >
                      {Street}
                    </p>
                  </div>

                  <div style={{ marginTop: Street ? "10px" : "20px" }}>
                    <label
                      htmlFor="c
                  reqity"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      City
                      <br />
                    </label>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBlock: "0px",
                      }}
                    >
                      {city}
                    </p>
                  </div>

                  <div style={{ marginTop: city ? "10px" : "20px" }}>
                    <label
                      htmlFor="zip"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Zip
                      <br />
                    </label>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBlock: "0px",
                      }}
                    >
                      {zip}
                    </p>
                  </div>

                  <div style={{ marginTop: zip ? "10px" : "20px" }}>
                    <label
                      htmlFor="totalInLabor"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Birthday
                      <br />
                    </label>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBlock: "0px",
                      }}
                    >
                      {birthday}
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor=" vehicle"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Last Name
                    <br />
                  </label>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "25%",
                      marginBlock: "0px",
                    }}
                  >
                    {lastName}
                  </p>
                </div>

                <div style={{ marginTop: lastName ? "10px" : "20px" }}>
                  <label
                    htmlFor="phoneNumber"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Phone Number
                    <br />
                  </label>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "25%",
                      marginBlock: "0px",
                    }}
                  >
                    {phoneNumber}
                  </p>
                </div>

                <div style={{ marginTop: phoneNumber ? "10px" : "20px" }}>
                  <label
                    htmlFor="street2"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Street 2
                    <br />
                  </label>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "25%",
                      marginBlock: "0px",
                    }}
                  >
                    {street2}
                  </p>
                </div>

                <div style={{ marginTop: street2 ? "10px" : "20px" }}>
                  <label
                    htmlFor="state"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    State
                    <br />
                  </label>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "25%",
                      marginBlock: "0px",
                    }}
                  >
                    {state}
                  </p>
                </div>

                <div style={{ marginTop: state ? "10px" : "20px" }}>
                  <label
                    htmlFor="country"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Country
                    <br />
                  </label>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "25%",
                      marginBlock: "0px",
                    }}
                  >
                    {country}
                  </p>
                </div>
              </div>
            </div>
          )}

          <h5
            style={{
              paddingBottom: "0px",
              marginBottom: "0px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              cursor: "pointer",
              WebkitUserSelect: "none" /* Safari */,
              msUserSelect: "none" /* IE 10 and IE 11 */,
              userSelect: "none" /* Standard syntax */,
            }}
            onClick={() => setIsExtraInformation(!isExtraInformation)}
          >
            {isExtraInformation ? (
              <ExpandMoreIcon sx={{ fontSize: "20px" }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
            )}{" "}
            Extra Information
          </h5>
          <hr />

          {isExtraInformation && (
            <>
              <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                Driver license
              </label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "start",
                    width: "25%",
                    marginBottom: "0px",
                  }}
                >
                  Type
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "start",
                    width: "25%",
                    marginBottom: "0px",
                  }}
                >
                  ID Number
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "start",
                    width: "25%",
                    marginBottom: "0px",
                  }}
                >
                  Issued On
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "start",
                    width: "25%",
                    marginBottom: "0px",
                  }}
                >
                  Exp. Date
                </p>
              </div>
              <hr style={{ marginBlock: "0px" }} />

              {extraRow.map((item: ExtraInformation, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBottom: "0px",
                      }}
                    >
                      {
                        type.find((value: any) => value.id === parseInt(item.idTypeId))
                          ?.name
                      }
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBottom: "0px",
                      }}
                    >
                      {item.idNumber}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBottom: "0px",
                      }}
                    >
                      {item.issueDate}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "25%",
                        marginBottom: "0px",
                      }}
                    >
                      {item.exprationDate}
                    </p>
                  </div>
                );
              })}
            </>
          )}

          {localAddress && (
            <div style={{ marginTop: "10px" }}>
              <label
                htmlFor="localAddress"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Local Address
                <br />
              </label>
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "start",
                  width: "25%",
                  marginBlock: "0px",
                }}
              >
                {localAddress}
              </p>
            </div>
          )}

          <h5
            style={{
              paddingBottom: "0px",
              marginBottom: "0px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              cursor: "pointer",
              WebkitUserSelect: "none" /* Safari */,
              msUserSelect: "none" /* IE 10 and IE 11 */,
              userSelect: "none" /* Standard syntax */,
            }}
            onClick={() => setIsSignatures(!isSignatures)}
          >
            {isSignatures ? (
              <ExpandMoreIcon sx={{ fontSize: "20px" }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
            )}{" "}
            Signatures
          </h5>
          <hr />

          {isSignatures && (
            <>
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  border: "1px dashed gray",
                  backgroundImage: `url('${baseURL}reservationFile/Get?name=${signatureURL?.fileName}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "auto"
                }}
              >
                <SignatureCanvas
                  ref={signaturePadRef}
                  penColor={colorConfigs.topbar.bg}
                  canvasProps={{
                    width: 900,
                    height: 150,
                    className: "sigCanvas",
                  }}
                />
              </div>

              <i
                style={{
                  fontSize: "18px",
                  backgroundColor: "orange",
                  color: "white",
                  padding: "3px 5px",
                  borderRadius: "3px",
                  cursor: "pointer",
                  marginTop: "5px",
                }}
                className="bx bxs-eraser"
                onClick={() => {
                  if (!isCompletedOrCancelled) {
                    signaturePadRef.current?.clear();
                    if (signatureURL) {
                      deleteTheSignatureURL();
                    }
                  }
                }}
              ></i>
            </>
          )}

          <div>
            <label
              htmlFor="sendConfirmationEmail"
              style={{ fontSize: "10px", textAlign: "center" }}
            >
              <input type="checkbox" name="sendConfirmationEmail" />
              Send Confirmation Email
            </label>
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "3px",
            }}
          >
            <button
              style={{
                marginBlock: "5px",
                paddingBlock: "3px",
                backgroundColor: "#66BB6A",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => confirmTheReservation()}
            >
              Confirm Reservation
            </button>

            <button
              style={{
                marginBlock: "5px",
                paddingBlock: "3px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid gray",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!isCompletedOrCancelled)
                  setConfrimStatus("Pending")
              }
              }
            >
              Confirm as Pending
            </button>

            {reservation && (
              <button
                style={{
                  marginBlock: "5px",
                  paddingBlock: "3px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid gray",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!isCompletedOrCancelled) {
                    primaryKey = "";
                    confirmTheReservation();
                  }
                }}
              >
                Confirm as Clone
              </button>
            )}

            {reservation && (
              <button
                style={{
                  marginBlock: "5px",
                  paddingBlock: "3px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid gray",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!isCompletedOrCancelled) {
                    changeStatusToQuote()
                  }
                }
                }
              >
                Quote
              </button>
            )}
          </div>
        </div>
      </div>

      <PleaseWaitPopup pleaseWaitPopup={pleaseWait} />
    </>
  );
}
