import {
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../../../configs/colorConfigs";
import LaunchIcon from "@mui/icons-material/Launch";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AddLabel } from "./AddLabel";
import baseURL from "../../../../configs/api";
import axios from "axios";
import axois from "../../../../configs/axois";
import Select, { components } from "react-select";
import "./AddLabel.css";
import useQuickLinks from "../../../../components/hooks/useQuickLinks";
import PleaseWaitPopup from "../../../PleaseWaitPopup";


const topBarValues = [
  {
    value: "alternatePlane1",
    label: "Alternate Plane",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-plane-167-474991.png",
  },
  {
    value: "alternatePlane2",
    label: "Alternate Plane",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-plane-1440142-1214643.png",
  },
  {
    value: "mailBulk",
    label: "Mail Bulk",
    icon: "https://cdn.iconscout.com/icon/premium/png-512-thumb/bulk-mail-8106087-6606997.png",
  },
  {
    value: "alternateCar",
    label: "Alternate Car",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-car-1780538-1517628.png",
  },
  {
    value: "location",
    label: "Location",
    icon: "https://cdn.iconscout.com/icon/free/png-512/free-gps-fixed-1779970-1518174.png",
  },
  {
    value: "addressCard",
    label: "Address Card",
    icon: "https://cdn.iconscout.com/icon/premium/png-512-thumb/address-card-2240304-1867652.png",
  },
];

const Option = (props: any) => (
  <components.Option {...props} className="top-bar-icon-option">
    <img src={props.data.icon} alt="logo" className="top-bar-icon-logo" />
    {props.data.label}
  </components.Option>
);

export const QuickLinks = () => {
  const { quickLinks, setQuickLinks }: any = useQuickLinks();
  const [selectedTopBarIcon, setSelectedTopBarIcon] = useState(topBarValues[0]);

  const handleChange = (value: any) => {
    setSelectedTopBarIcon(value);
    setTopBarIcon(value.value);
  };

  const SingleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>
      <img src={selectedTopBarIcon?.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDeleted, setIsDeleted] = useState(0);
  const [isAdded, setAdded] = useState(false);

  const [id, setId] = useState<number>();
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [label, setLabel] = useState("");
  const [link, setLink] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isOnTopBar, setIsOnTopBar] = useState(true);
  const [isOpenInNewTab, setIsOpenInNewTab] = useState(true);
  const [topBarIcon, setTopBarIcon] = useState(topBarValues[0].value);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState(false);

  useEffect(() => {
    fetch(`${baseURL}QuickLinks`)
      .then((res) => res.json())
      .then((data) => {
        setIsDataLoaded(true);
        setQuickLinks(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted]);

  const deletingLinks = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this item?") === true) {
      setPleaseWaitPopup(true);
      await axios
        .delete(`${baseURL}QuickLinks?id=${id}`)
        .then(() => {
          setIsDeleted(isDeleted + 1);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setPleaseWaitPopup(false);
        });
    }
  };

  const ChangeHandle = async (id: number,
    label: string,
    link: string,
    isActive: boolean,
    isOnTopBar: boolean,
    isOpenInNewTab: boolean,
    topBarIcon: string,
    userId: string) => {

    setId(id);
    setLink(link);
    setLabel(label);
    setIsActive(isActive);
    setIsOnTopBar(isOnTopBar);
    setIsOpenInNewTab(isOpenInNewTab);
    setTopBarIcon(topBarIcon);
    setUserId(userId);
  }

  useEffect(() => {
    axois
      .get(`${baseURL}users`)
      .then((response) => {
        setUsers(
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const saveNewRow = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const linkString = typeof link === 'string' ? link : '';
    setPleaseWaitPopup(true);

    if (!isAdded) {
      axios
        .put(
          `${baseURL}QuickLinks`,
          JSON.stringify({
            id,
            label,
            link: linkString,
            isActive,
            isOnTopBar,
            isOpenInNewTab,
            topBarIcon,
            userId,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          const updatedQuickLinks = quickLinks.map((link: any) =>
            link.id === id
              ? { ...link, label, link: linkString, isActive, isOnTopBar, isOpenInNewTab, topBarIcon, userId }
              : link
          );
          setQuickLinks(updatedQuickLinks);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setPleaseWaitPopup(false);
        });
    } else {
      await axios
        .post(
          `${baseURL}QuickLinks`,
          JSON.stringify({
            label,
            link: linkString,
            isActive,
            isOnTopBar,
            isOpenInNewTab,
            topBarIcon,
            userId,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          const newQuickLink = {
            id,
            label,
            link: linkString,
            isActive,
            isOnTopBar,
            isOpenInNewTab,
            topBarIcon,
            userId,
          };
          setQuickLinks([...quickLinks, newQuickLink]);
          setIsDeleted(isDeleted + 1);
          setAdded(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setPleaseWaitPopup(false);
        });
    }
  };

  return (
    <Box>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LaunchIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Quick Links
          </Typography>
        </Box>
      </Toolbar>

      <hr />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <div style={{ width: "25%" }}>Label</div>

        <div style={{ width: "25%" }}>Link</div>

        <div style={{ width: "10%" }}>Active?</div>

        <div style={{ width: "10%" }}>On Top Bar?</div>

        <div style={{ width: "10%" }}>Open in New Tab?</div>

        <div style={{ width: "15%" }}>Top Bar Icon</div>

        <div style={{ width: "15%" }}>User</div>

        <div style={{ width: "5%" }}>
          <DeleteForeverIcon sx={{ color: "red" }} />
        </div>
      </div>

      <hr style={{ marginLeft: "24px", marginRight: "24px", marginTop: "0" }} />

      {isDataLoaded ? (
        quickLinks.map((item: any) => (
          <AddLabel
            key={item.id}
            data={item}
            handleDelete={(id) => deletingLinks(id)}
            handleTheChange={(id, label, link, isActive, isOnTopBar, isOpenInNewTab, topBarIcon,
              userId) => ChangeHandle(id, label, link, isActive, isOnTopBar, isOpenInNewTab, topBarIcon, userId)}
          />
        ))
      ) : (
        <div className="loading">
          <p>Please wait</p>
          <span>
            <i></i>
            <i></i>
          </span>
        </div>
      )}

      {isDataLoaded && (
        <form onSubmit={(e) => saveNewRow(e)}>
          {isAdded && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "24px",
                paddingRight: "24px",
                backgroundColor: colorConfigs.sidebar.hoverBg,
              }}
            >
              <div
                style={{
                  width: "25%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <input
                  type="text"
                  name="label"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  required
                />
              </div>

              <div
                style={{
                  width: "25%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <input
                  type="text"
                  name="link"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  required
                />
              </div>

              <div
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <select
                  name="active"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={isActive ? "Yes" : "No"}
                  onChange={(e) =>
                    setIsActive(e.target.value === "Yes" ? true : false)
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <select
                  name="onTopBar"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={isOnTopBar ? "Yes" : "No"}
                  onChange={(e) =>
                    setIsOnTopBar(e.target.value === "Yes" ? true : false)
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <select
                  name="openInNewTab"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={isOpenInNewTab ? "Yes" : "No"}
                  onChange={(e) =>
                    setIsOpenInNewTab(e.target.value === "Yes" ? true : false)
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div
                style={{
                  width: "15%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Select
                  value={selectedTopBarIcon}
                  options={topBarValues}
                  onChange={handleChange}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
              </div>

              <div
                style={{
                  width: "15%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <select
                  name="user"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                >
                  <option value={0}></option>
                  {users.map((user: any) => {
                    return <option value={user.value}>{user.label}</option>;
                  })}
                </select>
              </div>

              <div style={{ width: "5%" }}>
                <IconButton
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      ) === true
                    )
                      setAdded(false);
                  }}
                >
                  <DeleteForeverIcon sx={{ color: "red" }} />
                </IconButton>
              </div>
            </div>
          )}
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "2",
              alignItems: "center",
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              paddingLeft: "32px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                borderRadius: "0",
                textTransform: "capitalize",
                marginRight: "8px",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={() => {
                setId(0);
                setLink("");
                setLabel("");
                setIsActive(true);
                setIsOnTopBar(true);
                setIsOpenInNewTab(true);
                setTopBarIcon(topBarValues[0].value);
                setUserId("");
                setAdded(true)
              }}
            >
              Add Row
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                borderRadius: "0",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      )}
      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </Box>
  );
};
