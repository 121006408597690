import { Box, 
    Button, 
    Toolbar, 
    Typography,
    Dialog,
    DialogContent,
    DialogTitle, 
} from "@mui/material";
import React, { useEffect, useState ,useRef} from "react";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Webcam from "react-webcam";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import colorConfigs from "../../../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axois from "../../../../configs/axois";
import baseURL from "../../../../configs/api";
import { handleCapture } from "../../../../utils/covertTheBase64FormatIntoImage";
import useAuth from "../../../../components/hooks/useAuth";


export const AddVehicleType = () => {  
  const {auth}: any = useAuth();
  const [label, setLabel] = useState<string>("");  
  const [order, setOrder] = useState<string>("");
  const [maxFuellevel, setMaxFuellevel] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [filePath, setFilePath] = useState<string[]>([]);
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  const [isImageSaved, setIsImageSaved] = useState<boolean>(false);
  const [primaryKey, setPrimaryKey] = useState<number>();

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}commonFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          const newFilePath: string =
            `${baseURL}commonFile/Get?name=` +
            response.data;
          setFilePath((prevPath: string[]) => [...prevPath, newFilePath]);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setPleaseWaitPopup(false);
    }
  };
    

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}vehicleType`,
        JSON.stringify({
          name: label,
          order,
          maxFuellevel,
          isActive: active,
          imageLocation: "",
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        setIsImageSaved(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleType`)
      .then((response) => {
        setPrimaryKey(parseInt(response.data[0].id) + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (isImageSaved) {
      if (filePath.length !== 0) {
        filePath.forEach((item: string, index: number) => {
          axios
            .post(
              `${baseURL}fileUploadData`,
              JSON.stringify({
                attributeName: "VehicleType",
                attributeId: primaryKey,
                fileName: item?.replace(
                  `${baseURL}commonFile/Get?name=`,
                  ""
                ),
                tabName: "VehicleType",
                userId: auth?.id ?? 0,
                createdDate: new Date(),
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
              if (filePath.length - 1 === index) {
                setPleaseWaitPopup(false);
                navigate(-1);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
      } else {
        setPleaseWaitPopup(false);
        navigate(-1);
      }
      setIsImageSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImageSaved]);

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <CreditCardIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Vehicle Types / Add Vehicle Type
          </Typography>
        </Box>
      </Toolbar>
      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px",fontSize: "18px" }}>
           Vehicle Type Overview
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                <label
                    htmlFor="label"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Label
                    <br />
                    <input
                      type="text"
                      name="label"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      required
                    />
                  </label>
                </div>
                <div style={{ marginTop: "10px" }}>
                <label
                    htmlFor="maxFuellevel"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Max Fuel Level
                    <br />
                    <input
                      type="number"
                      name="maxFuellevel"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={maxFuellevel}
                      onChange={(e) => setMaxFuellevel(e.target.value)}
                      required
                    />
                  </label>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="images"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Images
                    <br />
                    {filePath.length !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {filePath.map((item: string, outerIndex: number) => {
                          return (
                            <React.Fragment key={outerIndex}>
                              <img
                                src={item}
                                width={40}
                                height={40}
                                alt="repairOrder"
                                onClick={() => {
                                  setFileIndex(outerIndex);
                                  setImagePopup(true);
                                }}
                              />
                              <RemoveCircleIcon
                                sx={{
                                  fontSize: "14px",
                                  color: colorConfigs.topbar.bg,
                                  fontWeight: "bold",
                                  "&: hover": {
                                    color: "red",
                                  },
                                }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this image?"
                                    )
                                  ) {
                                    let newFileSet: string[] = filePath.filter(
                                      (_, index: number) => index !== outerIndex
                                    );
                                    setFilePath(() => newFileSet);
                                  }
                                }}
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputFileRef}
                      onChangeCapture={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        marginTop: "1px",
                      }}
                    >
                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => {
                          if (inputFileRef.current !== null) {
                            inputFileRef.current.click();
                          }
                        }}
                      >
                        {" "}
                        <AddIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                        Add Image
                      </p>

                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={() => setShowWebcam(true)}
                      >
                        {" "}
                        <LocalSeeIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                      </p>
                    </div>
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                <label
                    htmlFor="hourlyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Order
                    <br />
                    <input
                      type="number"
                      name="order"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                      required
                    />
                  </label>
                  </div>
                  <div style={{ marginTop: "10px" ,visibility: 'hidden' }}>
                <label
                    htmlFor="hourlyRate"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Order
                    <br />
                    <input
                      type="number"
                      name="order"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                      required
                    />
                  </label>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                      Active?
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="enabled"
                        style={{ fontSize: "12px" }}
                        checked={active ? true : false}
                        onChange={(e) =>
                          setActive(e.target.value === "Yes" ? true : false)
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="enabled"
                        style={{ fontSize: "12px" }}
                        checked={active ? false : true}
                        onChange={(e) =>
                          setActive(e.target.value === "Yes" ? true : false)
                        }
                      />
                      No
                    </label>
                  </div>
                  </div>
                </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => setShowWebcam(false)}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();
                    
                    handleCapture(imageSrc, `${baseURL}commonFile/upload`, (imageFilePath: string) => {
                      if (imageFilePath) {                         
                          setFilePath((prevFileString: string[]) => [
                            ...prevFileString,
                            `${baseURL}commonFile/Get?name=${imageFilePath}`
                          ]);
                      }
                    })

                    setShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Attachement</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {filePath.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  left: "0",
                  top: "50%",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    cursor: fileIndex !== 0 ? "pointer" : "",
                    opacity: fileIndex === 0 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== 0) {
                      setFileIndex(fileIndex - 1);
                    }
                  }}
                />
              </div>
              <img
                width={700}
                height={350}
                src={filePath[fileIndex]}
                alt="repairOrder"
              />

              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  right: "0",
                  top: "50%",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    cursor: fileIndex !== filePath.length - 1 ? "pointer" : "",
                    opacity: fileIndex === filePath.length - 1 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== filePath.length - 1) {
                      setFileIndex(fileIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
