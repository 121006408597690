import React, { useState } from "react";
import ChangesAdditionalCharges from "./ChangesAdditionalCharges";
import RackRateDetails from "./RackRateDetails";
import axios from "axios";
import baseURL from "../../../configs/api";
import PleaseWaitPopup from "../../PleaseWaitPopup";

type Props = {
  branches: any;
  reservation: any;
  reservationAdditionalCharge: any[];
  updateReservation: () => void;
  isCompletedOrCancelled: boolean
};

export default function Changes({
  branches,
  reservation,
  reservationAdditionalCharge,
  updateReservation,
  isCompletedOrCancelled
}: Props) {
  const [hourlyRate, setHourlyRate] = useState<string>("");
  const [dailyRate, setDailyRate] = useState<string>("");
  const [weeklyRate, setWeeklyRate] = useState<string>("");
  const [yearlyRate, setYearlyRate] = useState<string>("");
  const [isRatesChange, setIsRatesChange] = useState<boolean>(false);
  const [additionalCharges, setAdditionalCharges] = useState<any[]>([]);
  const [isAdditionalChanges, setIsAdditionalChanges] =
    useState<boolean>(false);

  const [pleaseWait, setPleaseWait] = useState<boolean>(false);

  const changeRates = (
    hourlyRate: string,
    dailyRate: string,
    weeklyRate: string,
    yearlyRate: string
  ) => {
    setHourlyRate(hourlyRate);
    setDailyRate(dailyRate);
    setWeeklyRate(weeklyRate);
    setYearlyRate(yearlyRate);
    setIsRatesChange(true);
  };

  const changesAddditionalChanges = (additionalCharge: any) => {
    if (additionalCharges.length !== 0) {
      if (
        additionalCharges.some((item: any) => item.id === additionalCharge.id)
      ) {
        setAdditionalCharges(() =>
          additionalCharges.map((rowValue: any) => {
            if (rowValue.id === additionalCharge.id) {
              return additionalCharge;
            }
            return rowValue;
          })
        );
      } else {
        setAdditionalCharges((prevAdditionalChanges: any) => [
          ...prevAdditionalChanges,
          additionalCharge,
        ]);
      }
    } else {
      setAdditionalCharges(() => [additionalCharge]);
    }
    setIsAdditionalChanges(true);
  };

  const updateReservationAPI = async () => {
    if (!isCompletedOrCancelled) {
      if (isRatesChange) {
        setPleaseWait(true);
        await axios
          .put(
            `${baseURL}reservation`,
            {
              ...reservation,
              dailyRate: dailyRate,
              hourlyRate: hourlyRate,
              weeklyRate: weeklyRate,
              yearlyRate: yearlyRate,
              totalPrice:
                reservation?.totalPrice -
                (reservation?.totalYears * reservation?.yearlyRate +
                  reservation?.totalWeeks * reservation?.weeklyRate +
                  reservation?.totalDays * reservation?.dailyRate +
                  reservation?.totalHours * reservation?.hourlyRate) +
                (reservation?.totalYears * Number(yearlyRate) +
                  reservation?.totalWeeks * Number(weeklyRate) +
                  reservation?.totalDays * Number(dailyRate) +
                  reservation?.totalHours * Number(hourlyRate)),
              outstandingBalance:
                reservation.outstandingBalance -
                (reservation?.totalYears * reservation?.yearlyRate +
                  reservation?.totalWeeks * reservation?.weeklyRate +
                  reservation?.totalDays * reservation?.dailyRate +
                  reservation?.totalHours * reservation?.hourlyRate) +
                (reservation?.totalYears * Number(yearlyRate) +
                  reservation?.totalWeeks * Number(weeklyRate) +
                  reservation?.totalDays * Number(dailyRate) +
                  reservation?.totalHours * Number(hourlyRate)),
              wasRateManuallyChanged: true,
            },
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then((response) => {
            setHourlyRate("");
            setDailyRate("");
            setWeeklyRate("");
            setYearlyRate("");
            setIsRatesChange(false);
            updateReservation();
            setPleaseWait(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const updateAdditionalChanges = () => {
    if (!isCompletedOrCancelled) {
      if (isAdditionalChanges) {
        setPleaseWait(true);

        let totalAmount = reservation ? reservation.totalPrice : 0;
        let outstandingBalance = reservation
          ? reservation?.outstandingBalance
          : 0;
        let count = 0;

        additionalCharges?.forEach((item: any) => {
          axios
            .put(`${baseURL}reservationAdditionalCharge`, item, {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            })
            .then((response) => {
              count++;
              totalAmount +=
                item.quantity * item.basePrice -
                reservationAdditionalCharge.find(
                  (value: any) => value.id === item.id
                )?.quantity *
                reservationAdditionalCharge.find(
                  (value: any) => value.id === item.id
                )?.basePrice;

              outstandingBalance +=
                item.quantity * item.basePrice -
                reservationAdditionalCharge.find(
                  (value: any) => value.id === item.id
                )?.quantity *
                reservationAdditionalCharge.find(
                  (value: any) => value.id === item.id
                )?.basePrice;
              if (additionalCharges.length === count) {
                updateTotalValue(totalAmount, outstandingBalance);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });

        });

      }
    }
  }

  const updateTotalValue = async (
    totalAmount: number,
    outstandingBalance: number
  ) => {
    if (!isCompletedOrCancelled) {
      if (isRatesChange) {
        totalAmount =
          totalAmount -
          (reservation?.totalYears * reservation?.yearlyRate +
            reservation?.totalWeeks * reservation?.weeklyRate +
            reservation?.totalDays * reservation?.dailyRate +
            reservation?.totalHours * reservation?.hourlyRate) +
          (reservation?.totalYears * Number(yearlyRate) +
            reservation?.totalWeeks * Number(weeklyRate) +
            reservation?.totalDays * Number(dailyRate) +
            reservation?.totalHours * Number(hourlyRate));

        outstandingBalance =
          outstandingBalance -
          (reservation?.totalYears * reservation?.yearlyRate +
            reservation?.totalWeeks * reservation?.weeklyRate +
            reservation?.totalDays * reservation?.dailyRate +
            reservation?.totalHours * reservation?.hourlyRate) +
          (reservation?.totalYears * Number(yearlyRate) +
            reservation?.totalWeeks * Number(weeklyRate) +
            reservation?.totalDays * Number(dailyRate) +
            reservation?.totalHours * Number(hourlyRate));
      }

      await axios
        .put(
          `${baseURL}reservation`,
          {
            ...reservation,
            dailyRate: isRatesChange ? dailyRate : reservation?.dailyRate,
            hourlyRate: isRatesChange ? hourlyRate : reservation?.hourlyRate,
            weeklyRate: isRatesChange ? weeklyRate : reservation?.weeklyRate,
            yearlyRate: isRatesChange ? yearlyRate : reservation?.yearlyRate,
            totalPrice: totalAmount,
            outstandingBalance: outstandingBalance,
            wasRateManuallyChanged: isRatesChange
              ? true
              : reservation?.wasRateManuallyChanged,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          setIsAdditionalChanges(false);
          setIsRatesChange(false);
          setHourlyRate("");
          setDailyRate("");
          setWeeklyRate("");
          setYearlyRate("");
          setAdditionalCharges([]);
          updateReservation();
          setPleaseWait(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <div>
      <RackRateDetails
        branches={branches}
        reservation={reservation}
        changeRates={(hourlyRate, dailyRate, weeklyRate, yearlyRate) =>
          changeRates(hourlyRate, dailyRate, weeklyRate, yearlyRate)
        }
      />
      <ChangesAdditionalCharges
        reservationAdditionalCharge={reservationAdditionalCharge}
        changesAddditionalChanges={(additionalCharge) =>
          changesAddditionalChanges(additionalCharge)
        }
      />

      <button
        style={{
          marginTop: "16px",
          paddingBlock: "8px",
          backgroundColor: "#f0ad4e",
          color: "white",
          border: "none",
          cursor: "pointer",
          marginLeft: "24px",
          marginRight: "24px",
          fontSize: "12px",
        }}
        onClick={() => {
          if (!isCompletedOrCancelled) {
            if (isRatesChange && isAdditionalChanges) {
              updateAdditionalChanges();
            } else if (isRatesChange) {
              updateReservationAPI();
            } else if (isAdditionalChanges) {
              updateAdditionalChanges();
            }
          }
        }}
      >
        Save Changes
      </button>

      <PleaseWaitPopup pleaseWaitPopup={pleaseWait} />
    </div>
  );
}
