import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../configs/colorConfigs";
import useAuth from "../hooks/useAuth";
import { useEffect, useState } from "react";
import { getLoctionOpenHours } from "../../services/locationOpenHour";
import { post_EarlyReturn } from "../../services/earlyReturn";
import { put_Reservation } from "../../services/reservation";
import PleaseWaitPopup from "../../pages/PleaseWaitPopup";


type AddEarlyReturnModalProps = {
    addEarlyReturnIsOpen: boolean;
    setAddEarlyReturnIsOpen: (value: boolean) => void;
    reservation: any;
    setReservationUpdate: (value: boolean) => void;
    setEarlyReturnRefresh: (value: boolean) => void;
}

const AddEarlyReturnModal = ({ addEarlyReturnIsOpen, setAddEarlyReturnIsOpen, reservation, setReservationUpdate, setEarlyReturnRefresh }: AddEarlyReturnModalProps) => {
    const { auth }: any = useAuth();
    const [newReturnDate, setNewReturnDate] = useState<string>("");
    const [newReturnTime, setNewReturnTime] = useState<string>("");
    const [pleaseWait, setPleaseWait] = useState<boolean>(false);
    const [locationOpenHourOfParticularBranch, setLocationOpenHourOfParticularBranch] = useState<any[]>([]);
    const [returnDateMessage, setReturnDateMessage] = useState<string>("");
    const [recalulateRates, setRecalulateRates] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");


    useEffect(() => {
        if (reservation && addEarlyReturnIsOpen) {
            getLoctionOpenHours().then(data => {
                setLocationOpenHourOfParticularBranch(data?.filter((item: any) => item.branchId === parseInt(reservation?.branchId) && (new Date(item.startDate) <= new Date() && new Date(item.endDate) >= new Date())));
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservation, addEarlyReturnIsOpen]);

    useEffect(() => {
        if (addEarlyReturnIsOpen) {
            setNewReturnDate(new Date(reservation?.returenDate).toISOString().split("T")[0]);
            setNewReturnTime(reservation?.returenDate.split("T")[1])
        }
    }, [reservation, addEarlyReturnIsOpen])

    useEffect(() => {
        if (locationOpenHourOfParticularBranch.length !== 0) {
            let returnDateMessages = "";
            if (
                new Date(locationOpenHourOfParticularBranch[0].endDate) >=
                new Date(newReturnDate.replaceAll("-", "/"))
            ) {
                switch (new Date(newReturnDate.replaceAll("-", "/")).getDay()) {
                    case 0:
                        if (locationOpenHourOfParticularBranch[0].isSundayOpen) {
                            returnDateMessages = "";
                            if (
                                locationOpenHourOfParticularBranch[0].sundayOpenTime <=
                                newReturnTime &&
                                locationOpenHourOfParticularBranch[0].sundayCloseTime >=
                                newReturnTime
                            ) {
                                returnDateMessages = "";
                            } else {
                                returnDateMessages =
                                    "Office on Sunday is open from " +
                                    locationOpenHourOfParticularBranch[0].sundayOpenTime +
                                    " to " +
                                    locationOpenHourOfParticularBranch[0].sundayCloseTime;
                            }
                        } else {
                            returnDateMessages = "Office is close on Sunday";
                        }
                        break;
                    case 1:
                        if (locationOpenHourOfParticularBranch[0].isMondayOpen) {
                            returnDateMessages = "";
                            if (
                                locationOpenHourOfParticularBranch[0].mondayOpenTime <=
                                newReturnTime &&
                                locationOpenHourOfParticularBranch[0].mondayCloseTime >=
                                newReturnTime
                            ) {
                                returnDateMessages = "";
                            } else {
                                returnDateMessages =
                                    "Office on Monday is open from " +
                                    locationOpenHourOfParticularBranch[0].mondayOpenTime +
                                    " to " +
                                    locationOpenHourOfParticularBranch[0].mondayCloseTime;
                            }
                        } else {
                            returnDateMessages = "Office is close on Monday";
                        }
                        break;
                    case 2:
                        if (locationOpenHourOfParticularBranch[0].isTuesdayOpen) {
                            returnDateMessages = "";
                            if (
                                locationOpenHourOfParticularBranch[0].tuesdayOpenTime <=
                                newReturnTime &&
                                locationOpenHourOfParticularBranch[0].tuesdayCloseTime >=
                                newReturnTime
                            ) {
                                returnDateMessages = "";
                            } else {
                                returnDateMessages =
                                    "Office on Tuesday is open from " +
                                    locationOpenHourOfParticularBranch[0].tuesdayOpenTime +
                                    " to " +
                                    locationOpenHourOfParticularBranch[0].tuesdayCloseTime;
                            }
                        } else {
                            returnDateMessages = "Office is close on Tuesday";
                        }
                        break;
                    case 3:
                        if (locationOpenHourOfParticularBranch[0].isWednesdayOpen) {
                            returnDateMessages = "";
                            if (
                                locationOpenHourOfParticularBranch[0].wednesdayOpenTime <=
                                newReturnTime &&
                                locationOpenHourOfParticularBranch[0].wednesdayCloseTime >=
                                newReturnTime
                            ) {
                                returnDateMessages = "";
                            } else {
                                returnDateMessages =
                                    "Office on Wednesday is open from " +
                                    locationOpenHourOfParticularBranch[0].wednesdayOpenTime +
                                    " to " +
                                    locationOpenHourOfParticularBranch[0].wednesdayCloseTime;
                            }
                        } else {
                            returnDateMessages = "Office is close on Wednesday";
                        }
                        break;
                    case 4:
                        if (locationOpenHourOfParticularBranch[0].isThursdayOpen) {
                            returnDateMessages = "";
                            if (
                                locationOpenHourOfParticularBranch[0].thursdayOpenTime <=
                                newReturnTime &&
                                locationOpenHourOfParticularBranch[0].thursdayCloseTime >=
                                newReturnTime
                            ) {
                                returnDateMessages = "";
                            } else {
                                returnDateMessages =
                                    "Office on Thursday is open from " +
                                    locationOpenHourOfParticularBranch[0].thursdayOpenTime +
                                    " to " +
                                    locationOpenHourOfParticularBranch[0].thursdayCloseTime;
                            }
                        } else {
                            returnDateMessages = "Office is close on Thursday";
                        }
                        break;
                    case 5:
                        if (locationOpenHourOfParticularBranch[0].isFridayOpen) {
                            returnDateMessages = "";
                            if (
                                locationOpenHourOfParticularBranch[0].fridayOpenTime <=
                                newReturnTime &&
                                locationOpenHourOfParticularBranch[0].fridayCloseTime >=
                                newReturnTime
                            ) {
                                returnDateMessages = "";
                            } else {
                                returnDateMessages =
                                    "Office on Friday is open from " +
                                    locationOpenHourOfParticularBranch[0].fridayOpenTime +
                                    " to " +
                                    locationOpenHourOfParticularBranch[0].fridayCloseTime;
                            }
                        } else {
                            returnDateMessages = "Office is close on Friday";
                        }
                        break;
                    case 6:
                        if (locationOpenHourOfParticularBranch[0].isSaturdayOpen) {
                            returnDateMessages = "";
                            if (
                                locationOpenHourOfParticularBranch[0].saturdayOpenTime <=
                                newReturnTime &&
                                locationOpenHourOfParticularBranch[0].saturdayCloseTime >=
                                newReturnTime
                            ) {
                                returnDateMessages = "";
                            } else {
                                returnDateMessages =
                                    "Office on Saturday is open from " +
                                    locationOpenHourOfParticularBranch[0].saturdayOpenTime +
                                    " to " +
                                    locationOpenHourOfParticularBranch[0].saturdayCloseTime;
                            }
                        } else {
                            returnDateMessages = "Office is close on Saturday";
                        }
                        break;
                }
                if (returnDateMessages !== "") {
                    setReturnDateMessage(returnDateMessages);
                }
                else {
                    setReturnDateMessage("");
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newReturnDate, newReturnTime])

    const getTotalDateDiff = () => {
        const start: any = new Date(reservation?.pickupDate);
        const end: any = new Date(newReturnDate + "T" + newReturnTime);

        let diffMs = Math.abs(end - start);

        const millisecondsInHour = 1000 * 60 * 60;
        const millisecondsInDay = millisecondsInHour * 24;
        const millisecondsInWeek = millisecondsInDay * 7;

        // Calculate months
        let months = 0;
        while (start < end) {
            const tempDate = new Date(start);
            tempDate.setMonth(tempDate.getMonth() + 1);
            if (tempDate <= end) {
                months++;
                start.setMonth(start.getMonth() + 1);
            } else {
                break;
            }
        }

        diffMs = end - start;

        const weeks = Math.floor(diffMs / millisecondsInWeek);
        diffMs -= weeks * millisecondsInWeek;

        const days = Math.floor(diffMs / millisecondsInDay);
        diffMs -= days * millisecondsInDay;

        const hours = Math.floor(diffMs / millisecondsInHour);

        return {
            months,
            weeks,
            days,
            hours,
        };
    }

    const handleSubmit = () => {
        if (new Date(reservation?.returenDate).getTime() <= new Date(`${newReturnDate}T${newReturnTime}`).getTime()) {
            setReturnDateMessage(
                `New return date can't be same or greater than previous return Date`
            );
        } else {
            setPleaseWait(true)
            const dateDifference = getTotalDateDiff();
            post_EarlyReturn({
                reservationId: reservation?.id,
                oldReturenDate: reservation?.returenDate,
                newReturenDate: newReturnDate + "T" + newReturnTime,
                comment: comment,
                createdBy: auth?.givenname,
                createdAt: new Date(),
            }, auth?.id)
                .then((res) => {
                    put_Reservation({
                        ...reservation,
                        returenDate: newReturnDate + "T" + newReturnTime,
                        totalHours: dateDifference?.hours,
                        totalDays: dateDifference?.days,
                        totalWeeks: dateDifference?.weeks,
                        totalYears: dateDifference?.months,
                        totalPrice:
                            (dateDifference?.months * reservation?.yearlyRate +
                                dateDifference?.weeks * reservation?.weeklyRate +
                                dateDifference?.days * reservation?.dailyRate +
                                dateDifference?.hours * reservation?.hourlyRate),
                        outstandingBalance:
                            (dateDifference?.months * reservation?.yearlyRate +
                                dateDifference?.weeks * reservation?.weeklyRate +
                                dateDifference?.days * reservation?.dailyRate +
                                dateDifference?.hours * reservation?.hourlyRate) - reservation.totalPaid,
                        recalculateRatesOnEarlyReturn: recalulateRates
                    })
                        .then((res) => {
                            setPleaseWait(false)
                            setAddEarlyReturnIsOpen(false);
                            setEarlyReturnRefresh(true);
                            setReservationUpdate(true);
                        })
                        .catch((error) => {
                            console.error("Error:", error)
                        })
                })
                .catch((error) => {
                    console.error("Error:", error)
                })
        }
    }


    return (
        <>
            <Dialog
                open={addEarlyReturnIsOpen}
                PaperProps={{
                    style: {
                        marginTop: "-14%",
                        width: "500px"
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Early Return
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                            onClick={() => setAddEarlyReturnIsOpen(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>
                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "16px",
                        }}>
                            <div style={{
                                width: "50%",

                            }}>
                                <label
                                    htmlFor="amount"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Return Date
                                    <br />
                                    <input
                                        type="date"
                                        name="return-date"
                                        max={reservation?.returenDate
                                            ? (new Date(reservation?.returenDate) === undefined ? new Date()?.toISOString()?.split("T")[0] : new Date(reservation?.returenDate)?.toISOString()?.split("T")[0])
                                            : ""}
                                        style={{
                                            width: "100%",
                                            fontSize: "14px",
                                            paddingBlock: "5px",
                                        }}
                                        value={newReturnDate}
                                        onChange={(e) => setNewReturnDate(e.target.value)}
                                        required
                                    />
                                </label>
                            </div>

                            <div style={{ width: "50%" }}>
                                <label
                                    htmlFor="amount"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Return Time
                                    <br />
                                    <input
                                        type="time"
                                        name="return-time"
                                        style={{
                                            width: "100%",
                                            fontSize: "14px",
                                            paddingBlock: "5px",
                                        }}
                                        value={newReturnTime}
                                        onChange={(e) => setNewReturnTime(e.target.value)}
                                        required
                                    />
                                </label>
                                <div style={{ width: "100%" }}>
                                    <p style={{ color: "red", marginBlock: "0px", fontSize: "12px", fontWeight: "bold" }}>
                                        {returnDateMessage}
                                    </p>

                                </div>
                            </div>

                        </div>

                        <div style={{ width: "100%" }}>
                            <label
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                htmlFor="recalculateRatesOnEarlyReturn"
                            >
                                Recalculate rates on early Return?
                                <br />
                                <div>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="recalculateRatesOnEarlyReturn"
                                            style={{ fontSize: "12px" }}
                                            checked={recalulateRates ? true : false}
                                            onChange={(e) =>
                                                setRecalulateRates(e.target.value === "Yes" ? true : false)
                                            }
                                        />{" "}
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="recalculateRatesOnEarlyReturn"
                                            style={{ fontSize: "12px" }}
                                            checked={recalulateRates ? false : true}
                                            onChange={(e) =>
                                                setRecalulateRates(e.target.value === "Yes" ? true : false)
                                            }
                                        />{" "}
                                        No
                                    </label>
                                </div>
                            </label>
                        </div>

                        <div style={{ width: "100%" }}>
                            <label
                                htmlFor="description"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Comments
                                <br />
                                <textarea
                                    name="comments"
                                    rows={4}
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    required
                                />
                            </label>
                        </div>

                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            marginTop: "20px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "50%" }} />

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => setAddEarlyReturnIsOpen(false)}
                            >
                                Cancel
                            </Button>


                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "#FFFFFF",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "#FFFFFF",
                                    },
                                }}
                                onClick={() => handleSubmit()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <PleaseWaitPopup pleaseWaitPopup={pleaseWait} />
        </>
    )
}

export default AddEarlyReturnModal;