import { useMemo, useState, useRef, useEffect } from "react";
import styles from "./pie-chart.module.css";
import MenuIcon from '@mui/icons-material/Menu';
import { appName } from "../../../configs/api";

type DataItem = {
    name: string;
    value: number;
};

type PieChartProps = {
    height: number;
    data: DataItem[];
    title?: string;
    thresholdForSlice?: number;
};

type OptionType = {
    value: number,
    label: string
};

const MARGIN_X = 150;
const MARGIN_Y = 50;
const INFLEXION_PADDING = 20; // space between donut and label inflexion point
const INFLEXION_PADDING_FOR_FULLSCREEN = 32; // space between donut and label inflexion point

function generateHSLColors(num: number): string[] {
    const colors = [];
    for (let i = 0; i < num; i++) {
        const hue = (i / num) * 360; // Spread across 360 degrees
        colors.push(`hsl(${hue}, 70%, 60%)`); // Saturation = 70%, Lightness = 60%
    }
    return colors;
}

function getAngle(value: number) {
    const angle = 2 * Math.PI * value;
    return angle;
}

function getCoordinatesForAngle(raduis: number, angle: number) {
    const x = raduis * Math.cos(angle);
    const y = raduis * Math.sin(angle);
    return [x, y];
}

// Handling small slices: Combine slices under a threshold into an "Others" category
function handleSmallSlices(data: any[], threshold: number = 5) {
    let smallSlices: any[] = [];
    let remainingData: any[] = [];

    data?.forEach(slice => {
        if (slice.value < threshold) {
            smallSlices.push(slice);
        } else {
            remainingData.push(slice);
        }
    });

    if (smallSlices.length > 0) {
        let othersValue = smallSlices?.reduce((sum, slice) => sum + slice.value, 0);
        remainingData.push({ name: 'Others', value: othersValue });
    }

    return remainingData;
}

function applyRepelForce(labelPositions: any[], padding: number) {
    const repelForce = padding / 2.5;

    for (let i = 0; i < labelPositions.length; i++) {
        for (let j = i + 1; j < labelPositions.length; j++) {
            const dx = labelPositions[j].x - labelPositions[i].x;
            const dy = labelPositions[j].y - labelPositions[i].y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < padding) {
                const angle = Math.atan2(dy, dx);
                const force = (padding - distance) / distance * repelForce;

                labelPositions[i].x -= Math.cos(angle) * force;
                labelPositions[i].y -= Math.sin(angle) * force;
                labelPositions[j].x += Math.cos(angle) * force;
                labelPositions[j].y += Math.sin(angle) * force;
            }
        }
    }
}

export const PieChart = ({ height, data, title = "Pie Chart", thresholdForSlice = 2 }: PieChartProps) => {
    const ref = useRef<SVGGElement | null>(null);
    const sliceRefs = useRef<(SVGGElement | null)[]>([]);
    const legends = useRef<HTMLDivElement | null>(null);

    const [tooltip, setTooltip] = useState<{
        visible: boolean;
        x: number;
        y: number;
        text: string;
    }>({ visible: false, x: 0, y: 0, text: "" });
    const [pieChartDropDown, setPieChartDropDown] = useState<OptionType[]>([
        { value: 1, label: "View in FullScreen" },
        { value: 2, label: "Print the Chart" }
    ]);
    const [width, setWidth] = useState<number>(0);
    const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
    const [scheduleFullScreen, setScheduleFullScreen] = useState<boolean>(false);

    const dropDownVisibleRef = useRef<HTMLDivElement>(null);
    const scheduleFullScreenRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onResize = () => {
            if (scheduleFullScreenRef.current && !scheduleFullScreen) {
                setWidth(scheduleFullScreenRef.current?.clientWidth);
            }
        }

        onResize();
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, scheduleFullScreen])

    useEffect(() => {
        const handleDropDown = (e: MouseEvent) => {
            if (!dropDownVisibleRef.current?.contains(e.target as Node)) setDropDownVisible(false);
        }

        document.addEventListener("click", handleDropDown);
        return () => document.removeEventListener("click", handleDropDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const colors = generateHSLColors(data?.length);

    const radius = scheduleFullScreen ? (Math.min(window.innerWidth - 2 * MARGIN_X, window.innerHeight - 2 * (MARGIN_Y * 2)) / 2) : Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2;

    const totalValue = useMemo(
        () => data?.reduce((acc, item) => acc + item.value, 0),
        [data]
    );

    const outerRadius = radius; // Outer radius
    const innerRadius = radius - 10; // Inner radius for donut effect (optional)

    const shapes = useMemo(() => {
        let cumulativeValue = 0;
        const pieData = handleSmallSlices(data, (thresholdForSlice / 100) * totalValue);
        const dataWithoutZero = pieData.filter((item: any) => item?.value > 0);
        const labelPositions: any[] = [];
        const padding = scheduleFullScreen ? INFLEXION_PADDING_FOR_FULLSCREEN : INFLEXION_PADDING;

        return dataWithoutZero.map((item, index) => {
            const startAngle = dataWithoutZero.length <= 1 ? getAngle(cumulativeValue / totalValue) : getAngle(cumulativeValue / totalValue) - Math.PI / 2;

            cumulativeValue += item.value;

            const endAngle = dataWithoutZero.length <= 1 ? getAngle(cumulativeValue / totalValue) : getAngle(cumulativeValue / totalValue) - Math.PI / 2;

            const largeArcFlag = item.value / totalValue > 0.5 ? 1 : 0;

            const [x0, y0] = getCoordinatesForAngle(outerRadius, startAngle);
            const [x1, y1] = getCoordinatesForAngle(outerRadius, endAngle);

            const pathData = [
                `M 0 0`,
                `L ${x0} ${y0}`,
                `A ${outerRadius} ${outerRadius}  0 ${largeArcFlag} 1 ${x1} ${y1} Z`,
            ].join(' ');

            let centroidAngle = (startAngle + endAngle) / 2;

            // Calculate the coordinates of the midpoint (center of the arc)
            let [centroidX, centroidY] = getCoordinatesForAngle(outerRadius, centroidAngle);

            let inflexionX = centroidX + (centroidX >= 0 ? padding : -padding);
            let inflexionY = centroidY + (centroidY >= 0 ? padding : -padding);
            let extraXLine = centroidX >= 0 ? padding : -padding;

            const textAnchor = centroidX >= 0 ? "start" : "end";
            const addOnX = centroidX >= 0 ? 2 : -2;

            const label = `${item.name} (${item.value})`;

            // Check if the label will collide with others
            let labelX = inflexionX + extraXLine + addOnX;
            let labelY = inflexionY;

            // Check for overlap with previous labels
            let isColliding = false;
            let attempts = 0;
            const MAX_ATTEMPTS = 10;

            while (attempts < MAX_ATTEMPTS) {
                isColliding = false;
                for (let i = 0; i < labelPositions.length; i++) {
                    const prevLabel = labelPositions[i];
                    if (
                        Math.abs(labelX - prevLabel.x) <= padding &&
                        Math.abs(labelY - prevLabel.y) <= padding
                    ) {
                        isColliding = true;
                        break;
                    }
                }

                // If there is a collision, adjust label position
                if (isColliding) {
                    // Increase the length of the line
                    extraXLine += centroidX >= 0 ? padding / 2.5 : -padding / 2.5;
                    labelX = centroidX >= 0 ? (inflexionX + extraXLine + addOnX) : (inflexionX + extraXLine - addOnX);

                    // Shift the position of the line
                    labelY += centroidX >= 0 ? (padding / 2.5) : -(padding / 2.5);
                    inflexionY += centroidX >= 0 ? (padding / 2.5) : -(padding / 2.5);

                    // Optionally, change the angle of the label
                    // You can add logic here to adjust the angle if necessary                    
                    attempts++;
                } else {
                    break;
                }
            }

            const linePathData = [
                `M ${centroidX} ${centroidY}`, // Move             
                `L ${inflexionX} ${inflexionY}`, // Line
                `L ${inflexionX + extraXLine} ${inflexionY}`, // Line
            ].join(' ');

            // Add the label position to the list
            labelPositions.push({ x: labelX, y: labelY });

            // After calculating initial label positions
            applyRepelForce(labelPositions, padding);

            return (
                <g
                    key={index}
                    ref={(el) => (sliceRefs.current[index] = el)}
                    className={styles.slice}
                    onMouseEnter={(e) => {
                        ref.current?.classList.add(styles.hasHighlight);
                        setTooltip({
                            visible: true,
                            x: scheduleFullScreen ? e.clientX : e.clientX + window.scrollX,
                            y: scheduleFullScreen ? e.clientY : e.clientY + window.scrollY - 10,
                            text: label,
                        });
                    }}
                    onMouseMove={(e) => {
                        setTooltip((prevTooltip) => ({
                            ...prevTooltip,
                            x: scheduleFullScreen ? e.clientX : e.clientX + window.scrollX,
                            y: scheduleFullScreen ? e.clientY : e.clientY + window.scrollY - 10
                        }));
                    }}
                    onMouseLeave={() => {
                        ref.current?.classList.remove(styles.hasHighlight);
                        setTooltip({ visible: false, x: 0, y: 0, text: "" });
                    }}
                >
                    <path
                        d={pathData}
                        fill={colors[index]}
                        stroke={"#ffffff"}
                        strokeWidth={0.5}
                        strokeLinejoin={'round'}
                        strokeLinecap={"round"}
                    />

                    <path
                        d={linePathData}
                        fill="none"
                        stroke={colors[index]}
                        strokeWidth={1}
                    />
                    <text
                        x={labelX}
                        y={labelY}
                        textAnchor={textAnchor}
                        dominantBaseline="middle"
                        alignmentBaseline="middle"
                        fontSize={scheduleFullScreen ? 12 : 8}
                    >
                        {label}
                    </text>
                </g>
            );
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, outerRadius, innerRadius, totalValue, scheduleFullScreen, colors, sliceRefs]);

    useEffect(() => {
        if (legends.current) {
            const isOverflowing = legends.current.scrollWidth > legends.current.clientWidth;

            if (isOverflowing) {
                legends.current.style.justifyContent = "flex-start";
            } else {
                legends.current.style.justifyContent = "center";
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, outerRadius, innerRadius, totalValue]);

    useEffect(() => {
        // Handler for fullscreen change event
        const handleFullscreenChange = () => {
            setScheduleFullScreen(document.fullscreenElement === scheduleFullScreenRef.current);
        };

        // Add event listeners for fullscreen change
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        // Clean up event listeners on component unmount
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        if (scheduleFullScreen) {
            setPieChartDropDown((prevValue: OptionType[]) => {
                return prevValue.map((option, index) => (
                    index === 0
                        ? { ...option, label: "Exit FullScreen" }
                        : option
                ))
            });
        } else {
            setPieChartDropDown((prevValue: OptionType[]) => {
                return prevValue.map((option, index) => (
                    index === 0
                        ? { ...option, label: "View in FullScreen" }
                        : option
                ))
            });
        }

    }, [scheduleFullScreen])

    const printContentRef = useRef<HTMLDivElement | null>(null);

    const hideDropdown = (value: number) => {
        switch (value) {
            case 1:
                if (scheduleFullScreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                } else {
                    const element = scheduleFullScreenRef.current;
                    if (element) {
                        element.requestFullscreen();
                    }
                }
                break;
            case 2:
                if (!printContentRef.current) return;
                const printContent = printContentRef.current;

                if (printContent) {
                    const printWindow = window.open('', '', 'height=500, width=500');
                    if (printWindow) {
                        printWindow.document.write(`<html><head><title>${appName}</title></head><body>`);
                        printWindow.document.write(printContent.innerHTML);
                        printWindow.document.write('</body></html>');
                        printWindow.document.close();

                        printWindow.print();

                        if (printWindow.closed) {
                            const element = scheduleFullScreenRef.current;
                            if (element) {
                                element.requestFullscreen();
                            }
                        }
                    }
                }
                break;
        }

        setDropDownVisible(false);
    }

    const handleLegendEnter = (index: number, name: string) => {
        ref.current?.classList.add(styles.hasHighlight);

        const slice = sliceRefs.current[index];
        if (slice) {
            if (!slice.classList.contains(styles.sliceHover)) {
                slice.classList.add(styles.sliceHover);
            }
            
            const rect = slice.getBoundingClientRect();          
    
            setTooltip({
                visible: true,
                x:  scheduleFullScreen ? rect.left : rect.left + window.scrollX,
                y: scheduleFullScreen ? rect.top : rect.top + window.scrollY,
                text: name
            });
        }
    };
    
    const handleLegendMove = (index: number) => {
        const slice = sliceRefs.current[index];
        if (slice) {           
            const rect = slice.getBoundingClientRect();          
    
            setTooltip((prevTooltip) => ({
                ...prevTooltip,
                x:  scheduleFullScreen ? rect.left : rect.left + window.scrollX,
                y: scheduleFullScreen ? rect.top : rect.top + window.scrollY          
            }));
        }
    };

    const handleLegendLeave = (index: number) => {
        ref.current?.classList.remove(styles.hasHighlight);

        const slice = sliceRefs.current[index];
        if (slice) {
            if (slice.classList.contains(styles.sliceHover)) {
                slice.classList.remove(styles.sliceHover);
            }

            setTooltip({ visible: false, x: 0, y: 0, text: "" });
        }
    };


    return (
        <div
            ref={scheduleFullScreenRef}
            style={{
                background: "#ECEFF1",
                width: "100%"
            }}
        >
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "0.5rem"
                }}
            >
                <h5
                    style={{
                        flex: "1 1 0%",
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontWeight: 600
                    }}
                >{title}</h5>

                <div
                    ref={dropDownVisibleRef}
                    style={{
                        width: "fit-content",
                    }}
                >
                    <button
                        style={{
                            padding: "0.125rem 0.5rem",
                            cursor: "pointer",
                            marginTop: "0.5rem",
                            border: "none"
                        }}
                        onClick={() => setDropDownVisible(!dropDownVisible)}
                    >
                        <MenuIcon sx={{ fontSize: 22 }} />
                    </button>

                    <div
                        style={{
                            position: "absolute",
                            zIndex: 10000,
                            top: 0,
                            right: 16,
                            marginTop: "2rem",
                            background: "#ffffff",
                            border: "1px solid rgb(0 0 0 / 0.3)",
                            display: dropDownVisible ? "flex" : "none",
                            flexDirection: "column",
                            overflow: "auto",
                            whiteSpace: "nowrap",
                            minWidth: "14rem",
                            width: "fit-content",
                            height: "fit-content",
                            maxHeight: "13rem"
                        }}
                    >
                        {pieChartDropDown?.map((item: OptionType, index: number) => (
                            <span
                                key={index}
                                style={{
                                    padding: "0.125rem 0.5rem",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    margin: "0"
                                }}
                                onClick={() => {
                                    hideDropdown(item.value);
                                }}
                                className={styles.dropdownItem}
                            >
                                {item.label}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            <div ref={printContentRef}>
                <svg width={scheduleFullScreen ? window.innerWidth : width} height={scheduleFullScreen ? window.innerHeight : height} style={{ display: "inline-block" }}  >
                    {!data?.every((item: any) => item?.value <= 0) ? (
                        <g
                            transform={`translate(${scheduleFullScreen ? window.innerWidth / 2 : width / 2}, ${scheduleFullScreen ? (window.innerHeight / 2) - MARGIN_Y : height / 2})`}
                            className={styles.container}
                            ref={ref}
                        >
                            {shapes}
                        </g>
                    ) : (
                        <circle
                            cx={scheduleFullScreen ? window.innerWidth / 2 : width / 2}
                            cy={scheduleFullScreen ? window.innerHeight / 2 : height / 2}
                            r={radius}
                            fill="#fecaca"
                            stroke={"#000000"}
                            strokeWidth={1}
                        />
                    )}
                </svg>

                {tooltip.visible && (
                    <div
                        style={{
                            position: "absolute",
                            left: tooltip.x,
                            top: tooltip.y,
                            transform: `translate(-50%, -50%)`
                        }}
                        className={styles.tooltip}
                    >
                        <div className={styles.tooltipContent}>{tooltip.text}</div>
                    </div>
                )}
            </div>

            <div
                ref={legends}
                style={{
                    width: scheduleFullScreen ? window.innerWidth : width,
                    height: "2rem",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    borderTop: "1px solid rgb(0 0 0 / 0.3)",
                    paddingInline: "0.5rem"
                }}
                className={styles.piechart}
            >
                {
                    handleSmallSlices(data, (thresholdForSlice / 100) * totalValue)?.map((item: any, index: number) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                textWrap: "nowrap",
                                gap: "0.25rem",
                                alignItems: "center",
                                cursor: "pointer"
                            }}
                            className={styles.legendItem}
                            onMouseEnter={() => handleLegendEnter(index, item?.name)}
                            onMouseMove={() => handleLegendMove(index)}
                            onMouseLeave={() => handleLegendLeave(index)}
                        >
                            <div
                                style={{
                                    backgroundColor: colors[index],
                                    width: "0.625rem",
                                    height: "0.625rem",
                                    borderRadius: "100%"
                                }}
                            ></div>
                            <span
                                style={{
                                    fontSize: "0.75rem",
                                    fontWeight: 500
                                }}
                            >{item?.name}</span>
                        </div>
                    ))
                }

                {
                    handleSmallSlices(data, (thresholdForSlice / 100) * totalValue)?.length <= 0
                    && (
                        <div
                            style={{
                                display: "flex",
                                textWrap: "nowrap",
                                gap: "0.25rem",
                                alignItems: "center",
                                cursor: "pointer"
                            }}
                            onMouseEnter={() => handleLegendEnter(0, "No Data")}
                            onMouseMove={() => handleLegendMove(0)}
                            onMouseLeave={() => handleLegendLeave(0)}
                        >
                            <div
                                style={{
                                    backgroundColor: "#fecaca",
                                    width: "0.625rem",
                                    height: "0.625rem",
                                    borderRadius: "100%"
                                }}
                            ></div>
                            <span
                                style={{
                                    fontSize: "0.75rem",
                                    fontWeight: 500,
                                    textAlign: "center"
                                }}
                            >No Data</span>
                        </div>
                    )
                }
            </div>
        </div>
    );
};
