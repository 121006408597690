import { Box, Button, Stack } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { appName } from "../../../configs/api";
import Cookies from "js-cookie";
import { getIncidentsReportByAppGroupIdAndByAppNameAndSearchWithIncidentName, getIncidentsReportByAppGroupIdAndByAppNameWithStatusAndSearchWithIncidentName } from "../../../services/Tickets";
import { format } from "date-fns";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: "100%",
    paddingBottom: "0px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    paddingBottom: "0px",
}));

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "#",
        width: 90,
        editable: false,
        sortable: false,
    },
    {
        field: "incidentName",
        headerName: "Subject",
        width: 400,
        editable: false,
        sortable: false,
        renderCell: (params) => {
            return (
                <Link to={`/support/edit-support-ticket/${params.id}`}>
                    {params.value}
                </Link>
            );
        }
    },
    {
        field: "assignedToUserName",
        headerName: "Assigned Agent",
        width: 200,
        editable: false,
        sortable: false,
    },
    {
        field: "createdAt",
        headerName: "Created Date",
        width: 200,
        editable: false,
        sortable: false,
    },
    {
        field: "status",
        headerName: "Status",
        width: 200,
        editable: false,
        sortable: false,
        renderCell: (params) => {
            const status = params.value;
            let statusStyle = {
                padding: "4px 12px",
                borderRadius: "12px",
                color: "white",
                backgroundColor: "#cccccc",
            };

            if (status === "New") {
                statusStyle = { ...statusStyle, backgroundColor: "#f97316" };
            } else if (status === "Resolved") {
                statusStyle = { ...statusStyle, backgroundColor: "#22c55e" };
            } else if (status === "Assigned") {
                statusStyle = { ...statusStyle, backgroundColor: "#3b82f6" };
            } else if (status === "Pending Assignment") {
                statusStyle = { ...statusStyle, backgroundColor: "#facc15" };
            } else if (status === "Closed") {
                statusStyle = { ...statusStyle, backgroundColor: "#6b7280" };
            } else if (status === "Awaiting Input") {
                statusStyle = { ...statusStyle, backgroundColor: "#9333ea" };
            } else if (status === "On Hold") {
                statusStyle = { ...statusStyle, backgroundColor: "#4f46e5" };
            }

            return (
                <span style={statusStyle}>
                    {status}
                </span>
            );
        }
    },
];

type Props = {
    statusId: any;
    paginationModel: any;
    setPaginationModel: (value: any) => void;
    totalLength: number;
    setSearchTableData: (value: any[]) => void;
    searchTableData: any[];
    setIsDataLoaded: (value: boolean) => void;
    isDataLoaded: boolean;
    searchValue: string;
    setSearchValue: (value: string) => void;
    oldPage: number;
    setOldPage: (value: number) => void;
    setTotalLength: (value: number) => void;
};

export const AllSupportTickets = ({
    statusId,
    paginationModel,
    setPaginationModel,
    totalLength,
    setSearchTableData,
    searchTableData,
    setIsDataLoaded,
    searchValue,
    setSearchValue,
    isDataLoaded,
    oldPage,
    setOldPage,
    setTotalLength }: Props) => {
    const appGroupId = Cookies.get("groupId-jj-rental-ui") || "";
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [subjectFilterValue, setSubjectFilterValue] = useState(0);
    const [operatorFilter, setOpertorFilter] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState<string>("Subject");

    useEffect(() => {
        setSubjectFilterValue(0);
        setSelectedFilter("Subject");
    }, [statusId])

    useEffect(() => {

        let timeOutId: NodeJS.Timeout;

        if (searchValue !== "") {
            timeOutId = setTimeout(() => {
                const newPaginationModel = {
                    page: 0,
                    pageSize: paginationModel.pageSize,
                };

                setPaginationModel(newPaginationModel);

            }, 1000);

        }

        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue !== "") {
            apiCall(paginationModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const apiCall = (newPaginationModel: any) => {
        setIsDataLoaded(true);
        const fetchData =
            statusId === 0
                ? getIncidentsReportByAppGroupIdAndByAppNameAndSearchWithIncidentName(searchValue, appName, appGroupId, newPaginationModel.page, newPaginationModel.pageSize)
                : getIncidentsReportByAppGroupIdAndByAppNameWithStatusAndSearchWithIncidentName(searchValue, statusId, appName, appGroupId, newPaginationModel.page, newPaginationModel.pageSize);

        fetchData
            .then((data: any) => {
                const filterData = data?.incidentReports.map((item: any) => ({
                    ...item,
                    createdAt: item?.createdAt ? format(new Date(item?.createdAt), "MM/dd/yyyy hh:mm a") : "No date",
                }));

                let newArray = [];
                if (newPaginationModel.page === 0) {
                    newArray = filterData;
                } else if (newPaginationModel.page > oldPage) {
                    newArray = searchTableData.concat(filterData);
                } else {
                    newArray = searchTableData;
                }

                setSearchTableData(newArray);
                setTotalLength(data.count || 0);

                if (newPaginationModel.page === 0) {
                    setOldPage(-1);
                } else {
                    setOldPage(newPaginationModel.page - 1);
                }
            })
            .catch((error) => {
                console.error(error);
                setSearchTableData([]);
                setTotalLength(0);
            })
            .finally(() => {
                setIsDataLoaded(false);
            });
    };

    const applyFilter = () => {
        setSelectedFilter("Subject");
        setSearchValue("");
        setDisplayFilter(false);
    };

    const clearFilter = () => {
        setSearchValue("");
        setDisplayFilter(false);
    };

    return (
        <>
            <Box sx={{ padding: "24px" }}>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ border: "1px solid black", alignItems: "center" }}
                >
                    <div style={{ position: "relative", width: "150px" }}>
                        <Button
                            variant="contained"
                            endIcon={<ArrowDropDownIcon sx={{ color: "#bdbdbd" }} />}
                            sx={{
                                color: "black",
                                backgroundColor: "#e0dee0",
                                textTransform: "unset",
                                fontSize: "12px",
                                border: "none",
                                borderRadius: "0px",

                                "&: hover": {
                                    backgroundColor: "white",
                                },
                            }}
                            onClick={() => setDisplayFilter(() => !displayFilter)}
                        >
                            {selectedFilter}
                        </Button>

                        <div
                            style={{
                                display: displayFilter ? "block" : "none",
                                width: "170px",
                                position: "absolute",
                                zIndex: "100",
                                top: "-100%",
                                left: "100%",
                                background: "white",
                                border: "1px solid black",
                                padding: "8px",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <label
                                        htmlFor="fields"
                                        style={{ fontSize: "10px", fontWeight: "bold" }}
                                    >
                                        Fields
                                        <br />
                                    </label>

                                    <CloseIcon
                                        sx={{
                                            width: "18px",
                                            fontSize: "10px",
                                            cursor: "pointer",
                                            background: "#e0e0e0",
                                        }}
                                        onClick={() => setDisplayFilter(false)}
                                    />
                                </div>

                                <select
                                    name="fields"
                                    style={{
                                        width: "100%",
                                        fontSize: "10px",
                                        paddingBlock: "3px",
                                    }}
                                    value={subjectFilterValue}
                                    onChange={(e) =>
                                        setSubjectFilterValue(parseInt(e.target.value))
                                    }
                                >
                                    <option value="0">Subject</option>
                                </select>
                            </div>

                            <div>
                                <label
                                    htmlFor="operator"
                                    style={{ fontSize: "10px", fontWeight: "bold" }}
                                >
                                    Operator
                                    <br />
                                    <select
                                        name="operator"
                                        style={{
                                            width: "100%",
                                            fontSize: "10px",
                                            paddingBlock: "3px",
                                        }}
                                        value={operatorFilter}
                                        onChange={(e) => setOpertorFilter(parseInt(e.target.value))}
                                    >
                                        <option value="0">In a list</option>
                                    </select>
                                </label>
                            </div>


                            <div
                                style={{
                                    display: "flex",

                                    marginTop: "7px",
                                    justifyContent: "space-between",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <button
                                    className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                                    onClick={() => applyFilter()}
                                >
                                    Apply Filter
                                </button>

                                <button
                                    className="p-1 px-3 rounded text-white text-xs font-semibold border-none outline-none bg-secondary hover:opacity-80"
                                    onClick={() => clearFilter()}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>

                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ "aria-label": "search" }}
                            value={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                    </Search>
                </Stack>


                <Box sx={{ height: "100%", width: "100%", marginTop: "16px" }}>
                    {!isDataLoaded ? searchTableData.length > 0 ? (
                        <DataGrid
                            rows={searchTableData}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                            rowCount={totalLength}
                            paginationModel={paginationModel}
                            onPaginationModelChange={(model: GridPaginationModel) => {
                                if (model.pageSize !== paginationModel.pageSize) {
                                    setPaginationModel({
                                        page: 0,
                                        pageSize: model.pageSize,
                                    });
                                    return;
                                }

                                setPaginationModel({
                                    page: model.page,
                                    pageSize: model.pageSize,
                                });
                            }}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            showCellVerticalBorder={false}
                            sx={{
                                border: "none",
                                fontSize: "12px",
                                wordWrap: "break-word",
                                overflow: "visible",
                            }}
                        />
                    ) : (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <p>No Data Found</p>
                        </div>
                    ) : (
                        <div className="loading">
                            <p>Please wait</p>
                            <span>
                                <i></i>
                                <i></i>
                            </span>
                        </div>
                    )}
                </Box>
            </Box>
        </>
    );
};
