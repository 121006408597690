import axios from "axios"
import baseURL from "../configs/api"

export const post_comment = (data: any) => {
    return axios({
        url: `${baseURL}comment`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_comment = (data: any) => {
    return axios({
        url: `${baseURL}comment`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_comment = (primaryKey: number) => {
    return axios({
        url: `${baseURL}comment`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getComments = () => {
    return getFetch("comment");
}

export const getComment = (primaryKey: string) => {
    return getFetch(`comment/${primaryKey}`);
}

export const getCommentsOfAttribute = (attributeName: string, primaryKey: string) => {
    return getFetch(`comment/${attributeName},${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}