import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import { format } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Webcam from "react-webcam";
import axois from "../../../configs/axois";
import { useNavigate } from "react-router";
import useAuth from "../../../components/hooks/useAuth";
import { handleCapture } from "../../../utils/covertTheBase64FormatIntoImage";

type Props = {
  goToNextPage: (page: number) => void;
  paymentList: any[];
  reservation: any;
  reservationUpdate: () => void;
  isCompletedOrCancelled: boolean;
};

const fuelLevelOptions: any = [];
for (let i = 0; i <= 8; i++) {
  fuelLevelOptions.push(<option key={i} value={i}>{i}</option>);
}

export default function Checkin({
  goToNextPage,
  paymentList,
  reservation,
  reservationUpdate,
  isCompletedOrCancelled
}: Props) {
  const navigate = useNavigate();
  const { auth }: any = useAuth();

  const [isReturnInformation, setIsReturnInformation] = useState<boolean>(true);
  const [isPayments, setIsPayments] = useState<boolean>(true);
  const [returnLocationOptions, setReturnLocationOptions] = useState([]);
  const [isReturnLocationUpdated, setIsReturnLocationUpdated] =
    useState<boolean>(false);
  const [isConfrimCheckIn, setIsConfrimCheckIn] = useState<boolean>(false);

  const [processPaymentBox, setProcessPaymentBox] = useState<boolean>(false);
  const [processSecurityDeposit, setProcessSecurityDeposit] =
    useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [paymentCheck, setPaymentCheck] = useState<boolean>(false);

  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<any[]>([]);
  const [newFilePath, setNewFilePath] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [isImageSaved, setIsImageSaved] = useState<boolean>(false);
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  const [vehicleId, setVehicleId] = useState<string>("");
  const [fuelLevelAtCheckIn, setFuelLevelAtCheckIn] = useState<string>("0");
  const [odometerAtCheckIn, setOdometerAtCheckIn] = useState<string>("");
  const [odometerShowSpan, setOdometerShowSpan] = useState<boolean>(false);

  const currentDate = new Date();
  const [returnDates, setReturnDates] = useState<string>(
    currentDate.toISOString().split("T")[0]
  );
  const [returnTimes, setReturnTimes] = useState<string>(
    format(currentDate, "kk:mm")
  );
  const [returnLocations, setReturnLocations] = useState<string>("");
  const [vehicles, setVehicles] = useState<any[]>([]);

  const [isChargeFuelToClient, setIsChargeFuelToClient] = useState<boolean>(false);
  const [recalculateRatesOnLateReturn, setRecalculateRatesOnLateReturn] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${baseURL}location`)
      .then((response) => {
        setReturnLocationOptions(() =>
          response.data
            .filter(
              (data: any) => data.branchId === parseInt(reservation?.branchId)
            )
            .map((item: any) => {
              return {
                value: item.id,
                label: item.locationName,
                branchId: item.branchId,
              };
            })
        );

        setIsReturnLocationUpdated(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [reservation]);

  useEffect(() => {
    axios
      .get(`${baseURL}vehicles`)
      .then((response) => {
        setVehicles(() => response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (parseInt(odometerAtCheckIn) < Number(reservation?.odometerAtCheckOut)) {
      setOdometerShowSpan(true);
    } else {
      setOdometerShowSpan(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odometerAtCheckIn]);

  useEffect(() => {
    if (reservation && isReturnLocationUpdated) {
      setVehicleId(reservation.vehicleId);
      setOdometerAtCheckIn(reservation.odometerAtCheckIn);
      setFuelLevelAtCheckIn(reservation.fuelLevelAtCheckIn);
      setReturnLocations(reservation.returenLoationId);
    }
  }, [reservation, isReturnLocationUpdated]);

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isCompletedOrCancelled) {
      setPleaseWaitPopup(true);
      const selectedFiles = e.target.files as FileList;

      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        formData.append("ImageFile", selectedFiles[0]);

        axios
          .post(`${baseURL}reservationFile/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((response) => {
            const newFilePath: any = {
              id: 0,
              fileName:
                `${baseURL}reservationFile/Get?name=` +
                response.data,
            };

            setNewFilePath((prevPath: string[]) => [
              ...prevPath,
              newFilePath.fileName,
            ]);
            setFilePath((prevPath: any[]) => [...prevPath, newFilePath]);
            setPleaseWaitPopup(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        setPleaseWaitPopup(false);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const dateTimeCheckOut = reservation?.dateTimeCheckOut;
    const checkOutDate = new Date(dateTimeCheckOut).toISOString();

    const returnDateTime = new Date(`${returnDates}T${returnTimes}`).toISOString();

    if (!dateTimeCheckOut) {
      window.alert("Checkout date is invalid.");
      return;
    }

    if (returnDateTime < checkOutDate) {
      window.alert("Return date and time cannot be earlier than or the same as check-out date.");
      return;
    }

    if (odometerAtCheckIn !== undefined && reservation?.odometerAtCheckOut !== undefined) {
      if (odometerAtCheckIn < reservation?.odometerAtCheckOut) {
        window.alert("Odometer reading at check-in cannot be less than check-out reading.");
        return;
      }
    } else {
      window.alert("Odometer readings are missing.");
      return;
    }

    if (!isCompletedOrCancelled) {
      if (isConfrimCheckIn) {
        setPleaseWaitPopup(true);

        if (newFilePath.length !== 0) {
          newFilePath.forEach((item: string, index: number) => {
            axios
              .post(
                `${baseURL}fileUploadData`,
                JSON.stringify({
                  attributeName: "Reservation",
                  attributeId: reservation?.id,
                  fileName: item?.replace(
                    `${baseURL}reservationFile/Get?name=`,
                    ""
                  ),
                  tabName: "Checkin",
                  userId: auth?.id ?? 0,
                  createdDate: new Date(),
                }),
                {
                  headers: { "Content-Type": "application/json" },
                  withCredentials: true,
                }
              )
              .then(() => {
                if (newFilePath.length - 1 === index) {
                  setIsImageSaved(true);
                }
              })
              .catch((error) => {
                const errorMessage = error?.response?.data?.message || error?.message || "File upload failed.";
                window.alert(errorMessage);
              });
          });
        } else {
          setIsImageSaved(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!isCompletedOrCancelled) {
      if (isImageSaved) {
        axios
          .put(
            `${baseURL}reservation`,
            {
              ...reservation,
              dateTimeCheckIn: returnDates + "T" + returnTimes,
              fuelLevelAtCheckIn,
              odometerAtCheckIn,
              status: "Completed",
              completedBy: auth.givenname,
              completedAt: new Date()
            },
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            setIsImageSaved(false);
            setPleaseWaitPopup(false);
            reservationUpdate();
            setNewFilePath([]);
            goToNextPage(9);
          })
          .catch((error) => {
            setIsImageSaved(false);
            setPleaseWaitPopup(false);
            const errorMessage = error?.response?.data?.message ||
              error?.message || "An error occurred while completing the reservation.";
            window.alert(errorMessage);

          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImageSaved]);

  useEffect(() => {
    if (reservation) {
      axois
        .get(`${baseURL}fileUploadData/Reservation/${reservation.id}`)
        .then((response) => {
          setFilePath(() =>
            response.data
              .filter((data: any) => data.tabName === "Checkin")
              .map((item: any) => {
                return {
                  id: item.id,
                  fileName: item.fileName.startsWith("data:image/png;base64,")
                    ? item.fileName
                    : `${baseURL}reservationFile/Get?name=` +
                    item.fileName,
                };
              })
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reservation]);

  const changeStatusToOpen = async () => {
    if (
      window.confirm("Are you sure you want to change status to Open?") === true
    ) {
      await axios
        .put(
          `${baseURL}reservation`,
          {
            ...reservation,
            status: "Open",
            reopenedBy: auth?.givenname,
            reopenedAt: new Date()
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          reservationUpdate();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "24px",
        }}
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <h5
            style={{
              paddingBottom: "0px",
              marginBlock: "0px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              cursor: "pointer",
              WebkitUserSelect: "none" /* Safari */,
              msUserSelect: "none" /* IE 10 and IE 11 */,
              userSelect: "none" /* Standard syntax */,
            }}
            onClick={() => setIsReturnInformation(!isReturnInformation)}
          >
            {isReturnInformation ? (
              <ExpandMoreIcon sx={{ fontSize: "20px" }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
            )}{" "}
            Return Information
          </h5>
          <hr />

          <div>
            {isReturnInformation && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginBlock: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Vehicle Link
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginTop: "0px",
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() =>
                          navigate("/fleet/vehicle/editvehicles", {
                            state: vehicleId,
                          })
                        }
                      >
                        {
                          vehicles.find((item: any) => item.id === parseInt(vehicleId))
                            ?.name
                        }
                      </p>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Fuel level at Pickup
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginTop: "0px",
                        }}
                      >
                        {reservation ? reservation.fuelLevelAtCheckOut : "0"}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                        >
                          Fuel level at Return
                        </p>

                        <select
                          name="vin"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "3px",
                          }}
                          value={fuelLevelAtCheckIn}
                          onChange={(e) =>
                            setFuelLevelAtCheckIn(e.target.value)
                          }
                          required
                        >
                          {fuelLevelOptions}
                        </select>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            fontWeight: "bold",
                            marginBottom: "0px",
                            marginLeft: "8px",
                          }}
                        >
                          Charge fuel to client?
                        </p>
                        <div>
                          <label
                            htmlFor="isChargeFuelToClient"
                            style={{ fontSize: "12px", marginLeft: "8px" }}
                          >
                            <input
                              type="radio"
                              value="Yes"
                              name="isChargeFuelToClient"
                              style={{ fontSize: "12px" }}
                              checked={isChargeFuelToClient ? true : false}
                              onChange={(e) =>
                                setIsChargeFuelToClient(e.target.value === "Yes" ? true : false)
                              }
                            />{" "}
                            Yes
                          </label>
                          <label style={{ fontSize: "12px" }}>
                            <input
                              type="radio"
                              value="No"
                              name="walkInCuisChargeFuelToClientstomer"
                              style={{ fontSize: "12px" }}
                              checked={isChargeFuelToClient ? false : true}
                              onChange={(e) =>
                                setIsChargeFuelToClient(e.target.value === "Yes" ? true : false)
                              }
                            />{" "}
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Return Date
                      </p>
                      <input
                        type="date"
                        name="vin"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={returnDates}
                        onChange={(e) => setReturnDates(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginBlock: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Return Information
                      </p>
                      <select
                        name="status"
                        id="cars"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "3px",
                        }}
                        value={returnLocations}
                        onChange={(e) => setReturnLocations(e.target.value)}
                        required
                      >
                        {returnLocationOptions.map((item: any) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Odometer at Pickup
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginBlock: "0px",
                        }}
                      >
                        {reservation ? reservation.odometerAtCheckOut : "0000"}
                      </p>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "start",
                          marginBottom: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Odometer at Return{" "}
                        <span
                          style={{
                            display: odometerShowSpan ? "inline-block" : "none",
                            color: "red",
                            paddingLeft: "5px",
                          }}
                        >
                          *Must be getter than or equal to Odometer at Pickup
                        </span>
                      </p>

                      <input
                        type="number"
                        min={reservation ? reservation.odometerAtCheckOut : 0}
                        name="vehicle"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "3px",
                        }}
                        value={odometerAtCheckIn}
                        onChange={(e) => setOdometerAtCheckIn(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%", marginRight: "3%" }}>
                        <label
                          htmlFor="year"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Return Time
                          <br />
                          <input
                            type="time"
                            name="year"
                            style={{
                              width: "100%",
                              fontSize: "14px",
                              paddingBlock: "3px",
                            }}
                            value={returnTimes}
                            onChange={(e) => setReturnTimes(e.target.value)}
                            required
                          />
                        </label>
                      </div>

                      <div style={{ width: "50%" }}>
                        <label
                          htmlFor="recalculateRatesOnLateReturn"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Recalculate rates on late Pickup?
                          <br />
                          <div>
                            <label style={{ fontSize: "12px" }}>
                              <input
                                type="radio"
                                value="Yes"
                                name="recalculateRatesOnLateReturn"
                                style={{ fontSize: "12px" }}
                                checked={recalculateRatesOnLateReturn ? true : false}
                                onChange={(e) =>
                                  setRecalculateRatesOnLateReturn(e.target.value === "Yes" ? true : false)
                                }
                              />{" "}
                              Yes
                            </label>
                            <label style={{ fontSize: "12px" }}>
                              <input
                                type="radio"
                                value="No"
                                name="recalculateRatesOnLateReturn"
                                style={{ fontSize: "12px" }}
                                checked={recalculateRatesOnLateReturn ? false : true}
                                onChange={(e) =>
                                  setRecalculateRatesOnLateReturn(e.target.value === "Yes" ? true : false)
                                }
                              />{" "}
                              No
                            </label>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="image"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Images
                    <br />
                    <div
                      style={{
                        maxWidth: "50%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        flexWrap: "wrap"
                      }}
                    >
                      {filePath.map((item: any, outerIndex: number) => {
                        return (
                          <div key={outerIndex}>
                            <img
                              src={item.fileName}
                              width={40}
                              height={40}
                              alt="repairOrder"
                              onClick={() => {
                                setFileIndex(outerIndex);
                                setImagePopup(true);
                              }}
                            />
                            <RemoveCircleIcon
                              sx={{
                                fontSize: "14px",
                                color: colorConfigs.topbar.bg,
                                fontWeight: "bold",
                                "&: hover": {
                                  color: "red",
                                },
                              }}
                              onClick={() => {
                                if (!isCompletedOrCancelled) {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this image?"
                                    )
                                  ) {
                                    let newFileSet: any[] = filePath.filter(
                                      (_, index: number) => index !== outerIndex
                                    );

                                    if (filePath[outerIndex].id === 0) {
                                      setFilePath(() => newFileSet);
                                    } else {
                                      axios
                                        .delete(
                                          `${baseURL}fileUploadData?id=${filePath[outerIndex].id}`
                                        )
                                        .then((response) => {
                                          setFilePath(() => newFileSet);
                                        })
                                        .catch((error) => {
                                          console.error("Error:", error);
                                        });
                                    }
                                  }
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputFileRef}
                      onChangeCapture={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => {
                          if (inputFileRef.current !== null) {
                            inputFileRef.current.click();
                          }
                        }}
                      >
                        {" "}
                        <AddIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                        Add Image
                      </p>

                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={() => {
                          if (!isCompletedOrCancelled)
                            setShowWebcam(true)
                        }
                        }
                      >
                        {" "}
                        <LocalSeeIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                      </p>
                    </div>
                  </label>
                </div>

                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <button
                    style={{
                      marginBlock: "5px",
                      paddingBlock: "5px",
                      backgroundColor: "#66BB6A",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                    }}
                    type="submit"
                    onClick={() => {

                      if (!isCompletedOrCancelled)
                        setIsConfrimCheckIn(true)
                    }}
                  >
                    Confirm Check In
                  </button>

                  <button
                    style={{
                      marginBlock: "5px",
                      paddingBlock: "5px",
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid gray",
                      cursor: "pointer",
                    }}

                  >
                    Recalculate Changes
                  </button>
                </div>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
                WebkitUserSelect: "none" /* Safari */,
                msUserSelect: "none" /* IE 10 and IE 11 */,
                userSelect: "none" /* Standard syntax */,
              }}
              onClick={() => {
                if (!isCompletedOrCancelled)
                  setIsPayments(!isPayments)
              }
              }
            >
              {isPayments ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Payments
            </h5>
            <hr />

            {isPayments && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "8%",
                      marginBottom: "0px",
                    }}
                  >
                    #
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    Payment Type
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "12%",
                      marginBottom: "0px",
                    }}
                  >
                    Payment Method
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    Date
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    Amount
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    Reference
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    Payment Status
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "10%",
                      marginBottom: "0px",
                    }}
                  >
                    Created at
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "20%",
                      marginBottom: "0px",
                    }}
                  >
                    Synced with Accounting?
                  </p>
                </div>
                <hr style={{ marginBlock: "0px" }} />

                {paymentList.map((item: any) => {
                  return (
                    <div key={item.id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "8%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.id}
                        </p>

                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "10%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.paymentType}
                        </p>

                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "12%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.paymentMethod}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "10%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.dateofPayment
                            ? format(
                              new Date(
                                item.dateofPayment
                                  .replaceAll("-", "/")
                                  .split("T")[0]
                              ),
                              "MM/dd/yyyy "
                            )
                            : ""}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "10%",
                            marginBottom: "0px",
                          }}
                        >
                          ${parseFloat(item.amount).toFixed(2)}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "10%",
                            marginBottom: "0px",
                          }}
                        >
                          {item?.reference}
                        </p>

                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "10%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.status}
                        </p>

                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "10%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.createdAt
                            ? format(
                              new Date(
                                item.createdAt
                                  .replaceAll("-", "/")
                                  .split("T")[0]
                              ),
                              "MM/dd/yyyy "
                            )
                            : ""}
                        </p>

                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "10%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.syncedWithAccounting ? "Yes" : "No"}
                        </p>
                      </div>
                      <hr style={{ marginBlock: "0px" }} />
                    </div>
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                  }}
                >
                  <button
                    style={{
                      marginBlock: "16px",
                      paddingBlock: "3px",
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                      border: "none",
                      padding: "8px",
                      cursor: "pointer",
                    }}

                  >
                    Add Offline Payment
                  </button>

                  <button
                    style={{
                      marginBlock: "16px",
                      paddingBlock: "3px",
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                      border: "none",
                      padding: "8px",
                      cursor: "pointer",
                    }}

                  >
                    Pay $0.00 with Authorize
                  </button>
                </div>

                <button
                  style={{
                    paddingBlock: "3px",
                    backgroundColor: "#f0ad4e",
                    color: "white",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}

                >
                  Refund Security Deposit
                </button>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                  }}
                >
                  <button
                    style={{
                      marginBlock: "16px",
                      paddingBlock: "3px",
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid black",
                      padding: "8px",
                      cursor: "pointer",
                    }}

                  >
                    Process Payment
                  </button>

                  <button
                    style={{
                      marginBlock: "16px",
                      paddingBlock: "3px",
                      backgroundColor: "#66BB6A",
                      color: "white",
                      border: "none",
                      padding: "8px",
                      cursor: "pointer",
                    }}

                  >
                    Request Payment
                  </button>
                </div>

                <button
                  style={{
                    paddingBlock: "3px",
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsConfrimCheckIn(false);
                    changeStatusToOpen();
                  }}
                >
                  Re-open Reservation
                </button>
              </>
            )}
          </div>
        </form>
      </div>

      <Dialog
        open={processPaymentBox}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "40%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              {processSecurityDeposit
                ? "Authorize Security Deposit"
                : "Charge Customer"}
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                if (!isCompletedOrCancelled) {
                  setProcessPaymentBox(false);
                  setProcessSecurityDeposit(false);
                }
              }}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {!processSecurityDeposit && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="amount"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Amount
                    <br />
                    <input
                      type="text"
                      name="amount"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="description"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Description
                    <br />
                    <input
                      type="text"
                      name="description"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            )}

            <p
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                fontSize: "12px",
                marginBottom: "0px",
              }}
            >
              Payment
            </p>

            <hr style={{ marginLeft: "16px", marginRight: "16px" }} />

            <div
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <label htmlFor="payment" style={{ fontSize: "12px" }}>
                <input
                  type="checkbox"
                  value="Yes"
                  name="payment"
                  style={{ fontSize: "12px", textAlign: "center" }}
                  defaultChecked={paymentCheck}
                  onChange={(e) =>
                    setPaymentCheck((paymentCheck) => !paymentCheck)
                  }
                />{" "}
                <i
                  style={{ fontSize: "26px", color: "blue" }}
                  className="bx bxl-visa"
                ></i>
                <i
                  style={{ fontSize: "26px", marginLeft: "5px" }}
                  className="bx bxl-mastercard"
                ></i>
              </label>
            </div>

            {paymentCheck && (
              <>
                <div
                  style={{
                    marginLeft: "16px",
                    marginRight: "16px",
                    marginBlock: "16px",
                    paddingBottom: "16px",
                    border: "1px solid gray",
                  }}
                >
                  <p
                    style={{
                      backgroundColor: "#eeeeee",
                      padding: "8px 5px",
                      marginBlock: "0px",
                    }}
                  >
                    Authorization Details - <strong>USD</strong>
                  </p>
                  <hr style={{ marginTop: "0px" }} />

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  >
                    <div style={{ width: "75%" }}>
                      <label
                        htmlFor="amount"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Credit Card Number
                        <br />
                        <input
                          type="text"
                          name="amount"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                      </label>

                      <label
                        htmlFor="amount"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Cardholder Name
                        <br />
                        <input
                          type="text"
                          name="amount"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ width: "25%" }}>
                      <label
                        htmlFor="description"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Expiration Date
                        <br />
                        <input
                          type="text"
                          placeholder="MM/YY"
                          name="description"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        />
                      </label>

                      <label
                        htmlFor="description"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        CCV Code
                        <br />
                        <input
                          type="text"
                          placeholder="CV"
                          name="description"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: "16px",
                    marginRight: "16px",
                    backgroundColor: colorConfigs.topbar.bg,
                    padding: "5px 8px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      color: "white",
                      marginBlock: "0px",
                    }}
                  >
                    Final Authorization
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      marginBlock: "0px",
                      color: "black",
                      backgroundColor: "white",
                      padding: "1px 5px",
                      borderRadius: "20px",
                    }}
                  >
                    $300.00
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "8px 16px 16px 16px",
                    color: "white",
                    backgroundColor: "#66BB6A",
                    padding: "5px 8px",
                    cursor: "pointer",
                    fontSize: "12px",
                  }}
                >
                  Authorize with Credit Card
                </div>
              </>
            )}
          </div>

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }} />

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => {
                  setProcessPaymentBox(false);
                  setProcessSecurityDeposit(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => {
                  if (!isCompletedOrCancelled)
                    setShowWebcam(false)
                }}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();

                    handleCapture(imageSrc, `${baseURL}reservationFile/upload`, (imageFilePath: string) => {
                      if (imageFilePath) {
                        setNewFilePath((prevFileString: string[]) => [
                          ...prevFileString,
                          `${baseURL}reservationFile/Get?name=${imageFilePath}`
                        ]);

                        const newFile: any = {
                          id: 0,
                          fileName: `${baseURL}reservationFile/Get?name=${imageFilePath}`
                        };
                        setFilePath((prevFileString: any[]) => [
                          ...prevFileString,
                          newFile,
                        ]);
                      }
                    })
                    setShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              Repair Order Image
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {filePath.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  left: "0",
                  top: "50%",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    cursor: fileIndex !== 0 ? "pointer" : "",
                    opacity: fileIndex === 0 ? "0.5" : "1",
                    color: "gray",
                  }}
                  onClick={() => {
                    if (fileIndex !== 0) {
                      setFileIndex(fileIndex - 1);
                    }
                  }}
                />
              </div>
              <img
                width={700}
                height={350}
                src={filePath[fileIndex]?.fileName}
                alt="repairOrder"
              />

              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  right: "0",
                  top: "50%",
                  color: "gray",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    cursor: fileIndex !== filePath.length - 1 ? "pointer" : "",
                    opacity: fileIndex === filePath.length - 1 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== filePath.length - 1) {
                      setFileIndex(fileIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
