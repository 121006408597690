import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import baseURL from "../../../configs/api";
import CloseIcon from "@mui/icons-material/Close";
import { AddNewCustomer } from "./AddNewCustomer";
import CustomerSelectWrapper from "./CustomerSelectWrapper";

type Props = {
  setNewDriverAdded: (value: boolean) => void;
  addNewDiver: boolean;
  setAddNewDriver: (value: boolean) => void;
  customers: any[];
  setCustomers: (value: any[]) => void;
  customerId: string;
  setCustomerId: (value: string) => void;
  drivers: any[];
  driverId: string;
  isPrimaryDriver: boolean;
  setIsPrimaryDriver: (value: boolean) => void;
  reservation: any;
  handlePleaseWait: (pleaseWaitPopup: boolean) => void;
  reservationUpdate: () => void;
  vehicleClassId: string;
  branchId: string;
  pickupDate: string;
  pickupTime: string;
  returnDate: string;
  returnTime: string;
};

export default function AddDriver({
  setNewDriverAdded,
  addNewDiver,
  setAddNewDriver,
  customers,
  setCustomers,
  customerId,
  setCustomerId,
  drivers,
  driverId,
  isPrimaryDriver,
  setIsPrimaryDriver,
  reservation,
  handlePleaseWait,
  reservationUpdate,
  vehicleClassId,
  branchId,
  pickupDate,
  pickupTime,
  returnDate,
  returnTime,
}: Props) {
  const [availableVehicle, setAvailableVehicle] = useState<any[]>([]);
  const [availableVehicleOfParticular, setAvailableVehicleOfParticular] =
    useState<any>();

  const [customerKey, setCustomerKey] = useState(0);
  const [options, setOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setNextPageLoading] = useState(false);
  const [pageNo, setPage] = useState(-1);
  const [searchValue, setSearchValue] = useState("");
  let latestPickup = new Date();
  let latestReturn = new Date();
  if (pickupDate) latestPickup = new Date(pickupTime ? `${pickupDate}T${pickupTime}` : pickupDate);
  if (returnDate) latestReturn = new Date(returnTime ? `${returnDate}T${returnTime}` : returnDate);


  const calculateAge = (date: string) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleCreateNew = () => {
    setCustomerId("");
    setCustomerKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;
    setHasNextPage(true);
    setNextPageLoading(false);
    setPage(-1);
    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        setIsSearching(false);
        setOptions([]);
      }, 1000);
    } else {
      setOptions([]);
      setIsSearching(false);
    }

    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const loadNextPage = () => {
    if (searchValue === "") {
      loadWithOutSearchOptions(pageNo + 1);
    } else {
      loadWithSearchOptions(pageNo + 1);
    }
  };

  const loadWithOutSearchOptions = (page: number) => {
    try {
      const pagination = {
        page: page,
        size: 15,
      };
      setNextPageLoading(true);
      fetch(`${baseURL}Customer/GetCustomersPaging/${pagination.page},${pagination.size}`)
        .then((res) => res.json())
        .then((data) => {
          const dataOptions = data.customers.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          });

          const itemsData = options.concat(dataOptions);
          setOptions(itemsData);
          setNextPageLoading(false);
          setHasNextPage(itemsData.length < data.count);
          setPage(page);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const loadWithSearchOptions = (page: number) => {
    try {
      const pagination = {
        page: page,
        size: 15,
      };
      setNextPageLoading(true);
      fetch(`${baseURL}Customer/GetCustomersSearchWithCustomerNamePaging/${searchValue},${pagination.page},${pagination.size}`)
        .then((res) => res.json())
        .then((data) => {
          const dataOptions = data.customers.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          });

          const itemsData = options.concat(dataOptions);
          setOptions(itemsData);
          setNextPageLoading(false);
          setHasNextPage(itemsData.length < data.count);
          setPage(page);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (branchId && pickupDate && pickupTime && returnDate && returnTime) {
      axios
        .get(
          `${baseURL}availableCars?pickupDate=${latestPickup.toISOString()}&returnDate=${latestReturn.toISOString()}&branchId=${branchId}`
        )
        .then((response) => {
          setAvailableVehicle(() => response.data);
        })
        .catch(error => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId, pickupDate, pickupTime, returnDate, returnTime, addNewDiver]);

  useEffect(() => {
    if (availableVehicle) {
      setAvailableVehicleOfParticular(() =>
        availableVehicle.find(
          (item: any) => item.vehicleClassId === parseInt(vehicleClassId)
        )
      );
    } else {
      setAvailableVehicleOfParticular(() => availableVehicle[0]);
    }
  }, [vehicleClassId, availableVehicle]);

  const updateTheDriver = (updateCustomerId: string, newCustomer?: any) => {
    handlePleaseWait(true);
    setCustomerId(updateCustomerId);

    let updateCustomer = customers;

    if (updateCustomer?.find((item) => item.value === parseInt(updateCustomerId))) {
      updateCustomer = updateCustomer.map((item) => {
        if (item.value === parseInt(updateCustomerId)) {
          return newCustomer;
        }
        return item;
      });
    } else {
      if (newCustomer) {
        updateCustomer = [
          ...updateCustomer,
          newCustomer
        ];
      }
    }

    setCustomers(updateCustomer);

    const customer = updateCustomer?.find((item) => item.value === parseInt(updateCustomerId));
    if (customer) {
      const customerDob = new Date(customer?.dob);
      const customerDobString = customerDob?.toISOString().split('T')[0];

      const customerAge = calculateAge(customerDobString);

      if (
        !customerAge ||
        !(customerAge >= availableVehicleOfParticular?.minimumRentalAllowedAge &&
          customerAge <= availableVehicleOfParticular?.maxRentalAllowedAge)
      ) {
        window.alert("Your age does not meet the requirements to operate this vehicle.");
        handlePleaseWait(false);
        return;
      }
    } else {
      window.alert("Please add date of birth of customer.");
      handlePleaseWait(false);
      return;
    }

    setAddNewDriver(false)

    if (driverId === "0") {
      axios
        .post(
          `${baseURL}reservationAdditionalDriver`,
          JSON.stringify({
            reservationId: reservation?.id,
            customerId: updateCustomerId,
            primaryDriver: isPrimaryDriver,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          updateAllDriver(updateCustomerId);
        })
        .catch((error) => {
          console.error("Error:", error);
          handlePleaseWait(false);
        });
    } else {
      axios
        .put(
          `${baseURL}reservationAdditionalDriver`,
          JSON.stringify({
            id: driverId,
            reservationId: reservation?.id,
            customerId: updateCustomerId,
            primaryDriver: isPrimaryDriver,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          updateAllDriver(updateCustomerId);
        })
        .catch((error) => {
          console.error("Error:", error);
          handlePleaseWait(false);
        });
    }
  };

  const updateAllDriver = (updateCustomerId: string) => {
    if (isPrimaryDriver) {
      updateReservationCustomer(updateCustomerId);
      drivers.forEach((item: any, index: number) => {
        if (item.customerId !== updateCustomerId) {
          axios
            .put(
              `${baseURL}reservationAdditionalDriver`,
              JSON.stringify({
                id: item.id,
                reservationId: item.reservationId,
                customerId: item.customerId,
                primaryDriver: false,
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
              if (drivers.length - 1 === index) {
                handlePleaseWait(false);
              }
              setNewDriverAdded(true);
            })
            .catch((error) => {
              console.error("Error:", error);
              handlePleaseWait(false);
            });
        } else {
          if (drivers.length - 1 === index) {
            handlePleaseWait(false);
          }
          setNewDriverAdded(true);
        }
      });
    } else {
      handlePleaseWait(false);
      setNewDriverAdded(true);
    }
  };

  const updateReservationCustomer = (updateCustomerId: String) => {
    axios
      .put(
        `${baseURL}reservation`,
        {
          ...reservation,
          customerId: updateCustomerId,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        reservationUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Dialog
      open={addNewDiver}
      maxWidth="md"
      fullScreen={false}
      PaperProps={{
        style: {
          width: "40%",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", width: "100%" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 8px 16px",
          }}
        >
          <p style={{ fontSize: "14px", marginLeft: "5px" }}>
            {customerId ? "Edit Driver" : "Add Driver"}
          </p>
          <CloseIcon
            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => setAddNewDriver(false)}
          />
        </div>
        <hr style={{ marginBlock: "0px" }} />
      </DialogTitle>
      <DialogContent sx={{ padding: "5px" }}>
        <div
          style={{
            padding: "8px",
          }}
        >
          <div style={{ marginBlock: "10px" }}>
            <label
              htmlFor="localAddress"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              Existing Driver
            </label>
            <hr />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "starrt",
                alignItems: "center",
                gap: "15px",
                marginTop: "0px",
              }}
            >
              <CustomerSelectWrapper
                key={customerKey}
                hasNextPage={hasNextPage}
                isNextPageLoading={isNextPageLoading}
                setSearchValue={setSearchValue}
                options={options}
                setPage={setPage}
                loadNextPage={loadNextPage}
                isSearching={isSearching}
                setIsSearching={setIsSearching}
                onChange={(selectedOption: any) => setCustomerId(selectedOption?.value)}
              />

              <p
                style={{ fontSize: "14px", cursor: "pointer" }}
                onClick={handleCreateNew}
              >
                or create a new one
              </p>
            </div>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <label
              htmlFor="timeOut"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Primary Driver?
              <br />
              <div>
                <label style={{ fontSize: "12px" }}>
                  <input
                    type="radio"
                    value="Yes"
                    name="isPrimaryDriver"
                    style={{ fontSize: "12px" }}
                    checked={isPrimaryDriver ? true : false}
                    onChange={(e) =>
                      setIsPrimaryDriver(
                        e.target.value === "Yes" ? true : false
                      )
                    }
                  />{" "}
                  Yes
                </label>
                <label style={{ fontSize: "12px" }}>
                  <input
                    type="radio"
                    value="No"
                    name="isPrimaryDriver"
                    style={{ fontSize: "12px" }}
                    checked={isPrimaryDriver ? false : true}
                    onChange={(e) =>
                      setIsPrimaryDriver(
                        e.target.value === "Yes" ? true : false
                      )
                    }
                  />{" "}
                  No
                </label>
              </div>
            </label>
          </div>

          <AddNewCustomer
            handleCustomer={(updateCustomerId, newCustomer) =>
              updateTheDriver(updateCustomerId, newCustomer)
            }
            closeNewCustomer={() => setAddNewDriver(false)}
            customerId={customerId}
            isFromDriver={true}
            returnDate={returnDate}
            returnTime={returnTime}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
