import axios from "axios";
import { ticketBaseURL } from "../configs/api";

export const post_TicketComment = (data: any) => {
    return axios({
        url: `${ticketBaseURL}comment`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_TicketComment = (data: any) => {
    return axios({
        url: `${ticketBaseURL}comment`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_TicketComment = (primaryKey: string) => {
    return axios({
        url: `${ticketBaseURL}comment`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getTicketComments = (attributeName: string, attributeId: string) => {
    return axios({
        url: `${ticketBaseURL}comment/${attributeName},${attributeId}`,
        method: "GET"
    }).then(response => response.data);
}