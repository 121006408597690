import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from "./bar-chart.module.css";
import { appName } from '../../../configs/api';
import MenuIcon from '@mui/icons-material/Menu';

type DataItem = {
    name: string;
    value: number;
};

type BarChartProps = {
    height: number;
    data: DataItem[];
    title?: string;
};

type OptionType = {
    value: number,
    label: string
};

const MARGIN_X = 40;
const MARGIN_Y = 250;

function generateHSLColors(num: number): string[] {
    const colors = [];
    for (let i = 0; i < num; i++) {
        const hue = (i / num) * 360; // Spread across 360 degrees
        colors.push(`hsl(${hue}, 70%, 60%)`); // Saturation = 70%, Lightness = 60%
    }
    return colors;
}

function createIntervals(maxValue: number, numIntervals: number = 10) {
    if (maxValue === 0) return [];  // Avoid division by zero if maxValue is 0
    const step = maxValue / numIntervals;  // Calculate the interval step
    const intervals = [];

    for (let i = 0; i <= numIntervals; i++) {
        intervals.push(step * i);
    }
    return intervals;
};


const BarChart = ({
    height,
    data,
    title
}: BarChartProps) => {
    const ref = useRef<SVGGElement | null>(null);
    const barRefs = useRef<(SVGGElement | null)[]>([]);
    const legends = useRef<HTMLDivElement | null>(null);

    const [tooltip, setTooltip] = useState<{
        visible: boolean;
        x: number;
        y: number;
        text: string;
    }>({ visible: false, x: 0, y: 0, text: "" });
    const [pieChartDropDown, setPieChartDropDown] = useState<OptionType[]>([
        { value: 1, label: "View in FullScreen" },
        { value: 2, label: "Print the Chart" }
    ]);
    const [width, setWidth] = useState<number>(0);
    const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
    const [scheduleFullScreen, setScheduleFullScreen] = useState<boolean>(false);

    const dropDownVisibleRef = useRef<HTMLDivElement>(null);
    const scheduleFullScreenRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onResize = () => {
            if (scheduleFullScreenRef.current && !scheduleFullScreen) {
                setWidth(scheduleFullScreenRef.current?.clientWidth);
            }
        }

        onResize();
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, scheduleFullScreen])

    useEffect(() => {
        const handleDropDown = (e: MouseEvent) => {
            if (!dropDownVisibleRef.current?.contains(e.target as Node)) setDropDownVisible(false);
        }

        document.addEventListener("click", handleDropDown);
        return () => document.removeEventListener("click", handleDropDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const colors = generateHSLColors(data?.length);

    const maxValue = useMemo(
        () => data?.reduce((acc, item) => (item.value > acc ? item.value : acc), 0) ?? 0,
        [data]
    );

    const intervals = useMemo(() => createIntervals(maxValue), [maxValue]);

    const shapes = useMemo(() => {
        const barGraphWidth = (scheduleFullScreen ? window.innerWidth : width) - MARGIN_X * 2;
        const barWidth = barGraphWidth / data.length - 2;
        const graphHeight = scheduleFullScreen ? window.innerHeight - MARGIN_Y : height;

        return (
            <>
                <g
                    className={`${styles.x} ${styles.axis}`}
                    transform={`translate(0,${graphHeight - 46})`}
                >
                    {data.map((item, index) => {
                        const xPosition = (barGraphWidth / data.length) * index + (barGraphWidth / data.length) - 3 / 2;

                        return (
                            <g
                                key={index}
                                className={styles.tick}
                                transform={`translate(${xPosition}, 0)`}
                                style={{ opacity: 1 }}
                            >
                                <line
                                    y2="-3"
                                    x2="0"
                                ></line>

                                {scheduleFullScreen &&
                                    <>
                                        <title>{item?.name}</title>

                                        <text
                                            x="0"
                                            y="9"
                                            style={{ textAnchor: "end" }}
                                            transform="rotate(-45) translate(-8, 0)"
                                            fontSize={data?.length > 100 ? 8 : 12}
                                        >
                                            {item?.name.length > 8 ? `${item?.name.substring(0, 8)}...` : item?.name}
                                        </text>
                                    </>
                                }
                            </g>
                        );
                    })}

                    <path className="domain" d="M0, 4V-2H600V4"></path>
                </g>

                <g className={`${styles.y} ${styles.axis}`}>
                    {intervals.map((item, index) => {
                        const tickSpacing = (graphHeight - 50) / 10;

                        return (
                            <g
                                key={index}
                                className={styles.tick}
                                transform={`translate(0,${(graphHeight - 50) - (tickSpacing * index)})`}
                                style={{ opacity: 1 }}
                            >
                                <line
                                    x2="-6"
                                    y2="0"
                                    transform={`translate(3,0)`}
                                ></line>

                                <text
                                    dy=".32em"
                                    x="-6"
                                    y="0"
                                    style={{ textAnchor: "end", fontSize: 10 }}
                                >
                                    {item}
                                </text>
                            </g>
                        );
                    })}

                    <path className="domain" d={`M 3, 0H2V${graphHeight - 50}H3`}></path>

                    <text transform="rotate(0)" x={12} y={-12} dy="0em" style={{ textAnchor: "end" }}>Value</text>
                </g>

                {data.map((item, index) => {
                    const barHeight = maxValue > 0 ? (item.value / maxValue) * (graphHeight - 50) : 0;
                    const xPosition = (barGraphWidth / data.length) * index + barWidth / 2;

                    const label = `${item.name} (${item.value})`;

                    return (
                        <rect
                            key={index}
                            className={styles.bar}
                            ref={(el) => (barRefs.current[index] = el)}
                            x={xPosition}
                            width={barWidth}
                            y={(graphHeight - 50) - barHeight}
                            height={barHeight}
                            fill={colors[index]}
                            onMouseEnter={(e) => {
                                ref.current?.classList.add(styles.hasHighlight);
                                setTooltip({
                                    visible: true,
                                    x: scheduleFullScreen ? e.clientX : e.clientX + window.scrollX,
                                    y: scheduleFullScreen ? e.clientY : e.clientY + window.scrollY - 10,
                                    text: label,
                                });
                            }}                      
                            onMouseMove={(e) => {
                                setTooltip((prevTooltip) => ({
                                    ...prevTooltip,
                                    x: scheduleFullScreen ? e.clientX : e.clientX + window.scrollX,
                                    y: scheduleFullScreen ? e.clientY : e.clientY + window.scrollY - 10
                                }));
                            }}
                            onMouseLeave={() => {
                                ref.current?.classList.remove(styles.hasHighlight);
                                setTooltip({ visible: false, x: 0, y: 0, text: "" });
                            }}
                        />
                    );
                })}
            </>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, maxValue, height, scheduleFullScreen, colors, barRefs]);

    useEffect(() => {
        // Handler for fullscreen change event
        const handleFullscreenChange = () => {
            setScheduleFullScreen(document.fullscreenElement === scheduleFullScreenRef.current);
        };

        // Add event listeners for fullscreen change
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        // Clean up event listeners on component unmount
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        if (scheduleFullScreen) {
            setPieChartDropDown((prevValue: OptionType[]) => {
                return prevValue.map((option, index) => (
                    index === 0
                        ? { ...option, label: "Exit FullScreen" }
                        : option
                ))
            });
        } else {
            setPieChartDropDown((prevValue: OptionType[]) => {
                return prevValue.map((option, index) => (
                    index === 0
                        ? { ...option, label: "View in FullScreen" }
                        : option
                ))
            });
        }

    }, [scheduleFullScreen])

    const printContentRef = useRef<HTMLDivElement | null>(null);

    const hideDropdown = (value: number) => {
        switch (value) {
            case 1:
                if (scheduleFullScreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                } else {
                    const element = scheduleFullScreenRef.current;
                    if (element) {
                        element.requestFullscreen();
                    }
                }
                break;
            case 2:
                if (!printContentRef.current) return;
                const printContent = printContentRef.current;

                if (printContent) {
                    const printWindow = window.open('', '', 'height=500, width=500');
                    if (printWindow) {
                        printWindow.document.write(`<html><head><title>${appName}</title></head><body>`);
                        printWindow.document.write(printContent.innerHTML);
                        printWindow.document.write('</body></html>');
                        printWindow.document.close();

                        printWindow.print();

                        if (printWindow.closed) {
                            const element = scheduleFullScreenRef.current;
                            if (element) {
                                element.requestFullscreen();
                            }
                        }
                    }
                }
                break;
        }

        setDropDownVisible(false);
    }

    const handleLegendEnter = (index: number, name: string) => {
        ref.current?.classList.add(styles.hasHighlight);

        const bar = barRefs.current[index];
        if (bar) {
            if (!bar.classList.contains(styles.barHover)) {
                bar.classList.add(styles.barHover);
            }

            const rect = bar.getBoundingClientRect();          
    
            setTooltip({
                visible: true,
                x: scheduleFullScreen ? rect.left : rect.left + window.scrollX,
                y: scheduleFullScreen ? rect.top : rect.top + window.scrollY,
                text: name
            });
        }
    };

    const handleLegendMove = (index: number) => {
        const bar = barRefs.current[index];
        if (bar) {           
            const rect = bar.getBoundingClientRect();          
    
            setTooltip((prevTooltip) => ({
                ...prevTooltip,
                x: scheduleFullScreen ? rect.left : rect.left + window.scrollX,
                y: scheduleFullScreen ? rect.top : rect.top + window.scrollY        
            }));
        }
    };

    const handleLegendLeave = (index: number) => {
        ref.current?.classList.remove(styles.hasHighlight);

        const bar = barRefs.current[index];
        if (bar) {
            if (bar.classList.contains(styles.barHover)) {
                bar.classList.remove(styles.barHover);
            }

            setTooltip({ visible: false, x: 0, y: 0, text: "" });
        }
    };


    return (
        <div
            ref={scheduleFullScreenRef}
            style={{
                background: "#ECEFF1",
                width: "100%"
            }}
        >
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "0.5rem"
                }}
            >
                <h5
                    style={{
                        flex: "1 1 0%",
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontWeight: 600
                    }}
                >{title}</h5>

                <div
                    ref={dropDownVisibleRef}
                    style={{
                        width: "fit-content",
                    }}
                >
                    <button
                        style={{
                            padding: "0.125rem 0.5rem",
                            cursor: "pointer",
                            marginTop: "0.5rem",
                            border: "none"
                        }}
                        onClick={() => setDropDownVisible(!dropDownVisible)}
                    >
                        <MenuIcon sx={{ fontSize: 22 }} />
                    </button>

                    <div
                        style={{
                            position: "absolute",
                            zIndex: 10000,
                            top: 0,
                            right: 16,
                            marginTop: "2rem",
                            background: "#ffffff",
                            border: "1px solid rgb(0 0 0 / 0.3)",
                            display: dropDownVisible ? "flex" : "none",
                            flexDirection: "column",
                            overflow: "auto",
                            whiteSpace: "nowrap",
                            minWidth: "14rem",
                            width: "fit-content",
                            height: "fit-content",
                            maxHeight: "13rem"
                        }}
                    >
                        {pieChartDropDown?.map((item: OptionType, index: number) => (
                            <span
                                key={index}
                                style={{
                                    padding: "0.125rem 0.5rem",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    margin: "0"
                                }}
                                onClick={() => {
                                    hideDropdown(item.value);
                                }}
                                className={styles.dropdownItem}
                            >
                                {item.label}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            <div ref={printContentRef}>
                <svg
                    width={scheduleFullScreen ? window.innerWidth : width}
                    height={scheduleFullScreen ? window.innerHeight : height}
                    style={{ display: "inline-block" }}
                >                  
                        <g
                            transform={`translate(${MARGIN_X},20)`}
                            className={styles.container}
                            ref={ref}
                        >
                            {shapes}
                        </g>
                </svg>
                
                {tooltip.visible && (
                    <div
                        style={{
                            position: "absolute",
                            transform: `translate(-50%, -50%)`,
                            left: tooltip.x,
                            top: tooltip.y
                        }}
                        className={styles.tooltip}
                    >
                        <div className={styles.tooltipContent}>{tooltip.text}</div>
                    </div>
                )}
            </div>

            <div
                ref={legends}
                style={{
                    width: scheduleFullScreen ? window.innerWidth : width,
                    height: "2rem",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    borderTop: "1px solid rgb(0 0 0 / 0.3)",
                    paddingInline: "0.5rem"
                }}
                className={styles.barchart}
            >
                {
                    data?.map((item: any, index: number) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                textWrap: "nowrap",
                                gap: "0.25rem",
                                alignItems: "center",
                                cursor: "pointer"
                            }}
                            className={styles.legendItem}
                            onMouseEnter={() => handleLegendEnter(index, item?.name)}
                            onMouseMove={() => handleLegendMove(index)}
                            onMouseLeave={() => handleLegendLeave(index)}
                        >
                            <div
                                style={{
                                    backgroundColor: colors[index],
                                    width: "0.625rem",
                                    height: "0.625rem",
                                    borderRadius: "100%"
                                }}
                            ></div>
                            <span
                                style={{
                                    fontSize: "0.75rem",
                                    fontWeight: 500
                                }}
                            >{item?.name}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default BarChart
