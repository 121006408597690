import axios from "axios"
import baseURL from "../configs/api"

export const getReservationHistory = (primaryKey: string) => {
    return getFetch(`reservation/ReservationHistory/${primaryKey}`);
}

export const getReservationHistoryByCustomer = (primaryKey: string) => {
    return getFetch(`reservation/GetGetReservationsHistoryByCustomer/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}