import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import baseURL, { appName } from "../../../configs/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PDFDocument } from "pdf-lib";
import SignatureCanvas from "react-signature-canvas";
import { Button, Dialog, DialogContent } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
import useAuth from "../../../components/hooks/useAuth";
import AdjustmentList from "../../../components/common/AdjustmentList";
import { getCommentsOfAttribute } from "../../../services/comment";
import { getAdditionalCharges } from "../../../services/additionalCharge";
import AddAdjustmentModal from "../../../components/common/AddAdjustmentModal";
import AddExtensionModal from "../../../components/common/AddExtensionModal";
import ExtensionList from "../../../components/common/ExtensionList";
import { getReservationExtensionByReservationId } from "../../../services/Extension";
import AddReplaceVehicleModal from "../../../components/common/AddReplaceVehicleModal";
import { getVehicleReplaceReportByReservationId } from "../../../services/VehicleReplcae";
import VehicleReplaceList from "../../../components/common/VehicleReplaceList";
import AddEarlyReturnModal from "../../../components/common/AddEarlyReturnModal";
import EarlyReturnList from "../../../components/common/EarlyReturnList";
import { getEarlyReturnByReservationId } from "../../../services/earlyReturn";

type Props = {
  goToNextPage: (page: number) => void;
  reservation: any;
  signatureURL: any;
  setSignatureURL: (value: any) => void;
  initialsURL: any;
  setInitialsURL: (Value: any) => void;
  setSignatureChange: (value: boolean) => void;
  setIsExtraValueIsAdded: (value: boolean) => void;
  setReservationUpdate: (value: boolean) => void;
  isCompletedOrCancelled: boolean
};

export default function Agreement({
  goToNextPage,
  reservation,
  signatureURL,
  setSignatureURL,
  initialsURL,
  setInitialsURL,
  setSignatureChange,
  setIsExtraValueIsAdded,
  setReservationUpdate,
  isCompletedOrCancelled
}: Props) {
  const { auth }: any = useAuth();
  const [isContract, setIsContract] = useState<boolean>(true);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [isContractModifications, setIsContractModifications] = useState<boolean>(true);
  const [isSignatures, setIsSignatures] = useState<boolean>(true);
  const [addEarlyReturnIsOpen, setAddEarlyReturnIsOpen] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
  const [adjustmentIsOpen, setAdjustmentIsOpen] = useState<boolean>(false);
  const [adjustmentListIsOpen, setAdjustmentListIsOpen] = useState<boolean>(true);
  const [adjustmentData, setAdjustmentData] = useState<any[]>([])
  const [additionalCharges, setAdditionalCharges] = useState<any[]>([]);
  const [isAdditionalCharegesAdded, setIsAdditionalCharegesAdded] = useState<boolean>(false);
  const [adjustmentRefresh, setAdjustmentRefresh] = useState<boolean>(true); const [addExtensionIsOpen, setAddExtensionIsOpen] = useState<boolean>(false);
  const [extensionListIsOpen, setExtensionListIsOpen] = useState<boolean>(true);
  const [extensionData, setExtensioData] = useState<any[]>([]);
  const [extensionRefresh, setExtensionRefresh] = useState<boolean>(true);
  const [replaceVehicleIsOpen, setReplaceVehicleIsOpen] = useState<boolean>(false);
  const [vehicleReplaceListIsOpen, setVehicleReplaceListIsOpen] = useState<boolean>(true);
  const [vehicleReplaceData, setVehicleReplaceData] = useState<any[]>([])
  const [vehicleReplaceRefresh, setVehicleReplaceRefresh] = useState<boolean>(true);
  const [earlyReturnRefresh, setEarlyReturnRefresh] = useState<boolean>(true);
  const [earlyReturnData, setEarlyReturnData] = useState<any[]>([]);
  const [isEarlyReturnOpen, setIsEarlyReturnOpen] = useState<boolean>(true);
  let signaturePadRef = React.useRef<SignatureCanvas>(null);
  let initialsPadRef = React.useRef<SignatureCanvas>(null);

  const signatureFileRef = useRef<HTMLInputElement>(null);
  const onSignatureFileChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isCompletedOrCancelled) {
      setPleaseWaitPopup(true);
      const selectedFiles = e.target.files as FileList;

      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        formData.append("ImageFile", selectedFiles[0]);

        axios
          .post(`${baseURL}reservationFile/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((response) => {
            const newFilePath: any = {
              id: 0,
              fileName: response.data,
            };
            signaturePadRef.current?.clear();
            setSignatureURL(newFilePath);
            setPleaseWaitPopup(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        setPleaseWaitPopup(false);
      }
    }
  };

  const initialsFileRef = useRef<HTMLInputElement>(null);
  const onInitialsFileChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isCompletedOrCancelled) {
      setPleaseWaitPopup(true);
      const selectedFiles = e.target.files as FileList;

      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        formData.append("ImageFile", selectedFiles[0]);

        axios
          .post(`${baseURL}reservationFile/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((response) => {
            const newFilePath: any = {
              id: 0,
              fileName: response.data,
            };

            initialsPadRef.current?.clear();
            setInitialsURL(newFilePath);
            setPleaseWaitPopup(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        setPleaseWaitPopup(false);
      }
    }
  };

  useEffect(() => {
    if (reservation) {
      getAdditionalCharges()
        .then(data => {
          setAdditionalCharges(
            data.filter((value: any) => !value.hideFromReservations)
              .map((item: any) => {
                return {
                  id: item.id,
                  name: item.name,
                  selectionType: item.selectionType,
                  chargeType: item.chargeType,
                };
              })
          );
          setIsAdditionalCharegesAdded(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation]);

  useEffect(() => {
    const fetchReservationAdjustment = async () => {
      if (reservation && isAdditionalCharegesAdded && adjustmentRefresh) {
        try {
          const response = await axios.get(
            `${baseURL}reservationAdditionalCharge/GetReservationAdditionalCharges/${reservation?.id}`
          );

          const reservationAdjustment = await Promise.all(
            response.data
              .filter((item: any) => item.reservatinId === reservation?.id)
              .map(async (item: any) => {
                try {
                  const commentResponse = await getCommentsOfAttribute(
                    "ReservationAdjustment",
                    item.id
                  );

                  return {
                    ...item,
                    name: additionalCharges.find(
                      (value: any) => value.id === item.additionalChargeByBranchId
                    )?.name,
                    selectionType: additionalCharges.find(
                      (value: any) => value.id === item.additionalChargeByBranchId
                    )?.selectionType,
                    chargeType: additionalCharges.find(
                      (value: any) => value.id === item.additionalChargeByBranchId
                    )?.chargeType,
                    comment: commentResponse[0]?.notes,
                    commentId: commentResponse[0]?.id,
                    commnetAttributeName: commentResponse[0]?.attributeName,
                    commnetAttributeId: commentResponse[0]?.attributeId,
                    commnetUserId: commentResponse[0]?.userId,
                    commnetCreatedDate: commentResponse[0]?.createdDate
                  };
                } catch (error) {
                  console.error("Error fetching comments:", error);
                  return { ...item, comment: null };
                }
              })
          );

          setAdjustmentData(reservationAdjustment);
          setAdjustmentRefresh(false);
        } catch (error) {
          console.error("Error fetching reservation adjustments:", error);
        }
      }
    };

    fetchReservationAdjustment();
  }, [reservation, isAdditionalCharegesAdded, adjustmentRefresh]);




  useEffect(() => {
    if (reservation && extensionRefresh) {
      getReservationExtensionByReservationId(reservation?.id)
        .then((res) => {
          setExtensioData(res);
          setExtensionRefresh(false);
        })
        .catch((error) => {
          console.error("Error:", error)
        })
    }
  }, [reservation, extensionRefresh])


  useEffect(() => {
    if (reservation && extensionRefresh) {
      getReservationExtensionByReservationId(reservation?.id)
        .then((res) => {
          setExtensioData(res);
          setExtensionRefresh(false);
        })
        .catch((error) => {
          console.error("Error:", error)
        })
    }
  }, [reservation, extensionRefresh])

  useEffect(() => {
    if (reservation && vehicleReplaceRefresh) {
      getVehicleReplaceReportByReservationId(reservation?.id)
        .then((res) => {
          setVehicleReplaceData(res);
          setVehicleReplaceRefresh(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
    }
  }, [reservation, vehicleReplaceRefresh])

  useEffect(() => {
    if (reservation && earlyReturnRefresh) {
      getEarlyReturnByReservationId(reservation?.id)
        .then((res) => {
          setEarlyReturnData(res);
          setEarlyReturnRefresh(false);
        })
        .catch((error) => {
          console.error("Error:", error)
        })
    }
  }, [reservation, earlyReturnRefresh])

  useEffect(() => {
    async function fetchAndConvertPdf() {
      try {
        const response = await axios.get(
          `${baseURL}rentalAgreementPdf?reservationId=${reservation?.id}`,
          {
            responseType: "arraybuffer", // Fetch the PDF as binary data
          }
        );

        const pdfData = new Uint8Array(response.data);
        const encodedArray = Array.from(pdfData);
        const base64PdfData = btoa(
          String.fromCharCode.apply(null, encodedArray)
        );

        const bytes = Uint8Array.from(atob(base64PdfData), (c) =>
          c.charCodeAt(0)
        );

        // Create a PDFDocument from the bytes
        const pdfDoc = await PDFDocument.load(bytes);

        // Set custom metadata (including the file name)
        pdfDoc.setTitle(`RentalAgreement${reservation?.id}.pdf`);
        pdfDoc.setAuthor(reservation?.createdBy);
        pdfDoc.setSubject("Rental Agreement");
        pdfDoc.setProducer(appName);
        pdfDoc.setCreator(appName);

        // Serialize the modified PDF to a new Uint8Array
        const modifiedBytes = await pdfDoc.save();

        const modifiedArray = Array.from(modifiedBytes);

        // Convert the modifiedBytes to a base64-encoded string
        const modifiedBase64 = btoa(String.fromCharCode(...modifiedArray));

        setFileUrl(modifiedBase64);
      } catch (error) {
        console.error("Error fetching or converting PDF:", error);
      }
    }

    if (reservation) fetchAndConvertPdf();
  }, [reservation]);

  const converTheBase64IntoFileAndUploadToServer = (canvas: HTMLCanvasElement, callback: any) => {
    if (!isCompletedOrCancelled) {
      canvas.toBlob((blob) => {
        if (blob) {
          const formData = new FormData();
          formData.append("ImageFile", blob, 'signature.png');
          axios
            .post(`${baseURL}reservationFile/upload`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
              withCredentials: true,
            })
            .then((response) => {
              callback(response.data);
            })
            .catch((error) => {
              console.error("Error:", error);
              callback("");
            });
        } else {
          console.error("Error: Blob is null");
          callback("");
        }
      }, 'image/png');
    }
  }

  const uploadSignature = () => {
    if (!isCompletedOrCancelled) {
      if (signatureURL && signatureURL?.id > 0) {
        uploadInitial();
        signaturePadRef.current?.clear();
      } else if (signatureURL && signatureURL?.id === 0) {
        axios
          .post(
            `${baseURL}fileUploadData`,
            JSON.stringify({
              attributeName: "Reservation",
              attributeId: reservation?.id,
              fileName: signatureURL.fileName,
              tabName: "Signature",
              userId: auth?.id ?? 0,
              createdDate: new Date(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            uploadInitial();
            signaturePadRef.current?.clear();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        const canvas = signaturePadRef.current?.getTrimmedCanvas();
        if (canvas) {
          converTheBase64IntoFileAndUploadToServer(canvas, (imageFilePath: string) => {
            if (imageFilePath) {
              axios
                .post(
                  `${baseURL}fileUploadData`,
                  JSON.stringify({
                    attributeName: "Reservation",
                    attributeId: reservation?.id,
                    fileName: imageFilePath,
                    tabName: "Signature",
                    userId: auth?.id ?? 0,
                    createdDate: new Date(),
                  }),
                  {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                  }
                )
                .then(() => {
                  uploadInitial();
                  signaturePadRef.current?.clear();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            } else {
              uploadInitial();
            }
          });

        } else {
          uploadInitial();
        }
      }
    }
  };

  const uploadInitial = () => {
    if (!isCompletedOrCancelled) {
      if (initialsURL && initialsURL?.id > 0) {
        goToNextPage(8);
        setSignatureChange(true);
        initialsPadRef.current?.clear();
      } else if (initialsURL && initialsURL?.id === 0) {
        axios
          .post(
            `${baseURL}fileUploadData`,
            JSON.stringify({
              attributeName: "Reservation",
              attributeId: reservation?.id,
              fileName: initialsURL.fileName,
              tabName: "Initials",
              userId: 1,
              createdDate: new Date(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            goToNextPage(8);
            setSignatureChange(true);
            initialsPadRef.current?.clear();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        const canvas = initialsPadRef.current?.getTrimmedCanvas();
        if (canvas) {
          converTheBase64IntoFileAndUploadToServer(canvas, (imageFilePath: string) => {
            if (imageFilePath) {
              axios
                .post(
                  `${baseURL}fileUploadData`,
                  JSON.stringify({
                    attributeName: "Reservation",
                    attributeId: reservation?.id,
                    fileName: imageFilePath,
                    tabName: "Initials",
                    userId: auth?.id ?? 0,
                    createdDate: new Date(),
                  }),
                  {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                  }
                )
                .then(() => {
                  goToNextPage(8);
                  setSignatureChange(true);
                  initialsPadRef.current?.clear();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            } else {
              goToNextPage(8);
              setSignatureChange(true);
            }
          });
        } else {
          goToNextPage(8);
          setSignatureChange(true);;
        }
      }
    }
  };

  const deleteTheSignatureURL = async () => {
    if (!isCompletedOrCancelled) {
      if (
        window.confirm("Are you sure you want to delete this Signature?") === true
      ) {
        if (signatureURL?.id === 0) {
          setSignatureURL(null);
        } else {
          await axios
            .delete(`${baseURL}fileUploadData?id=${signatureURL?.id}`)
            .then(() => {
              setSignatureURL(null);
              setSignatureChange(true);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    }
  };

  const deleteTheInitialsURL = async () => {
    if (!isCompletedOrCancelled) {
      if (
        window.confirm("Are you sure you want to delete this Initials?") === true
      ) {
        if (initialsURL.id === 0) {
          setInitialsURL(null);
        } else {
          await axios
            .delete(`${baseURL}fileUploadData?id=${initialsURL?.id}`)
            .then(() => {
              setInitialsURL(null);
              setSignatureChange(true);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "24px",
        }}
      >
        <h5
          style={{
            paddingBlock: "0px",
            marginBlock: "0px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            cursor: "pointer",
            WebkitUserSelect: "none" /* Safari */,
            msUserSelect: "none" /* IE 10 and IE 11 */,
            userSelect: "none" /* Standard syntax */,
          }}
          onClick={() => setIsContract(!isContract)}
        >
          {isContract ? (
            <ExpandMoreIcon sx={{ fontSize: "20px" }} />
          ) : (
            <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
          )}{" "}
          Contract
        </h5>
        <hr />

        {isContract && (
          <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
            {fileUrl && (
              <embed
                src={`data:application/pdf;base64,${fileUrl}`}
                type="application/pdf"
                width="100%"
                height="600"
              />
            )}
          </div>
        )}

        <h5
          style={{
            paddingBottom: "0px",
            marginBottom: "0px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            cursor: "pointer",
            WebkitUserSelect: "none" /* Safari */,
            msUserSelect: "none" /* IE 10 and IE 11 */,
            userSelect: "none" /* Standard syntax */,
          }}
          onClick={() => setIsSignatures(!isSignatures)}
        >
          {isSignatures ? (
            <ExpandMoreIcon sx={{ fontSize: "20px" }} />
          ) : (
            <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
          )}{" "}
          Signatures
        </h5>
        <hr />

        {isSignatures && (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <div
                style={{
                  width: "50%",
                }}
              >
                <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Signature
                </p>
                <div
                  style={{
                    border: "1px dashed gray",
                    backgroundImage: `url('${baseURL}reservationFile/Get?name=${signatureURL?.fileName}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "auto"
                  }}
                >
                  <SignatureCanvas
                    ref={signaturePadRef}
                    penColor={colorConfigs.topbar.bg}
                    canvasProps={{
                      width: 500,
                      height: 150,
                      className: "sigCanvas",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <i
                    style={{
                      fontSize: "18px",
                      backgroundColor: "orange",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    className="bx bxs-eraser"
                    onClick={() => {
                      if (!isCompletedOrCancelled) {
                        signaturePadRef.current?.clear();
                        if (signatureURL) {
                          deleteTheSignatureURL();
                        }
                      }
                    }}
                  ></i>
                  <input
                    type="file"
                    accept="image/*"
                    ref={signatureFileRef}
                    onChangeCapture={onSignatureFileChangeCapture}
                    style={{ display: "none" }}
                  />
                  <i
                    style={{
                      fontSize: "18px",
                      backgroundColor: "skyblue",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    className="bx bx-upload"
                    onClick={() => {
                      if (!isCompletedOrCancelled) {
                        if (!signatureURL) {
                          if (signatureFileRef.current !== null) {
                            signatureFileRef.current.click();
                          }
                        } else {
                          if (signatureURL?.id === 0) {
                            if (signatureFileRef.current !== null) {
                              signatureFileRef.current.click();
                            }
                          } else {
                            window.alert("Please remove this Signature first.");
                          }
                        }
                      }
                    }}
                  ></i>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <p style={{ fontSize: "12px", fontWeight: "bold" }}>Initials</p>
                <div
                  style={{
                    border: "1px dashed gray",
                    backgroundImage: `url('${baseURL}reservationFile/Get?name=${initialsURL?.fileName}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "auto"
                  }}
                >
                  <SignatureCanvas
                    ref={initialsPadRef}
                    penColor={colorConfigs.topbar.bg}
                    canvasProps={{
                      width: 500,
                      height: 150,
                      className: "sigCanvas",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  <i
                    style={{
                      fontSize: "18px",
                      backgroundColor: "orange",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    className="bx bxs-eraser"
                    onClick={() => {
                      if (!isCompletedOrCancelled) {
                        initialsPadRef.current?.clear();
                        if (initialsURL) {
                          deleteTheInitialsURL();
                        }
                      }
                    }}
                  ></i>
                  <input
                    type="file"
                    accept="image/*"
                    ref={initialsFileRef}
                    onChangeCapture={onInitialsFileChangeCapture}
                    style={{ display: "none" }}
                  />
                  <i
                    style={{
                      fontSize: "18px",
                      backgroundColor: "skyblue",
                      color: "white",
                      padding: "3px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    className="bx bx-upload"
                    onClick={() => {
                      if (!isCompletedOrCancelled) {
                        if (!initialsURL) {
                          if (initialsFileRef.current !== null) {
                            initialsFileRef.current.click();
                          }
                        } else {
                          if (initialsURL?.id === 0) {
                            if (initialsFileRef.current !== null) {
                              initialsFileRef.current.click();
                            }
                          } else {
                            window.alert("Please remove this Inititals first.");
                          }
                        }
                      }
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <button
                style={{
                  marginBlock: "5px",
                  paddingBlock: "5px",
                  backgroundColor: "#66BB6A",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
                type="submit"
                onClick={() => uploadSignature()}
              >
                Confirm the Agreement
              </button>
            </div>
          </>
        )}

        <h5
          style={{
            paddingBottom: "0px",
            marginBottom: "0px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            WebkitUserSelect: "none" /* Safari */,
            msUserSelect: "none" /* IE 10 and IE 11 */,
            userSelect: "none" /* Standard syntax */,
          }}
          onClick={() => setIsContractModifications(!isContractModifications)}
        >
          {isContractModifications ? (
            <ExpandMoreIcon sx={{ fontSize: "20px" }} />
          ) : (
            <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
          )}{" "}
          Contract Modifications
        </h5>
        <hr />

        {isContractModifications && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginLeft: "22px"
            }}
          >
            <Button
              variant="contained"
              sx={{
                marginBlock: "16px",
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                border: "none",
                borderRadius: "0px",
                fontSize: "12px",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={() => {
                if (!isCompletedOrCancelled)
                  setAddExtensionIsOpen(true)
              }}
            >
              Extension
            </Button>

            <Button
              variant="contained"
              sx={{
                marginBlock: "16px",
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                border: "none",
                borderRadius: "0px",
                fontSize: "12px",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={() => {
                if (!isCompletedOrCancelled)
                  setAddEarlyReturnIsOpen(true);
              }}
            >
              Early Return
            </Button>

            <Button
              variant="contained"
              sx={{
                marginBlock: "16px",
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                border: "none",
                borderRadius: "0px",
                fontSize: "12px",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={() => {
                if (!isCompletedOrCancelled)
                  setReplaceVehicleIsOpen(!replaceVehicleIsOpen)
              }}
            >
              Replace Vehicle
            </Button>

            <Button
              variant="contained"
              sx={{
                marginBlock: "16px",
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                border: "none",
                borderRadius: "0px",
                fontSize: "12px",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}

            >
              Update Loaction
            </Button>

            <Button
              variant="contained"
              sx={{
                marginBlock: "16px",
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                border: "none",
                borderRadius: "0px",
                fontSize: "12px",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}
              onClick={() => {
                if (!isCompletedOrCancelled)
                  setAdjustmentIsOpen(true)
              }
              }
            >
              Charge or Adjustment
            </Button>

            <Button
              variant="contained"
              sx={{
                marginBlock: "16px",
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                border: "none",
                borderRadius: "0px",
                fontSize: "12px",
                textTransform: "capitalize",
                "&: hover": {
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                },
              }}

            >
              Disable Renewal
            </Button>
          </div>

        )}

        {adjustmentData.length > 0 && (
          <>
            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                WebkitUserSelect: "none" /* Safari */,
                msUserSelect: "none" /* IE 10 and IE 11 */,
                userSelect: "none" /* Standard syntax */,
              }}
              onClick={() => setAdjustmentListIsOpen(!adjustmentListIsOpen)}
            >
              {adjustmentListIsOpen ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Adjustments
            </h5>
            <hr />

            {adjustmentListIsOpen && (
              <AdjustmentList
                adjustmentData={adjustmentData}
                setAdjustmentRefresh={setAdjustmentRefresh}
                setIsExtraValueIsAdded={setIsExtraValueIsAdded}
                reservation={reservation}
                setReservationUpdate={setReservationUpdate}
              />
            )}
          </>
        )
        }

        {extensionData.length > 0 && (
          <>
            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                WebkitUserSelect: "none" /* Safari */,
                msUserSelect: "none" /* IE 10 and IE 11 */,
                userSelect: "none" /* Standard syntax */,
              }}
              onClick={() => setExtensionListIsOpen(!extensionListIsOpen)}
            >
              {extensionListIsOpen ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Extension
            </h5>
            <hr />

            {extensionListIsOpen && (
              <ExtensionList
                extensionData={extensionData}
                setExtensionRefresh={setExtensionRefresh}
              />
            )}
          </>
        )
        }
        {vehicleReplaceData.length > 0 && (
          <>
            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                WebkitUserSelect: "none" /* Safari */,
                msUserSelect: "none" /* IE 10 and IE 11 */,
                userSelect: "none" /* Standard syntax */,
              }}
              onClick={() => setVehicleReplaceListIsOpen(!vehicleReplaceListIsOpen)}
            >
              {vehicleReplaceListIsOpen ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Vehicle Replacements
            </h5>
            <hr />

            {vehicleReplaceListIsOpen && (
              <VehicleReplaceList
                vehicleReplaceData={vehicleReplaceData}
                setVehicleReplaceRefresh={setVehicleReplaceRefresh}
                reservation={reservation}
                setReservationUpdate={setReservationUpdate}
              />
            )}
          </>
        )}


        {earlyReturnData.length > 0 && (
          <>
            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                WebkitUserSelect: "none" /* Safari */,
                msUserSelect: "none" /* IE 10 and IE 11 */,
                userSelect: "none" /* Standard syntax */,
              }}
              onClick={() => setIsEarlyReturnOpen(!isEarlyReturnOpen)}
            >
              {isEarlyReturnOpen ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Early Return
            </h5>
            <hr />

            {isEarlyReturnOpen && (
              <EarlyReturnList
                earlyReturnData={earlyReturnData}
                setEarlyReturnRefresh={setEarlyReturnRefresh}
              />
            )}
          </>
        )
        }
      </div>


      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>

      <AddEarlyReturnModal
        addEarlyReturnIsOpen={addEarlyReturnIsOpen}
        setAddEarlyReturnIsOpen={setAddEarlyReturnIsOpen}
        reservation={reservation}
        setReservationUpdate={setReservationUpdate}
        setEarlyReturnRefresh={setEarlyReturnRefresh}
      />

      <AddReplaceVehicleModal
        replaceVehicleIsOpen={replaceVehicleIsOpen}
        setReplaceVehicleIsOpen={setReplaceVehicleIsOpen}
        reservation={reservation}
        setVehicleReplaceRefresh={setVehicleReplaceRefresh}
        setReservationUpdate={setReservationUpdate}
      />

      <AddAdjustmentModal
        adjustmentIsOpen={adjustmentIsOpen}
        setAdjustmentIsOpen={setAdjustmentIsOpen}
        reservation={reservation}
        setAdjustmentRefresh={setAdjustmentRefresh}
        setIsExtraValueIsAdded={setIsExtraValueIsAdded}
        setReservationUpdate={setReservationUpdate}
      />


      <AddExtensionModal
        addExtensionIsOpen={addExtensionIsOpen}
        setAddExtensionIsOpen={setAddExtensionIsOpen}
        setExtensionRefresh={setExtensionRefresh}
        reservation={reservation}
        setReservationUpdate={setReservationUpdate}
      />
    </>
  );
}
