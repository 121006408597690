import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { format } from "date-fns";
import { put_EarlyReturn } from "../../services/earlyReturn";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../configs/colorConfigs";
import PleaseWaitPopup from "../../pages/PleaseWaitPopup";

type EditEarlyReturnModalProps = {
    editEarlyReturnIsOpen: boolean;
    setEditEarlyReturnIsOpen: (value: boolean) => void;
    modalData: any,
    setEarlyReturnRefresh: (value: boolean) => void;
}

const EditEarlyReturnModal = ({ editEarlyReturnIsOpen, setEditEarlyReturnIsOpen, modalData, setEarlyReturnRefresh }: EditEarlyReturnModalProps) => {
    const { auth }: any = useAuth();
    const [oldReturnDate, setOldReturnDate] = useState<string>("");
    const [newReturnDate, setNewReturnDate] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [createdBy, setCreatedBy] = useState<string>("");
    const [pleaseWait, setPleaseWait] = useState<boolean>(false);

    useEffect(() => {
        if (editEarlyReturnIsOpen && modalData) {
            setNewReturnDate(format(new Date(modalData?.newReturenDate), "dd-MM-yyyy hh:mm aaaaa'm'"));
            setOldReturnDate(format(new Date(modalData?.oldReturenDate), "dd-MM-yyyy hh:mm aaaaa'm'"));
            setComment(modalData?.comment);
            setCreatedBy(modalData?.createdBy);
        }
    }, [modalData, editEarlyReturnIsOpen])

    const handleSubmit = () => {
        setPleaseWait(true)

        put_EarlyReturn({
            ...modalData,
            comment: comment,
            updateBy: auth?.givenname,
            updateAt: new Date(),
        }, auth?.id)
            .then((res) => {
                setPleaseWait(false)
                setEditEarlyReturnIsOpen(false);
                setEarlyReturnRefresh(true);
            })
            .catch((error) => {
                console.error("Error:", error)
            })
    }

    return (
        <>
            <Dialog
                open={editEarlyReturnIsOpen}
                PaperProps={{
                    style: {
                        marginTop: "-14%",
                        width: "500px"
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Edit Early Return
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                            onClick={() => setEditEarlyReturnIsOpen(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>
                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "16px",
                        }}>
                            <div style={{
                                width: "50%",

                            }}>
                                <label
                                    htmlFor="amount"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    New Return Date
                                    <br />
                                    <span style={{ fontWeight: "lighter" }}>{newReturnDate}</span>
                                </label>
                            </div>

                            <div style={{ width: "50%" }}>
                                <label
                                    htmlFor="amount"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Old Return Date
                                    <br />
                                    <span style={{ fontWeight: "lighter" }}>{oldReturnDate}</span>
                                </label>
                            </div>

                        </div>

                        <div style={{ width: "100%" }}>
                            <label
                                htmlFor="amount"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Created By
                                <br />
                                <span style={{ fontWeight: "lighter" }}>{createdBy}</span>
                            </label>
                        </div>

                        <div style={{ width: "100%" }}>
                            <label
                                htmlFor="description"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Comments
                                <br />
                                <textarea
                                    name="comments"
                                    rows={4}
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    required
                                />
                            </label>
                        </div>



                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => {
                                    setEditEarlyReturnIsOpen(false);
                                }}
                            >
                                Cancel
                            </Button>


                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "#FFFFFF",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "#FFFFFF",
                                    },
                                }}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <PleaseWaitPopup pleaseWaitPopup={pleaseWait} />
        </>
    )
}

export default EditEarlyReturnModal