import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@mui/icons-material';
import colorConfigs from "../../../configs/colorConfigs";
import { Button, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import baseURL from "../../../configs/api";
import PleaseWaitPopup from "../../PleaseWaitPopup";
import { useNavigate } from "react-router";
import SendQuotes from "./SendQuotes";
import { delete_ReservationAdditionalCharge, post_ReservationAdditionalCharge } from "../../../services/reservationAdditionalCharge";
import { post_reservationDiscountDiscount } from "../../../services/reservationDiscount";
import useAuth from "../../../components/hooks/useAuth";

type Props = {
  goToNextPage: (page: number) => void;
  getDataFromExtras: (totalAmount: number, extraValue: any[]) => void;
  reservation: any;
  reservationUpdate: () => void;
  extraValueData: any[];
  selectedVehicleClass: any;
  discountUpdate: () => void;
  branchId: string;
  vehicleClassId: string;
  pickupDate: string;
  pickupTime: string;
  returnDate: string;
  returnTime: string;
  pickupLocation: string;
  returnLocation: string;
  totalAmountAfterDiscount: number;
  totalOfSelectedVehicleClass: number;
  discountOptions: any[]
  discountTotalAmount: number;
  setDiscountList: (discountDefinition: string, discountAmount: number) => void;
  securityDepositAmountToPaid: string;
  isCompletedOrCancelled: boolean;
};

export default function Extras({
  goToNextPage,
  getDataFromExtras,
  reservation,
  reservationUpdate,
  extraValueData,
  selectedVehicleClass,
  discountUpdate,
  branchId,
  vehicleClassId,
  pickupDate,
  pickupTime,
  returnDate,
  returnTime,
  pickupLocation,
  returnLocation,
  totalAmountAfterDiscount,
  totalOfSelectedVehicleClass,
  discountOptions,
  discountTotalAmount,
  setDiscountList,
  securityDepositAmountToPaid,
  isCompletedOrCancelled
}: Props) {
  const navigate = useNavigate();
  const { auth }: any = useAuth();

  const [extraValue, setExtraValue] = useState<any[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0.0);
  const [checkExtraValue, setCheckExtraValue] = useState<any[]>([]);
  const [firstTimeSummaryUpdate, setFirstTimeSummaryUpdate] =
    useState<boolean>(false);

  //Discount
  const [couponCode, setCouponCode] = useState<string>("");
  const [customDiscountValue, setCustomDiscountValue] = useState<string>("");
  const [manualDiscount, setManualDiscount] = useState<string>("");
  const [isManualDiscountInAPercentage, setIsManualDiscountInAPercentage] =
    useState<boolean>(false);

  //Quotes
  const [isCreatingPdf, setIsCreatingPdf] = useState<boolean>(true);
  const [sendEmailPopUp, setSendEmailPopUp] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isCompletedOrCancelled)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [pleaseWait, setPleaseWait] = useState<boolean>(false);

  useEffect(() => {
    if (extraValue && !reservation) {
      setCheckExtraValue(() =>
        extraValue
          .filter((item: any) => item.isChecked)
          .map((value: any) => {
            return {
              ...value,
              amountOrPercentage:
                value.selectionType === "Only One"
                  ? value.amountOrPercentage
                  : parseFloat(selectedVehicleClass?.days) *
                  parseFloat(value.amountOrPercentage),
            };
          })
      );
    }
  }, [extraValue]);

  useEffect(() => {
    if (totalOfSelectedVehicleClass) {
      setExtraValue(() =>
        extraValueData.map((value: any) => {
          return {
            ...value,
            amountOrPercentage:
              value.chargeType === "Amount"
                ? value.amountOrPercentage
                : (totalOfSelectedVehicleClass *
                  parseFloat(value.amountOrPercentage)) /
                100,
          };
        })
      );
    }
  }, [totalOfSelectedVehicleClass, extraValueData]);

  useEffect(() => {
    if (extraValue && reservation && firstTimeSummaryUpdate) {
      extraValue.forEach((item: any) => {
        if (item.isChecked) {
          if (item?.reservationAdditionalId === 0) {
            addAdditionalCharge(item);
          }
        } else {
          if (item?.isDelete) {
            deleteAdditionalCharge(
              item.reservationAdditionalId,
              item.selectionType === "Only One"
                ? item.amountOrPercentage
                : parseFloat(selectedVehicleClass?.days) *
                parseFloat(item.amountOrPercentage)
            );
          }
        }
      });
    }
  }, [extraValue]);

  const addAdditionalCharge = async (value: any) => {
    if (!isCompletedOrCancelled) {
      setPleaseWait(true);
      const amountOrPercentage =
        value.selectionType === "Only One"
          ? value.amountOrPercentage
          : parseFloat(selectedVehicleClass?.days) *
          parseFloat(value.amountOrPercentage);

      await post_ReservationAdditionalCharge(
        JSON.stringify({
          reservatinId: reservation?.id,
          additionalChargeByBranchId: value.id,
          quantity: 1,
          basePrice: amountOrPercentage,
        })
      )
        .then(data => {
          setCheckExtraValue(() =>
            extraValue.filter((item: any) => item.isChecked)
              .map((value: any) => {
                return {
                  ...value,
                  reservationAdditionalId: data,
                  amountOrPercentage: amountOrPercentage,
                };
              })
          );
          updateReservation(amountOrPercentage, false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const deleteAdditionalCharge = async (id: number, amount: number) => {
    if (!isCompletedOrCancelled) {
      setPleaseWait(true);
      await delete_ReservationAdditionalCharge(id)
        .then(data => updateReservation(amount, true))
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const updateReservation = async (amount: number, isDelete: boolean) => {
    if (!isCompletedOrCancelled) {
      await axios
        .put(
          `${baseURL}reservation`,
          JSON.stringify({
            ...reservation,
            totalPrice: isDelete
              ? parseFloat(reservation?.totalPrice) - amount
              : parseFloat(reservation?.totalPrice) + amount,
            outstandingBalance: isDelete
              ? parseFloat(reservation?.outstandingBalance) - amount
              : parseFloat(reservation?.outstandingBalance) + amount,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          setPleaseWait(false);
          reservationUpdate();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    if (checkExtraValue) {
      let checkAmount = 0.0;
      checkExtraValue.forEach(
        (item: any) => (checkAmount += parseFloat(item.amountOrPercentage))
      );
      getDataFromExtras(checkAmount, checkExtraValue);
      setTotalAmount(() => checkAmount);
    }
  }, [checkExtraValue]);

  const handleDiscount = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isCompletedOrCancelled) {

      setPleaseWait(true);

      let amount = 0.0;

      if (couponCode) {
        amount =
          discountOptions.find((item: any) => item.id === parseInt(couponCode))
            ?.discounttype === "Amount"
            ? parseFloat(
              discountOptions.find(
                (item: any) => item.id === parseInt(couponCode)
              )?.discountAmount
            )
            : (totalOfSelectedVehicleClass *
              parseFloat(
                discountOptions.find(
                  (item: any) => item.id === parseInt(couponCode)
                )?.discountAmount
              )) /
            100;
        addDiscount(
          discountOptions.find((item: any) => item.id === parseInt(couponCode))
            ?.name,
          discountOptions.find((item: any) => item.id === parseInt(couponCode))
            ?.discounttype,
          amount,
          false
        );
      } else if (customDiscountValue) {
        addDiscount(
          "Manual Discount",
          "Manual",
          parseFloat(customDiscountValue),
          true
        );
      } else if (manualDiscount) {
        amount = isManualDiscountInAPercentage
          ? (totalOfSelectedVehicleClass *
            parseFloat(manualDiscount)) /
          100
          : parseFloat(manualDiscount);
        addDiscount(
          "Manual Discount",
          isManualDiscountInAPercentage ? "Percentage" : "Amount",
          amount,
          true
        );
      } else {
        if (reservation)
          discountUpdate();
      }
    }
  }

  const addDiscount = async (
    discountDefinition: string,
    discountType: string,
    discountAmount: number,
    manualDiscount: boolean
  ) => {
    if (!isCompletedOrCancelled) {
      if (reservation) {
        await post_reservationDiscountDiscount(reservation?.id, discountDefinition, discountType, discountAmount, manualDiscount, auth)
          .then(data => {
            setCouponCode("");
            setCustomDiscountValue("");
            setManualDiscount("");
            setIsManualDiscountInAPercentage(false);
            updateReservationForDiscount(discountAmount);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        setPleaseWait(false);
        setDiscountList(discountDefinition, discountAmount);
      }
    }
  };

  const updateReservationForDiscount = async (amount: number) => {
    if (!isCompletedOrCancelled) {
      await axios
        .put(
          `${baseURL}reservation`,
          JSON.stringify({
            ...reservation,
            totalPrice: parseFloat(reservation?.totalPrice) - amount,
            outstandingBalance:
              parseFloat(reservation?.outstandingBalance) - amount,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          setPleaseWait(false);
          discountUpdate();
          reservationUpdate();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "24px",
      }}
    >
      {extraValue
        .filter((value: any) => !value.mandatory)
        .map((item: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <h5
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  marginTop: index === 0 ? "0px" : "20px",
                }}
              >
                {item?.categoryName}
              </h5>
              <hr />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: "16px",
                  marginRight: "16px",
                  marginBlock: "16px",
                }}
              >
                <p style={{ paddingBottom: "0px", marginBlock: "0px" }}>
                  {item?.name}

                  {item?.recommended && (
                    <strong
                      style={{
                        color: "white",
                        backgroundColor: "#4CAF50",
                        fontSize: "10px",
                        padding: "5px 5px 5px 16px",
                        marginLeft: "8px",
                        borderTopLeftRadius: "50%",
                        borderBottomLeftRadius: "50%",
                      }}
                    >
                      Recommended
                    </strong>
                  )}
                </p>

                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    padding: "8px",
                    width: "200px",
                  }}
                >
                  <input
                    type="checkbox"
                    name={"checkbox" + index}
                    checked={item?.isChecked ? true : false}
                    onChange={() => {
                      if (!isCompletedOrCancelled) {
                        if (reservation) setFirstTimeSummaryUpdate(true);
                        setExtraValue((extraValue) =>
                          extraValue.map((value: any) => {
                            if (value.id === item.id) {
                              return {
                                ...value,
                                isChecked: !value.isChecked,
                                isDelete:
                                  value?.reservationAdditionalId !== 0
                                    ? true
                                    : false,
                              };
                            }

                            return {
                              ...value,
                              isDelete: false,
                            };
                          })
                        );
                      }
                    }
                    }
                  />
                  ${parseFloat(item?.amountOrPercentage).toFixed(2)}
                  {item.selectionType === "Only One" ? " One Time" : "/Day"}
                  {/* <EditIcon
                    style={{
                      color: "#4FC3F7",
                      fontSize: "14px",
                      marginLeft: "3px",
                    }}
                  /> */}
                </div>
              </div>

              <hr style={{ marginLeft: "16px", marginRight: "16px" }} />
            </React.Fragment>
          );
        })}

      <form onSubmit={(e) => handleDiscount(e)}>
        <h5
          style={{
            paddingBottom: "0px",
            marginBottom: "0px",
            marginTop: "20px",
          }}
        >
          Discount
        </h5>
        <hr />

        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div style={{ width: "50%" }}>
              <label
                htmlFor="couponCode"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Coupon Code
                <select
                  name="couponCode"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                >
                  <option value="">Select...</option>
                  {discountOptions.map((item: any) => {
                    return <option key={item.id} value={item.id}>{item.name}</option>;
                  })}
                </select>
              </label>

              <label
                htmlFor="manualDiscount"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Manual Discount($)
                <input
                  type="text"
                  name="manualDiscount"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={manualDiscount}
                  onChange={(e) => setManualDiscount(e.target.value)}
                />
              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="customDiscountValue"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Custom Discount Value
                <input
                  type="number"
                  name="customDiscountValue"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={customDiscountValue}
                  onChange={(e) => setCustomDiscountValue(e.target.value)}
                />
              </label>
              <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                Manual Discount is a Percentage?
              </label>{" "}
              <br />
              <label style={{ fontSize: "12px" }}>
                <input
                  type="radio"
                  value="Yes"
                  name="isManualDiscountInAPercentage"
                  style={{ fontSize: "12px" }}
                  checked={isManualDiscountInAPercentage ? true : false}
                  onChange={(e) =>
                    setIsManualDiscountInAPercentage(
                      e.target.value === "Yes" ? true : false
                    )
                  }
                />
                Yes
              </label>
              <label style={{ fontSize: "12px" }}>
                <input
                  type="radio"
                  value="No"
                  name="isManualDiscountInAPercentage"
                  style={{ fontSize: "12px" }}
                  checked={isManualDiscountInAPercentage ? false : true}
                  onChange={(e) =>
                    setIsManualDiscountInAPercentage(
                      e.target.value === "Yes" ? true : false
                    )
                  }
                />
                No
              </label>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            marginTop: "16px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              color: "white",
              borderRadius: "0",
              border: "none",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            type="submit"
          >
            Update Order
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              color: "white",
              borderRadius: "0",
              border: "none",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            onClick={() => {
              getDataFromExtras(totalAmount, checkExtraValue);
              goToNextPage(3);
            }}
          >
            Next Step
          </Button>

          <div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#e0dee0",
                color: "black",
                borderRadius: "0",
                border: "none",
                textTransform: "capitalize",
                textAlign: "center",
                fontSize: "12px",
                padding: "6px 4px 6px 16px",
                "&: hover": {
                  backgroundColor: "#e0dee0",
                  color: "black",
                  border: "none",
                },
              }}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Quote {open ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              sx={{ marginBlock: "0px" }}
            >
              <MenuItem
                sx={{ fontSize: "12px", marginBlock: "0px" }}
                onClick={() => {
                  setSendEmailPopUp(true);
                  setIsCreatingPdf(true);
                }}>Create Pdf</MenuItem>
              <MenuItem
                sx={{ fontSize: "12px" }}
                onClick={() => {
                  setSendEmailPopUp(true);
                  setIsCreatingPdf(false);
                }}>Send Email</MenuItem>
            </Menu>
          </div>

        </div>
      </form>

      <PleaseWaitPopup pleaseWaitPopup={pleaseWait} />

      <SendQuotes
        sendEmailPopUp={sendEmailPopUp}
        hideSendEmailPopup={() => setSendEmailPopUp(false)}
        branchId={branchId}
        vehicleClassId={vehicleClassId}
        pickupDate={pickupDate}
        pickupTime={pickupTime}
        returnDate={returnDate}
        returnTime={returnTime}
        pickupLocation={pickupLocation}
        returnLocation={returnLocation}
        totalAmountAfterDiscount={totalAmountAfterDiscount}
        totalOfSelectedVehicleClass={totalOfSelectedVehicleClass}
        discountTotalAmount={discountTotalAmount}
        securityDepositAmountToPaid={securityDepositAmountToPaid}
        isCreatingPdf={isCreatingPdf}
      />
    </div>
  );
}
