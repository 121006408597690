import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import useAuth from '../../../components/hooks/useAuth';
import useAttachments from '../../../components/hooks/useAttachments';
import useDelete from '../../../components/hooks/useDelete';
import { appName, ticketBaseURL } from '../../../configs/api';
import { getTicketStatusesByAttribute } from '../../../services/TicketStatus';
import { upload_CommonFileSupportTicket } from '../../../services/commonFile';
import { post_Incident } from '../../../services/Tickets';
import { post_fileUpload } from '../../../services/FileUpload';
import { format } from 'date-fns';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import colorConfigs from '../../../configs/colorConfigs';

const NewSupportTicket = () => {
    const navigate = useNavigate();
    const { auth }: any = useAuth();
    const { setViewImages, setAttachments, setCurrentIndex }: any = useAttachments();
    const { setDeletePopup, setData: setDeleteData }: any = useDelete();
    const [supportTicketData, setSupportTicketData] = useState<any>({
        accountId: 0,
        incidentName: "",
        status: "2",
        requester: "",
        description: "",
        categoryId: 0,
        subcategoryId: 0,
        assignedToUserId: 0,
        assignedToGroupAssignmentId: 0,
        priority: "",
        dueDate: "",
        emailCC: "",
        expectedRevenue: 0,
        groupId: "0",
        appGroupId: "0",
        appName: appName,
        createdAt: "",
        createdBy: "",
        updatedAt: "",
        updatedBy: ""
    });
    const [priorityData, setPriorityData] = useState<any[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const groupId = Cookies.get("groupId-jj-rental-ui") || "";
    const [isDataUploading, setIsDataUploading] = useState<boolean>(false);
    const [attachmentsValue, setAttachmentsValue] = useState<any[]>([]);
    const [key, setKey] = useState<number>(0);

    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getTicketStatusesByAttribute('Priority')
            .then((res) => {
                const data = res?.data.reverse()
                    ?.map((item: any) => {
                        return {
                            value: item?.id,
                            label: item?.status
                        }
                    });
                setPriorityData(() => data);

                setSupportTicketData({ ...supportTicketData, priority: data?.length > 0 ? data[0]?.value : 0, });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDataUploading) {
            const selectedFiles = e.target.files as FileList;

            if (selectedFiles.length !== 0) {
                saveImageFile(selectedFiles[0]);
                if (fileRef.current) {
                    fileRef.current.value = '';
                    setKey((prevKey) => prevKey + 1);
                }
            }
        }
    };

    const saveImageFile = (imageFile: File) => {
        setIsDataUploading(true);
        const formData = new FormData();
        formData.append("ImageFile", imageFile);

        upload_CommonFileSupportTicket(formData)
            .then(res => {
                setIsDataUploading(false);
                setAttachmentsValue((prevValue) => ([...prevValue, {
                    fileName: res,
                    createdDate: new Date()
                }]));
            })
            .catch(error => console.error(error))
    }

    const viewImage = (index: number) => {
        setViewImages(true);
        setAttachments(attachmentsValue)
        setCurrentIndex(index);
    }

    const deleteImage = (id: number) => {
        setDeleteData({
            id: 0,
            tilte: `Delete`,
            summary: `Are you sure you want to delete this Attachment?`,
            isDelete: (callback: any) => {
                setAttachmentsValue(() => attachmentsValue?.filter((_: any, index: number) => id !== index));
                callback && callback();
            }
        });

        setDeletePopup(true);
    }

    const closeModal = () => navigate(-1);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isProcessing) {
            setIsProcessing(true);

            post_Incident({
                ...supportTicketData,
                status: "2",
                requester: auth?.givenname,
                priority: supportTicketData.priority,
                appGroupId: groupId,
                appName: appName,
                createdAt: new Date().toISOString(),
                createdBy: auth?.givenname,
                updatedAt: new Date().toISOString(),
                updatedBy: auth?.givenname,
            }, auth?.id)
                .then((ticketId) => {
                    return fileUpload(ticketId);
                })
                .then(() => {
                    closeModal();
                    setIsProcessing(false);
                })
                .catch((error) => {
                    console.error('Error creating ticket or uploading files:', error);
                    setIsProcessing(false);
                });
        }
    }

    const fileUpload = (ticketId: any) => {
        return new Promise<void>((resolve, reject) => {
            if (attachmentsValue?.length > 0) {
                let count = 0;
                attachmentsValue?.forEach((attachment: any) => {
                    post_fileUpload({
                        attributeName: "Incidents",
                        attributeId: ticketId,
                        fileName: attachment?.fileName,
                        tabName: "Incidents",
                        userId: auth?.id,
                        createdDate: new Date()
                    })
                        .then(() => {
                            count++;
                            if (count === attachmentsValue.length) {
                                resolve();
                            }
                        })
                        .catch((error) => {
                            console.error('File upload failed:', error);
                            reject(error);
                        });
                });
            } else {
                resolve();
            }
        });
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ margin: '0.5rem 0' }}>
                <div style={{ padding: '2rem 1.5rem', position: 'relative', backgroundColor: '#fff' }}>
                    <h2 style={{ fontSize: '1.25rem', color: colorConfigs.primaryColor, fontWeight: 'bold' }}>Create Support Ticket</h2>
                    <form onSubmit={handleSubmit} style={{ marginTop: '2rem', display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '1.5rem', color: '#007bff' }}>
                        <div style={{ gridColumn: 'span 2 / span 2' }}>
                            <label style={{ fontWeight: '600', fontSize: '0.875rem', color: colorConfigs.primaryColor }}>Subject</label>
                            <input
                                type='text'
                                placeholder='Enter Subject'
                                style={{
                                    width: '100%',
                                    borderRadius: '0.375rem',
                                    padding: '0.625rem 1rem',
                                    marginTop: '0.5rem',
                                    border: '2px solid #ccc',
                                    fontSize: '0.875rem',
                                    color: '#000',
                                    outlineColor: '#007bff',
                                }}
                                value={supportTicketData.incidentName}
                                onChange={(e) => setSupportTicketData({ ...supportTicketData, incidentName: e.target.value })}
                                required
                            />
                        </div>

                        <div style={{ gridColumn: 'span 2 / span 2' }}>
                            <label style={{ fontWeight: '600', fontSize: '0.875rem', color: colorConfigs.primaryColor }}>Description</label>
                            <textarea
                                placeholder='Description'
                                style={{
                                    width: '100%',
                                    borderRadius: '0.375rem',
                                    padding: '0.625rem 1rem',
                                    marginTop: '0.5rem',
                                    border: '2px solid #ccc',
                                    fontSize: '0.875rem',
                                    color: '#000',
                                    outlineColor: '#007bff',
                                }}
                                value={supportTicketData.description}
                                onChange={(e) => setSupportTicketData({ ...supportTicketData, description: e.target.value })}
                            />
                        </div>

                        <div style={{ gridColumn: 'span 2 / span 2' }}>
                            <label style={{ fontWeight: '600', fontSize: '0.875rem', color: colorConfigs.primaryColor }}>Priority</label>
                            <select
                                style={{
                                    width: '100%',
                                    borderRadius: '0.375rem',
                                    padding: '0.625rem 1rem',
                                    marginTop: '0.5rem',
                                    border: '2px solid #ccc',
                                    fontSize: '0.875rem',
                                    color: '#000',
                                    outlineColor: '#007bff',
                                }}
                                value={supportTicketData.priority}
                                onChange={(e) => {
                                    setSupportTicketData({ ...supportTicketData, priority: e.target.value });
                                }}
                            >
                                {priorityData?.map((item, ind) => (
                                    <option key={ind} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>

                        <div style={{ gridColumn: 'span 2 / span 2' }}>
                            <span style={{ fontSize: '0.875rem', color: colorConfigs.primaryColor, fontWeight: '600' }}>{"Attachment"}</span>
                            <button
                                key={key}
                                type="button"
                                onClick={() => fileRef.current?.click()}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    padding: '0.375rem 0.75rem',
                                    marginTop: '0.5rem',
                                    borderRadius: '0.375rem',
                                    color: '#6c757d',
                                    fontSize: '0.875rem',
                                    fontWeight: '600',
                                    border: '1px solid #6c757d',
                                    backgroundColor: '#fff',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    opacity: isDataUploading ? '0.8' : '1',
                                }}
                            >
                                {isDataUploading ? (
                                    <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18px"
                                            className="mr-2 fill-tertiary inline animate-spin"
                                            viewBox="0 0 26.349 26.35"
                                        >
                                            <circle cx="13.792" cy="3.082" r="3.082" />
                                            <circle cx="13.792" cy="24.501" r="1.849" />
                                        </svg>
                                        Processing...
                                    </>
                                ) : (
                                    <>
                                        <CloudUploadIcon style={{ color: colorConfigs.primaryColor }} />
                                        <span style={{ color: colorConfigs.primaryColor }}>Add Attachment</span>
                                    </>
                                )}
                            </button>
                            <input
                                ref={fileRef}
                                type="file"
                                accept="image/png, image/jpeg"
                                name="image-choose"
                                style={{ display: 'none' }}
                                onChangeCapture={onFileChangeCapture}
                            />
                        </div>

                        {attachmentsValue?.length !== 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '1rem',
                                    gridColumn: 'span 2 / span 2',
                                }}
                            >
                                {attachmentsValue?.map((_, index) => (
                                    <div
                                        key={index}
                                        style={{ position: 'relative', width: '10rem', height: '10rem' }}
                                        onMouseEnter={(e) => {
                                            const deleteIcon = e.currentTarget.querySelector('.delete-icon') as HTMLElement;
                                            if (deleteIcon) deleteIcon.style.visibility = 'visible';
                                        }}
                                        onMouseLeave={(e) => {
                                            const deleteIcon = e.currentTarget.querySelector('.delete-icon') as HTMLElement;
                                            if (deleteIcon) deleteIcon.style.visibility = 'hidden';
                                        }}
                                    >
                                        <div
                                            className="delete-icon"
                                            style={{
                                                position: 'absolute',
                                                top: '0.25rem',
                                                right: '0.25rem',
                                                backgroundColor: '#e2e8f0',
                                                borderRadius: '0.25rem',
                                                cursor: 'pointer',
                                                color: colorConfigs.primaryColor,
                                                visibility: 'hidden',
                                            }}
                                            onClick={() => deleteImage(index)}
                                        >
                                            <DeleteIcon />
                                        </div>

                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '0.375rem',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => viewImage(index)}
                                        >
                                            <img
                                                style={{
                                                    width: '100%',
                                                    height: '6rem',
                                                    borderRadius: '0.375rem',
                                                }}
                                                src={`${ticketBaseURL}commonFile/Get?name=${_?.fileName}`}
                                                alt={_?.fileName}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#f7fafc',
                                                    width: '100%',
                                                    fontSize: '0.75rem',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '0.375rem 0.75rem',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: colorConfigs.primaryColor,
                                                    }}
                                                >
                                                    {_?.fileName}
                                                </span>
                                                <span style={{ color: colorConfigs.primaryColor }}>
                                                    {format(new Date(_?.createdDate), 'dd MMM yyyy, KK:mm a')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem', flexDirection: 'row' }}>
                            <button
                                type="button"
                                style={{
                                    padding: '0.5rem 1.5rem',
                                    minWidth: '150px',
                                    borderRadius: '0.375rem',
                                    color: '#000',
                                    fontSize: '0.875rem',
                                    fontWeight: '600',
                                    backgroundColor: '#e2e8f0',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    border: 'none',
                                }}
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                style={{
                                    padding: '0.5rem 1.5rem',
                                    minWidth: '150px',
                                    borderRadius: '0.375rem',
                                    color: '#fff',
                                    fontSize: '0.875rem',
                                    fontWeight: '600',
                                    backgroundColor: colorConfigs.primaryColor,
                                    outline: 'none',
                                    cursor: 'pointer',
                                    border: 'none',
                                }}
                            >
                                {isProcessing ? (
                                    <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18px"
                                            fill="#fff"
                                            className="mr-2 inline animate-spin"
                                            viewBox="0 0 26.349 26.35"
                                        >
                                            <circle cx="13.792" cy="3.082" r="3.082" />
                                            <circle cx="13.792" cy="24.501" r="1.849" />
                                        </svg>
                                        Processing...
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default NewSupportTicket