import React, { useEffect, useState } from 'react'
import AmountPresentation from './AmountPresentation'
import { getDashboardBooking } from '../../services/dashboardBooking'
import { PieChart } from '../../components/charts/piechart/PieChart'
import moment from 'moment'
import BarChart from '../../components/charts/barchart/BarChart'

type Props = {
  startDate: string;
  endDate: string
}

export default function Bookings({ startDate, endDate }: Props) {
  const [bookingData, setBookingData] = useState<any>(null)
  const [bookedForDay, setBookedForDay] = useState<any>(null)
  const [bookedForStatus, setBookedForStatus] = useState<any>(null)
  const [bookedForCountry, setBookedForCountry] = useState<any>(null)
  const [bookedForState, setBookedForState] = useState<any>(null)
  const [bookedForPerPickupLocation, setBookedForPerPickupLocation] = useState<any>(null)

  useEffect(() => {
    getDashboardBooking(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
      .then(data => setBookingData(data))
      .catch(error => console.error(error))
  }, [startDate, endDate])

  useEffect(() => {
    if (bookingData !== null) {
      setBookedForDay([
        { name: 'Sunday', value: bookingData?.bookingsPerDay?.sunday },
        { name: 'Monday', value: bookingData?.bookingsPerDay?.monday },
        { name: 'Tuesday', value: bookingData?.bookingsPerDay?.tuesday },
        { name: 'Wednesday', value: bookingData?.bookingsPerDay?.wednesday },
        { name: 'Thursday', value: bookingData?.bookingsPerDay?.thursday },
        { name: 'Friday', value: bookingData?.bookingsPerDay?.friday },
        { name: 'Saturday', value: bookingData?.bookingsPerDay?.saturday }
      ]);
    }
  }, [bookingData])

  useEffect(() => {
    if (bookingData !== null) {
      setBookedForStatus([
        { name: 'No Show', value: bookingData?.bookingsPerStatus?.noShow },
        { name: 'Completed', value: bookingData?.bookingsPerStatus?.completed },
        { name: 'Rental', value: bookingData?.bookingsPerStatus?.rental },
        { name: 'Open', value: bookingData?.bookingsPerStatus?.open },
        { name: 'Cancelled Paid', value: bookingData?.bookingsPerStatus?.cancelledPaid },
        { name: 'Cancelled', value: bookingData?.bookingsPerStatus?.cancelled }
      ]);
    }
  }, [bookingData])

  useEffect(() => {
    if (bookingData !== null) {
      setBookedForCountry([
        { name: 'United State', value: bookingData?.bookingsPerCountry?.unitedState },
        { name: 'Canada', value: bookingData?.bookingsPerCountry?.canada },
        { name: 'Australia', value: bookingData?.bookingsPerCountry?.australia },
        { name: 'United Kingdom', value: bookingData?.bookingsPerCountry?.unitedKingdom },
        { name: 'Germany', value: bookingData?.bookingsPerCountry?.germany },
        { name: 'France', value: bookingData?.bookingsPerCountry?.france },
        { name: 'China', value: bookingData?.bookingsPerCountry?.china },
        { name: 'India', value: bookingData?.bookingsPerCountry?.india },
        { name: 'Argentina', value: bookingData?.bookingsPerCountry?.argentina }
      ]);
    }
  }, [bookingData])

  useEffect(() => {
    if (bookingData !== null) {
      setBookedForState([
        { name: "Alabama", value: bookingData?.bookingsPerState?.alabama },
        { name: "Alaska", value: bookingData?.bookingsPerState?.alaska },
        { name: "Arizona", value: bookingData?.bookingsPerState?.arizona },
        { name: "Arkansas", value: bookingData?.bookingsPerState?.arkansas },
        { name: "California", value: bookingData?.bookingsPerState?.california },
        { name: "Colorado", value: bookingData?.bookingsPerState?.colorado },
        { name: "Connecticut", value: bookingData?.bookingsPerState?.connecticut },
        { name: "Delaware", value: bookingData?.bookingsPerState?.delaware },
        { name: "Florida", value: bookingData?.bookingsPerState?.florida },
        { name: "Georgia", value: bookingData?.bookingsPerState?.georgia },
        { name: "Hawaii", value: bookingData?.bookingsPerState?.hawaii },
        { name: "Idaho", value: bookingData?.bookingsPerState?.idaho },
        { name: "Illinois", value: bookingData?.bookingsPerState?.illinois },
        { name: "Indiana", value: bookingData?.bookingsPerState?.indiana },
        { name: "Iowa", value: bookingData?.bookingsPerState?.iowa },
        { name: "Kansas", value: bookingData?.bookingsPerState?.kansas },
        { name: "Kentucky", value: bookingData?.bookingsPerState?.kentucky },
        { name: "Louisiana", value: bookingData?.bookingsPerState?.louisiana },
        { name: "Maine", value: bookingData?.bookingsPerState?.maine },
        { name: "Maryland", value: bookingData?.bookingsPerState?.maryland },
        { name: "Massachusetts", value: bookingData?.bookingsPerState?.massachusetts },
        { name: "Michigan", value: bookingData?.bookingsPerState?.michigan },
        { name: "Mississippi", value: bookingData?.bookingsPerState?.mississippi },
        { name: "Missouri", value: bookingData?.bookingsPerState?.missouri },
        { name: "Montana", value: bookingData?.bookingsPerState?.montana },
        { name: "Nebraska", value: bookingData?.bookingsPerState?.nebraska },
        { name: "Nevada", value: bookingData?.bookingsPerState?.nevada },
        { name: "New Hampshire", value: bookingData?.bookingsPerState?.newHampshire },
        { name: "New Jersey", value: bookingData?.bookingsPerState?.newJersey },
        { name: "New Mexico", value: bookingData?.bookingsPerState?.newMexico },
        { name: "New York", value: bookingData?.bookingsPerState?.newYork },
        { name: "North Carolina", value: bookingData?.bookingsPerState?.northCarolina },
        { name: "North Dakota", value: bookingData?.bookingsPerState?.northDakota },
        { name: "Ohio", value: bookingData?.bookingsPerState?.ohio },
        { name: "Oklahoma", value: bookingData?.bookingsPerState?.oklahoma },
        { name: "Oregon", value: bookingData?.bookingsPerState?.oregon },
        { name: "Pennsylvania", value: bookingData?.bookingsPerState?.pennsylvania },
        { name: "Rhode Island", value: bookingData?.bookingsPerState?.rhodeIsland },
        { name: "South Carolina", value: bookingData?.bookingsPerState?.southCarolina },
        { name: "South Dakota", value: bookingData?.bookingsPerState?.southDakota },
        { name: "Tennessee", value: bookingData?.bookingsPerState?.tennessee },
        { name: "Texas", value: bookingData?.bookingsPerState?.texas },
        { name: "Utah", value: bookingData?.bookingsPerState?.utah },
        { name: "Vermont", value: bookingData?.bookingsPerState?.vermont },
        { name: "Virginia", value: bookingData?.bookingsPerState?.virginia },
        { name: "Washington", value: bookingData?.bookingsPerState?.washington },
        { name: "West Virginia", value: bookingData?.bookingsPerState?.westVirginia },
        { name: "Wisconsin", value: bookingData?.bookingsPerState?.wisconsin },
        { name: "Wyoming", value: bookingData?.bookingsPerState?.wyoming }
      ]);
    }
  }, [bookingData])

  useEffect(() => {
    if (bookingData !== null) {
      setBookedForPerPickupLocation([
        { name: 'IAH Airport', value: bookingData?.bookingsPerPickupLocation?.iahAirport },
        { name: 'Sugar Land', value: bookingData?.bookingsPerPickupLocation?.sugarLand },
        { name: 'South West Houston', value: bookingData?.bookingsPerPickupLocation?.soutWestHouston },
        { name: 'Hobby Airport', value: bookingData?.bookingsPerPickupLocation?.hobbyAirport }
      ]);
    }
  }, [bookingData])


  return (
    <>
      <AmountPresentation bookingData={bookingData} />

      <div
        style={{
          width: "100%",
          paddingInline: "1.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '1rem'
          }}
        >
          <div style={{ width: '49%', marginLeft: '0.5rem', marginTop: '0.5rem' }}>
            {bookedForDay
              && bookedForDay?.length > 20
              ?
              <BarChart
                data={bookedForDay}
                title="Bookings Per Day"
                height={300}
              />
              :
              <PieChart
                data={bookedForDay}
                title="Bookings Per Day"
                height={300}
                thresholdForSlice={0}
              />
            }
          </div>
          <div style={{ width: '49%', marginLeft: '0.5rem', marginTop: '0.5rem' }}>
            {bookedForStatus
              && bookedForStatus?.length > 20
              ?
              <BarChart
                data={bookedForStatus}
                title="Bookings Per Status"
                height={300}
              />
              :
              <PieChart
                data={bookedForStatus}
                title="Bookings Per Status"
                height={300}
                thresholdForSlice={0}
              />
            }
          </div>
          <div style={{ width: '49%', marginLeft: '0.5rem', marginTop: '0.5rem' }}>
            {bookedForCountry
              && bookedForCountry?.length > 20
              ?
              <BarChart
                data={bookedForCountry}
                title="Bookings Per Country"
                height={300}
              />
              :
              <PieChart
                data={bookedForCountry}
                title="Bookings Per Country"
                height={300}
                thresholdForSlice={0}
              />
            }
          </div>
          <div style={{ width: '49%', marginLeft: '0.5rem', marginTop: '0.5rem' }}>
            {bookedForState
              && bookedForState?.length > 20
              ?
              <BarChart
                data={bookedForState}
                title="Bookings Per State"
                height={300}
              />
              :
              <PieChart
                data={bookedForState}
                title="Bookings Per State"
                height={300}
                thresholdForSlice={0}
              />
            }
          </div>
          <div style={{ width: '49%', marginLeft: '0.5rem', marginTop: '0.5rem' }}>
            {bookedForPerPickupLocation
              && bookedForPerPickupLocation?.length > 20
              ?
              <BarChart
                data={bookedForPerPickupLocation}
                title="Bookings Per Pickup Location"
                height={300}
              />
              :
              <PieChart
                data={bookedForPerPickupLocation}
                title="Bookings Per Pickup Location"
                height={300}
                thresholdForSlice={0}
              />
            }
          </div>
        </div>
      </div>
    </>
  )
}
