import axios from "axios";

const handleCapture = (imageSrc: string, path: string, callback: any) => {
    // Convert Base64 to Blob
    const blob = base64ToBlob(imageSrc, 'image/png');

    // Create a FormData object to send the file
    const formData = new FormData();
    formData.append("ImageFile", blob, 'screenshot.png');

    axios
        .post(path, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
        })
        .then((response) => {
            callback(response.data);
        })
        .catch((error) => {
            console.error("Error:", error);
            callback("");
        });
};

const base64ToBlob = (base64: string, mime: string): Blob => {
    const byteCharacters = atob(base64.split(',')[1]); // Split off the data URL part
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const byteArray = new Uint8Array(
            Math.min(1024, byteCharacters.length - offset)
        );
        for (let i = 0; i < byteArray.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(offset + i);
        }
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
};

export {
    handleCapture
}