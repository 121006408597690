import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../configs/colorConfigs";
import { useEffect, useState } from "react";
import PleaseWaitPopup from "../../pages/PleaseWaitPopup";
import { delete_ReservationAdditionalCharge, put_ReservationAdditionalCharge } from "../../services/reservationAdditionalCharge";
import { post_comment, put_comment } from "../../services/comment";
import useAuth from "../hooks/useAuth";
import { format } from "date-fns";
import axios from "axios";
import baseURL from "../../configs/api";

type EditAdjustmentModalProps = {
    editAdjustmentIsOpen: boolean;
    setEditAdjustmentIsOpen: (value: boolean) => void;
    modalData: any;
    setAdjustmentRefresh: (value: boolean) => void;
    setIsExtraValueIsAdded: (value: boolean) => void;
    reservation: any;
    setReservationUpdate: (value: boolean) => void;
}

const EditAdjustmentModal = ({ editAdjustmentIsOpen, setEditAdjustmentIsOpen, modalData, setAdjustmentRefresh, setIsExtraValueIsAdded, reservation, setReservationUpdate }: EditAdjustmentModalProps) => {

    const { auth }: any = useAuth();
    const [adjustmentName, setAdjustmentName] = useState<string>("");
    const [quantity, setQuantity] = useState<number>(0);
    const [price, setPrice] = useState<string>('0');
    const [comment, setComment] = useState<string>("");
    const [pleaseWait, setPleaseWait] = useState<boolean>(false);
    const [selectionType, setSelectionType] = useState<string>("");
    const [chargeType, setChargeType] = useState<string>("");

    useEffect(() => {
        if (editAdjustmentIsOpen && modalData) {
            setAdjustmentName(modalData?.name);
            setQuantity(modalData?.quantity);
            setPrice(modalData?.basePrice);
            setComment(modalData?.comment);
            setSelectionType(modalData?.selectionType);
            setChargeType(modalData?.chargeType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editAdjustmentIsOpen])

    const updateTotalValue = async (
        totalAmount: number,
        outstandingBalance: number
    ) => {

        await axios
            .put(
                `${baseURL}reservation`,
                {
                    ...reservation,
                    totalPrice: totalAmount,
                    outstandingBalance: outstandingBalance,
                },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then((response) => {
                setPleaseWait(false);
                setEditAdjustmentIsOpen(false);
                setAdjustmentRefresh(true);
                setIsExtraValueIsAdded(true);
                setReservationUpdate(true);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async () => {
        setPleaseWait(true);
        let totalAmount = reservation ? reservation.totalPrice : 0;
        let outstandingBalance = reservation
            ? reservation?.outstandingBalance
            : 0;

        await put_ReservationAdditionalCharge(
            JSON.stringify({
                ...modalData,
                basePrice: parseInt(price),
            })
        )
            .then(data => {
                if (modalData?.commentId > 0) {
                    put_comment(
                        JSON.stringify({
                            id: modalData?.commentId,
                            attributeName: modalData?.commnetAttributeName !== "" && modalData?.commnetAttributeName !== null ? modalData?.commnetAttributeName : "ReservationAdjustment",
                            attributeId: modalData?.commnetAttributeId,
                            notes: comment,
                            userId: modalData?.commnetUserId,
                            createdDate: modalData?.commnetCreatedDate,
                        })).then(data => {
                            totalAmount -= selectionType === "Only One" ? chargeType === "Amount" ? modalData.quantity * modalData?.basePrice : modalData.quantity * (reservation?.totalPrice * (modalData?.basePrice / 100))
                                : chargeType === "Amount" ? (modalData.quantity * modalData?.basePrice) * reservation?.totalDays : (modalData.quantity * (reservation?.totalPrice * (modalData?.basePrice / 100))) * reservation?.totalDays;
                            totalAmount += selectionType === "Only One" ? chargeType === "Amount" ? modalData.quantity * parseInt(price) : modalData.quantity * (reservation?.totalPrice * (parseInt(price) / 100))
                                : chargeType === "Amount" ? (modalData.quantity * parseInt(price)) * reservation?.totalDays : (modalData.quantity * (reservation?.totalPrice * (parseInt(price) / 100))) * reservation?.totalDays;

                            outstandingBalance -= selectionType === "Only One" ? chargeType === "Amount" ? modalData.quantity * modalData?.basePrice : modalData.quantity * (reservation?.outstandingBalance * (modalData?.basePrice / 100))
                                : chargeType === "Amount" ? (modalData.quantity * modalData?.basePrice) * reservation?.totalDays : (modalData.quantity * (reservation?.outstandingBalance * (modalData?.basePrice / 100))) * reservation?.totalDays;

                            outstandingBalance += selectionType === "Only One" ? chargeType === "Amount" ? modalData.quantity * parseInt(price) : modalData.quantity * (reservation?.outstandingBalance * (parseInt(price) / 100))
                                : chargeType === "Amount" ? (modalData.quantity * parseInt(price)) * reservation?.totalDays : (modalData.quantity * (reservation?.outstandingBalance * (parseInt(price) / 100))) * reservation?.totalDays;
                            updateTotalValue(totalAmount, outstandingBalance);
                        })
                        .catch(error => console.error("Error:", error));
                }
                else if (!modalData?.commentId && comment !== "") {
                    post_comment(
                        JSON.stringify({
                            attributeName: "ReservationAdjustment",
                            attributeId: modalData?.id,
                            notes: comment,
                            userId: auth?.id,
                            createdDate:
                                format(new Date(), "yyyy-MM-dd") +
                                "T" +
                                format(new Date(), "KK:mm:ss"),
                        })).then(data => {
                            totalAmount -= selectionType === "Only One" ? modalData.quantity * modalData?.basePrice : (modalData.quantity * modalData?.basePrice) * reservation?.totalDays;
                            totalAmount += selectionType === "Only One" ? modalData.quantity * parseInt(price) : (modalData.quantity * parseInt(price)) * reservation?.totalDays

                            outstandingBalance -= selectionType === "Only One" ? modalData.quantity * modalData?.basePrice : (modalData.quantity * modalData?.basePrice) * reservation?.totalDays
                            outstandingBalance += selectionType === "Only One" ? modalData.quantity * parseInt(price) : (modalData.quantity * parseInt(price)) * reservation?.totalDays


                            updateTotalValue(totalAmount, outstandingBalance);
                        })
                        .catch(error => console.error("Error:", error));
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const updateReservation = async (amount: number) => {
        await axios
            .put(
                `${baseURL}reservation`,
                JSON.stringify({
                    ...reservation,
                    totalPrice: selectionType === "Only One" ? chargeType === "Amount" ? reservation?.totalPrice - amount : reservation?.totalPrice - (reservation?.totalPrice * (amount / 100))
                        : chargeType === "Amount" ? reservation?.totalPrice - (reservation?.totalDays * amount) : reservation?.totalPrice - (reservation?.totalDays * (reservation?.totalPrice * (amount / 100))),
                    outstandingBalance: selectionType === "Only One" ? chargeType === "Amount" ? reservation?.outstandingBalance - amount : reservation?.outstandingBalance - (reservation?.totalPrice * (amount / 100)) :
                        chargeType === "Amount" ? reservation?.outstandingBalance - (reservation?.totalDays * amount) : reservation?.outstandingBalance - (reservation?.totalDays * (reservation?.totalPrice * (amount / 100))),
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then((response) => {
                setPleaseWait(false);
                setEditAdjustmentIsOpen(false);
                setAdjustmentRefresh(true);
                setIsExtraValueIsAdded(true);
                setReservationUpdate(true);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const deleteAdjustment = async () => {
        setPleaseWait(true)
        await delete_ReservationAdditionalCharge(modalData?.id)
            .then(data => {
                updateReservation(parseFloat(modalData?.basePrice))
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    return (
        <>
            <Dialog
                open={editAdjustmentIsOpen}
                PaperProps={{
                    style: {
                        marginTop: "-14%",
                        width: "500px"
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Add new adjustment
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                            onClick={() => setEditAdjustmentIsOpen(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>
                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "16px",
                        }}>
                            <div style={{
                                width: "50%",

                            }}>
                                <label
                                    htmlFor="amount"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Charge of Adjustment
                                    <br />
                                    <span style={{ fontWeight: "lighter" }}>{adjustmentName}</span>
                                </label>
                            </div>

                            <div style={{
                                width: "50%",

                            }}>
                                <label
                                    htmlFor="amount"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Quantity
                                    <br />
                                    <span style={{ fontWeight: "lighter" }}>{quantity}</span>
                                </label>
                            </div>
                        </div>

                        <div style={{ width: "100%" }}>
                            <label
                                htmlFor="amount"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                {chargeType} {" "} {selectionType === "Only One" ? " One Time" : "/Day"}
                                <br />
                                <input
                                    type="text"
                                    name="price"
                                    style={{
                                        width: "50%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={price}
                                    required
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </label>
                        </div>

                        <div style={{ width: "100%" }}>
                            <label
                                htmlFor="description"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Comments
                                <br />
                                <textarea
                                    name="comments"
                                    rows={4}
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    required
                                />
                            </label>
                        </div>



                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "15px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#ff3333",
                                    color: "#ffffff",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#ff3333",
                                        color: "ffffff",
                                    },
                                }}
                                onClick={() => deleteAdjustment()}
                            >
                                Delete
                            </Button>
                        </div>

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => {
                                    setEditAdjustmentIsOpen(false);
                                }}
                            >
                                Cancel
                            </Button>


                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "#FFFFFF",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "#FFFFFF",
                                    },
                                }}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <PleaseWaitPopup pleaseWaitPopup={pleaseWait} />
        </>
    )
}

export default EditAdjustmentModal;