import React, { useState } from "react";
import EditReplaceVehicleModal from "./EditReplaceVehicleModal";
import { format } from "date-fns";

type VehicleReplaceListProps = {
    vehicleReplaceData: any[];
    setVehicleReplaceRefresh: (value: boolean) => void;
    reservation: any;
    setReservationUpdate: (value: boolean) => void;
}

const VehicleReplaceList = ({ vehicleReplaceData, setVehicleReplaceRefresh, reservation, setReservationUpdate }: VehicleReplaceListProps) => {
    const [editReplaceVehicleIsOpen, setEditReplaceVehicleIsOpen] = useState<boolean>(false);
    const [replacementModalData, setReplacementModalData] = useState<any>({});

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "5%",
                        marginBottom: "0px",
                    }}
                >
                    #
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "30%",
                        marginBottom: "0px",
                    }}
                >
                    Replaced Vehicle
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "30%",
                        marginBottom: "0px",
                    }}
                >
                    Replacement Vehicle
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "10%",
                        marginBottom: "0px",
                    }}
                >
                    Replacement Comments
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "20%",
                        marginBottom: "0px",
                    }}
                >
                    Created By
                </p>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "start",
                        width: "20%",
                        marginBottom: "0px",
                    }}
                >
                    Created At
                </p>
            </div>
            <hr style={{ marginBlock: "0px" }} />

            {vehicleReplaceData?.map((item: any, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "5%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.id}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "30%",
                                    marginBottom: "0px",
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => {
                                    setEditReplaceVehicleIsOpen(true);
                                    setReplacementModalData(item);
                                }}
                            >
                                {item?.replacedVehicle}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "30%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.replacementVehicle}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "10%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.replacementComments}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "20%",
                                    marginBottom: "0px",
                                }}
                            >
                                {item?.createdBy}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    textAlign: "start",
                                    width: "20%",
                                    marginBottom: "0px",
                                }}
                            >
                                {format(new Date(item?.createdAt), "dd-MM-yyyy hh:mm aaaaa'm'")}
                            </p>
                        </div>
                        <hr style={{ marginBlock: "0px" }} />
                    </React.Fragment>
                );
            })}

            <EditReplaceVehicleModal
                editReplaceVehicleIsOpen={editReplaceVehicleIsOpen}
                setEditReplaceVehicleIsOpen={setEditReplaceVehicleIsOpen}
                reservation={reservation}
                setVehicleReplaceRefresh={setVehicleReplaceRefresh}
                setReservationUpdate={setReservationUpdate}
                replacementModalData={replacementModalData}
            />
        </>
    )
}

export default VehicleReplaceList;