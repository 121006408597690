import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getIncidentById, put_Incident } from '../../../services/Tickets';
import useAuth from '../../../components/hooks/useAuth';
import useAttachments from '../../../components/hooks/useAttachments';
import useDelete from '../../../components/hooks/useDelete';
import { appName, ticketBaseURL } from '../../../configs/api';
import { getTicketStatusesByAttribute } from '../../../services/TicketStatus';
import { delete_fileUpload, getFileUploads, post_fileUpload } from '../../../services/FileUpload';
import { upload_CommonFileSupportTicket } from '../../../services/commonFile';
import { getTicketComments, post_TicketComment, put_TicketComment, delete_TicketComment } from '../../../services/TicketComments';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../../configs/colorConfigs';
import { format } from 'date-fns';

const EditSupportTicket = () => {
    const navigate = useNavigate();
    const { auth }: any = useAuth();
    const { id }: any = useParams();
    const { setViewImages, setAttachments, setCurrentIndex }: any = useAttachments();
    const { setDeletePopup, setData: setDeleteData }: any = useDelete();
    const [supportTicketData, setSupportTicketData] = useState<any>({
        accountId: 0,
        incidentName: "",
        status: "2",
        requester: "",
        description: "",
        categoryId: 0,
        subcategoryId: 0,
        assignedToUserId: 0,
        assignedToGroupAssignmentId: 0,
        priority: "",
        dueDate: "",
        emailCC: "",
        expectedRevenue: 0,
        groupId: "0",
        appGroupId: "0",
        appName: appName,
        createdAt: "",
        createdBy: "",
        updatedAt: "",
        updatedBy: ""
    });
    const [priorityData, setPriorityData] = useState<any[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isChanges, setIsChanges] = useState<boolean>(true);
    const [comments, setComments] = useState<any[]>([]);
    const [newComment, setNewComment] = useState<string>('');
    const [isAddComment, setIsAddComment] = useState<boolean>(false);
    const [showComments, setShowComments] = useState<boolean>(true);
    const [isProcessingComment, setIsProcessingComment] = useState<boolean>(false);
    const [isDataUploading, setIsDataUploading] = useState<boolean>(false);
    const [attachmentsValue, setAttachmentsValue] = useState<any[]>([]);
    const [key, setKey] = useState<number>(0);

    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getTicketStatusesByAttribute('Priority')
            .then((res) => {
                setPriorityData(res?.data.reverse().map((item: any) => ({
                    value: item?.id,
                    label: item?.status
                })));
            })
    }, [])

    useEffect(() => {
        getIncidentById(id)
            .then(res => {
                setSupportTicketData({
                    ...res,
                    status: res.status !== "" && res.status !== "string" ? res.status : "0",
                    priority: res.priority !== "string" && res.priority !== "" ? res.priority : "0",
                    dueDate: res.dueDate?.split("T")[0]
                });
            })
            .catch((error) => {
                console.log(error)
            })
    }, [id]);

    useEffect(() => {
        if (id && isChanges) {
            getTicketComments("Incidents", id)
                .then(res => {
                    setComments(res);
                    setIsChanges(false);
                })
                .catch((error) => console.log(error));
        }
    }, [id, isChanges]);

    useEffect(() => {
        if (id && !isDataUploading) {
            getFileUploads("Incidents", id)
                .then(res => {
                    setAttachmentsValue(res);
                })
                .catch((error) => console.log(error));
        }
    }, [id, isDataUploading]);

    const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDataUploading) {
            const selectedFiles = e.target.files as FileList;

            if (selectedFiles.length !== 0) {
                saveImageFile(selectedFiles[0]);
                if (fileRef.current) {
                    fileRef.current.value = '';
                    setKey((prevKey) => prevKey + 1);
                }
            }
        }
    };

    const saveImageFile = (imageFile: File) => {
        setIsDataUploading(true);
        const formData = new FormData();
        formData.append("ImageFile", imageFile);

        upload_CommonFileSupportTicket(formData)
            .then(res => {
                post_fileUpload({
                    attributeName: "Incidents",
                    attributeId: id,
                    fileName: res,
                    tabName: "Incidents",
                    userId: auth?.id,
                    createdDate: new Date()
                })
                    .then(() => {
                        setIsDataUploading(false);
                    })
                    .catch(error => console.error(error))
            })
            .catch(error => console.error(error))
    }

    const viewImage = (index: number) => {
        setViewImages(true);
        setAttachments(attachmentsValue)
        setCurrentIndex(index);
    }

    const deleteImage = (id: number) => {
        setDeleteData({
            id: id,
            title: `Delete`,
            summary: `Are you sure you want to delete this Attachment?`,
            isDelete: (callback: any) => {
                delete_fileUpload(id.toString())
                    .then(() => {
                        setAttachmentsValue((prev) => {
                            const updated = prev?.filter((item: any) => item?.id !== id);
                            return updated;
                        });
                    })
                    .catch((error) => {
                        console.error("Delete API Error:", error);
                    })
                    .finally(() => {
                        callback && callback();
                    });
            },
        });

        setDeletePopup(true);
    };


    const addComment = () => {
        if (!newComment) return;
        setIsProcessingComment(true);

        const newCommentData = {
            attributeName: "Incidents",
            attributeId: id,
            notes: newComment,
            userId: auth?.id,
            groupId: auth?.groupId,
            createdDate: new Date()
        };

        post_TicketComment(newCommentData)
            .then(() => {
                setIsChanges(true);
                setNewComment('');
                setIsAddComment(false);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setIsProcessingComment(false);
            });
    };

    const editComment = (data: any) => {
        setIsProcessingComment(true);

        put_TicketComment({
            id: data?.id,
            attributeName: data?.attributeName,
            attributeId: data?.attributeId,
            notes: data?.notes,
            userId: data?.userId,
            createdDate: data?.createdDate
        })
            .then(() => {
                setIsChanges(true);
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsProcessingComment(false);
            });
    };

    const deleteComment = (id: string) => {
        setDeleteData({
            id: id,
            title: `Delete`,
            summary: `Are you sure you want to delete this comment?`,
            isDelete: (callback: any) => {
                setIsProcessingComment(true);

                delete_TicketComment(id)
                    .then(() => {
                        setComments(prevComments => prevComments.filter(comment => comment.id !== id));
                        setIsChanges(true);
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        callback && callback();
                        setIsProcessingComment(false);
                    });
            }
        });

        setDeletePopup(true);
    };

    const closeModal = () => navigate(-1);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isProcessing && !isProcessingComment) {
            setIsProcessing(true);

            put_Incident({
                ...supportTicketData,
                priority: supportTicketData.priority,
                createdAt: new Date().toISOString(),
                createdBy: auth?.givenname,
                updatedAt: new Date().toISOString(),
                updatedBy: auth?.givenname,
            }, auth?.id)
                .then(() => {
                    setIsProcessing(false);
                    navigate(-1);
                })
                .catch(error => {
                    setIsProcessing(false);
                    console.error(error);
                });
        }
    }

    const isStatusNew = supportTicketData.status === 'Closed';

    return (
        <div style={{ width: "100%" }}>
            <div style={{ margin: "0.5rem 0" }}>
                <div style={{ padding: "2rem 1.5rem", position: "relative", backgroundColor: "white" }}>
                    <h2 style={{ fontSize: "1.25rem", fontWeight: "bold", color: colorConfigs.primaryColor, }}>Edit Support Ticket</h2>
                    <form onSubmit={handleSubmit} style={{ marginTop: "2rem", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "1.5rem", color: "#007bff" }}>
                        <div style={{ gridColumn: 'span 2 / span 2' }}>
                            <label style={{ fontWeight: '600', fontSize: '0.875rem', color: colorConfigs.primaryColor }}>Subject</label>
                            <input
                                type='text'
                                placeholder='Enter Subject'
                                style={{
                                    width: '100%',
                                    borderRadius: '0.375rem',
                                    padding: '0.625rem 1rem',
                                    marginTop: '0.5rem',
                                    border: '2px solid #ccc',
                                    fontSize: '0.875rem',
                                    color: '#000',
                                    outlineColor: '#000',
                                }}
                                value={supportTicketData.incidentName}
                                onChange={(e) => setSupportTicketData({ ...supportTicketData, incidentName: e.target.value })}
                                required
                            />
                        </div>

                        <div style={{ gridColumn: 'span 2 / span 2' }}>
                            <label style={{ fontWeight: '600', fontSize: '0.875rem', color: colorConfigs.primaryColor }}>Description</label>
                            <textarea
                                placeholder='Description'
                                style={{
                                    width: '100%',
                                    borderRadius: '0.375rem',
                                    padding: '0.625rem 1rem',
                                    marginTop: '0.5rem',
                                    border: '2px solid #ccc',
                                    fontSize: '0.875rem',
                                    color: '#000',
                                    outlineColor: '#007bff',
                                }}
                                value={supportTicketData.description}
                                onChange={(e) => setSupportTicketData({ ...supportTicketData, description: e.target.value })}
                            />
                        </div>

                        <div style={{ gridColumn: 'span 2 / span 2' }}>
                            <label style={{ fontWeight: '600', fontSize: '0.875rem', color: colorConfigs.primaryColor }}>Priority</label>
                            <select
                                style={{
                                    width: '100%',
                                    borderRadius: '0.375rem',
                                    padding: '0.625rem 1rem',
                                    marginTop: '0.5rem',
                                    border: '2px solid #ccc',
                                    fontSize: '0.875rem',
                                    color: '#000',
                                    outlineColor: '#007bff',
                                }}
                                value={supportTicketData.priority}
                                onChange={(e) => {
                                    setSupportTicketData({ ...supportTicketData, priority: e.target.value });
                                }}
                            >
                                {priorityData?.map((item, ind) => (
                                    <option key={ind} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>

                        <div style={{ gridColumn: 'span 2 / span 2' }}>
                            <span style={{ fontSize: '0.875rem', color: colorConfigs.primaryColor, fontWeight: '600' }}>{"Attachment"}</span>
                            <button
                                key={key}
                                type="button"
                                onClick={() => fileRef.current?.click()}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    padding: '0.375rem 0.75rem',
                                    marginTop: '0.5rem',
                                    borderRadius: '0.375rem',
                                    color: '#6c757d',
                                    fontSize: '0.875rem',
                                    fontWeight: '600',
                                    border: '1px solid #6c757d',
                                    backgroundColor: '#fff',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    opacity: isDataUploading ? '0.8' : '1',
                                }}
                            >
                                {isDataUploading ? (
                                    <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18px"
                                            style={{
                                                marginRight: "0.5rem",
                                                fill: colorConfigs.primaryColor,
                                                display: "inline-block",
                                                animation: "spin 1s linear infinite",
                                            }}
                                            viewBox="0 0 26.349 26.35"
                                        >
                                            <circle cx="13.792" cy="3.082" r="3.082" />
                                            <circle cx="13.792" cy="24.501" r="1.849" />
                                        </svg>
                                        <span style={{ color: colorConfigs.primaryColor }}>Processing...</span>
                                    </>
                                ) : (
                                    <>
                                        <CloudUploadIcon style={{ color: colorConfigs.primaryColor }} />
                                        <span style={{ color: colorConfigs.primaryColor }}>Add Attachment</span>
                                    </>
                                )}
                            </button>
                            <input
                                ref={fileRef}
                                type="file"
                                accept="image/png, image/jpeg"
                                name="image-choose"
                                style={{ display: 'none' }}
                                onChangeCapture={onFileChangeCapture}
                            />
                        </div>

                        {attachmentsValue?.length !== 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '1rem',
                                    gridColumn: 'span 2 / span 2',
                                    marginBottom: "2rem"
                                }}
                            >
                                {attachmentsValue?.map((attachment,  index: number) => (
                                    <div
                                        key={attachment.id}
                                        style={{ position: 'relative', width: '10rem', height: '10rem' }}
                                        onMouseEnter={(e) => {
                                            const deleteIcon = e.currentTarget.querySelector('.delete-icon') as HTMLElement;
                                            if (deleteIcon) deleteIcon.style.visibility = 'visible';
                                        }}
                                        onMouseLeave={(e) => {
                                            const deleteIcon = e.currentTarget.querySelector('.delete-icon') as HTMLElement;
                                            if (deleteIcon) deleteIcon.style.visibility = 'hidden';
                                        }}
                                    >
                                        <div
                                            className="delete-icon"
                                            style={{
                                                position: 'absolute',
                                                top: '0.25rem',
                                                right: '0.25rem',
                                                backgroundColor: '#e2e8f0',
                                                borderRadius: '0.25rem',
                                                cursor: 'pointer',
                                                color: colorConfigs.primaryColor,
                                                visibility: 'hidden',
                                            }}
                                            onClick={() => deleteImage(attachment.id)}
                                        >
                                            <DeleteIcon />
                                        </div>

                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '0.375rem',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => viewImage(index)}
                                        >
                                            <img
                                                style={{
                                                    width: '100%',
                                                    height: '6rem',
                                                    borderRadius: '0.375rem',
                                                }}
                                                src={`${ticketBaseURL}commonFile/Get?name=${attachment.fileName}`}
                                                alt={attachment.fileName}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#f7fafc',
                                                    width: '100%',
                                                    fontSize: '0.75rem',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '0.375rem 0.75rem',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                        color: colorConfigs.primaryColor,
                                                    }}
                                                >
                                                    {attachment.fileName}
                                                </span>
                                                <span style={{ color: colorConfigs.primaryColor }}>
                                                    {format(new Date(attachment.createdDate), 'dd MMM yyyy, KK:mm a')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div style={{ gridColumn: "span 2", marginTop: "1rem" }}>
                            <button
                                type="button"
                                style={{ display: "flex", alignItems: "center", gap: "0.5rem", fontWeight: '600', fontSize: '0.875rem', color: colorConfigs.primaryColor, border: "none", backgroundColor: "#fff", cursor: "pointer" }}
                                onClick={() => setShowComments(!showComments)}
                            >
                                <span className="material-icons pr-1">
                                    {showComments ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                </span>
                                Comments
                            </button>
                        </div>
                        <hr
                            style={{
                                gridColumn: "span 2",
                                border: "1px solid black",
                                marginTop: "0.5rem",
                                width: "100%",
                            }}
                        />
                        {showComments && (
                            <>
                                <div style={{ gridColumn: "span 2", margin: "0.25rem 0" }}>
                                    <div style={{ margin: "0.25rem 0.5rem" }}>
                                        {comments.map((comment, index) => (
                                            <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "1rem 0" }}>
                                                {!comment.isEdit ? (
                                                    <span style={{ fontSize: "0.875rem", width: "90%", color: "black" }}>{comment.notes}</span>
                                                ) : (
                                                    <textarea
                                                        style={{ padding: "0.625rem 1rem", fontSize: "0.875rem", border: "1px solid gray", color: "black", width: "90%" }}
                                                        value={comment.notes}
                                                        onChange={(e) => setComments(prevComments => {
                                                            const updatedComments = [...prevComments];
                                                            updatedComments[index] = {
                                                                ...updatedComments[index],
                                                                notes: e.target.value
                                                            };
                                                            return updatedComments;
                                                        })}
                                                    />
                                                )}
                                                <div style={{ display: "flex", gap: "1rem" }}>
                                                    {!comment.isEdit ? (
                                                        <>
                                                            {!isStatusNew && (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => setComments(prevComments => {
                                                                        const updatedComments = [...prevComments];
                                                                        updatedComments[index].isEdit = true;
                                                                        return updatedComments;
                                                                    })}
                                                                    style={{ color: "#007bff" }}
                                                                >
                                                                    <EditIcon />
                                                                </button>
                                                            )}
                                                            {!isStatusNew && (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => deleteComment(comment.id)}
                                                                    style={{ color: "#f44336" }}
                                                                    disabled={isProcessing || isProcessingComment}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                type="button"
                                                                onClick={() => editComment(comment)}
                                                                style={{ color: "#4caf50" }}
                                                                disabled={isProcessing || isProcessingComment}
                                                            >
                                                                <CheckIcon />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => setComments(prevComments => {
                                                                    const updatedComments = [...prevComments];
                                                                    updatedComments[index].isEdit = false;
                                                                    return updatedComments;
                                                                })}
                                                                style={{ color: "#9e9e9e" }}
                                                            >
                                                                <CloseIcon />
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                        {!isStatusNew && !isAddComment ? (
                                            <button
                                                onClick={() => setIsAddComment(true)}
                                                style={{ padding: '0.5rem 1.5rem', fontSize: '0.875rem', color: 'white', backgroundColor: colorConfigs.primaryColor, border: 'none', borderRadius: '0.375rem', cursor: 'pointer', display: 'inline-block' }}
                                            >
                                                Add Comment
                                            </button>
                                        ) : !isStatusNew && isAddComment ? (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <textarea
                                                    value={newComment}
                                                    onChange={(e) => setNewComment(e.target.value)}
                                                    style={{ width: '100%', padding: '1rem', fontSize: '0.875rem', color: 'black', border: '1px solid #d1d5db', borderRadius: '0.375rem', marginBottom: '1rem' }}
                                                    placeholder="Enter your comment..."
                                                />
                                                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                                    <button
                                                        onClick={() => setIsAddComment(false)}
                                                        style={{ fontSize: '0.875rem', color: '#000', cursor: 'pointer', textDecoration: 'none', backgroundColor: '#e2e8f0', borderRadius: '0.375rem', border: "none", outline: 'none', padding: '0.2rem 0.5rem' }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={addComment}
                                                        style={{ fontSize: '0.875rem', color: '#fff', cursor: 'pointer', textDecoration: 'none', backgroundColor: colorConfigs.primaryColor, border: "none", outline: 'none', borderRadius: '0.375rem', padding: '0.2rem 0.5rem' }}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </>
                        )}

                        <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem', flexDirection: 'row' }}>
                            <button
                                type="button"
                                style={{
                                    padding: '0.5rem 1.5rem',
                                    minWidth: '150px',
                                    borderRadius: '0.375rem',
                                    color: '#000',
                                    fontSize: '0.875rem',
                                    fontWeight: '600',
                                    backgroundColor: '#e2e8f0',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    border: 'none',
                                }}
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                style={{
                                    padding: '0.5rem 1.5rem',
                                    minWidth: '150px',
                                    borderRadius: '0.375rem',
                                    color: '#fff',
                                    fontSize: '0.875rem',
                                    fontWeight: '600',
                                    backgroundColor: colorConfigs.primaryColor,
                                    outline: 'none',
                                    cursor: 'pointer',
                                    border: 'none',
                                }}
                            >
                                {isProcessing ? (
                                    <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18px"
                                            fill="#fff"
                                            className="mr-2 inline animate-spin"
                                            viewBox="0 0 26.349 26.35"
                                        >
                                            <circle cx="13.792" cy="3.082" r="3.082" />
                                            <circle cx="13.792" cy="24.501" r="1.849" />
                                        </svg>
                                        Processing...
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditSupportTicket;